import { axiosBaseQuery } from '../../api/axios.queryBuilder'
import { createApi } from '@reduxjs/toolkit/query/react'
import { SystemNotification } from '../../types/notification'

export const systemNotificationsApi = createApi({
	reducerPath: 'systemNotificationsApi',
	baseQuery: axiosBaseQuery({
		baseUrl: 'notifications',
	}),
	tagTypes: ['SystemNotifications'],
	endpoints: (builder) => ({
		loadNotifications: builder.query<SystemNotification[], void>({
			query: () => ({
				url: '/getNotifications',
				method: 'GET',
			}),
			providesTags: ['SystemNotifications'],
		}),
		updateNotification: builder.mutation<SystemNotification, string>({
			query: (id) => ({
				url: `/${id}/updateNotification`,
				method: 'PUT',
			}),
			invalidatesTags: ['SystemNotifications'],
		}),
		deleteNotification: builder.mutation<SystemNotification, string>({
			query: (id) => ({
				url: `/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['SystemNotifications'],
		}),
	}),
});

export const {
	useLoadNotificationsQuery,
	useUpdateNotificationMutation,
	useDeleteNotificationMutation
} = systemNotificationsApi;
