import React, { useState, useMemo } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { MultiselectFormGroup } from '../../../../../components/FormGroups'
import { ReportResponse } from '../../../../../types/finance/reports'
import {
	useGetUnarchivedContractorClassesQuery,
	useGetUnarchivedContractorTypesQuery,
	useLoadContractorsSelectQuery,
	useLoadOrganizationsSelectQuery,
} from '../../../../../redux/slices/financeDictionaries'
import { useGetNetSettlementQuery } from '../../../../../redux/slices/reports'
import Loader from '../../../../../components/Loaders/Loader'
import NetSettlementExcelButton from './Buttons/NetSettlementExcelButton'
import TableError from '../../../../../components/TableError'
import {
	defaultRanges,
	FIXED_COLUMN_STYLE,
	RecursiveTreeRowComponent,
	ReportQuickSelect,
	ReportSwitches,
	ReportTotalsRow
} from './Commons'

const NetSettlementTable = ({ tree, totals, showNative, useArchived }) => {
	const { t } = useTranslation()
	return (
		<Table bordered hover responsive className="table-striped table-sm mt-3">
			<thead>
			<tr>
				<th rowSpan={2} className="align-middle text-break text-wrap" style={FIXED_COLUMN_STYLE}>
					{t('type')}
				</th>
				{showNative && (
					<th colSpan={4} className="text-center">
						{t('systemNative')}
					</th>
				)}
				<th colSpan={4} className="text-center">
					{t('managementCurrency')}
				</th>
			</tr>
			<tr>
				{showNative && (
					<>
						<th className="text-center">{t('openingBalance')}</th>
						<th className="text-center">{t('incoming')}</th>
						<th className="text-center">{t('outcoming')}</th>
						<th className="text-center">{t('closingBalance')}</th>
					</>
				)}
				<th className="text-center">{t('openingBalance')}</th>
				<th className="text-center">{t('incoming')}</th>
				<th className="text-center">{t('outcoming')}</th>
				<th className="text-center">{t('closingBalance')}</th>
			</tr>
			</thead>
			<tbody>
			{tree.map((node) => (
				<RecursiveTreeRowComponent key={node.id} node={node} level={0} showNative={showNative} useArchived={useArchived} />
			))}
			<ReportTotalsRow totals={totals} showNative={showNative} t={t} />
			</tbody>
		</Table>
	)
}

export default function NetSettlementReport() {
	const { t, i18n } = useTranslation()
	const { data: contractors = [] } = useLoadContractorsSelectQuery()
	const { data: organizations = [] } = useLoadOrganizationsSelectQuery()
	const { data: contractorClasses = [] } = useGetUnarchivedContractorClassesQuery()
	const { data: contractorTypes = [] } = useGetUnarchivedContractorTypesQuery()
	const [selectedContractorClasses, setSelectedContractorClasses] = useState([])
	const [selectedContractorTypes, setSelectedContractorTypes] = useState([])
	const [documentDateFrom, setDocumentDateFrom] = useState(moment().startOf('year').toDate())
	const [documentDateTo, setDocumentDateTo] = useState(moment().endOf('year').toDate())
	const [showNative, setShowNative] = useState(true)
	const [useArchived, setUseArchived] = useState(false)
	const [selectedOrganizations, setSelectedOrganizations] = useState([])
	const [selectedContractors, setSelectedContractors] = useState([])
	const validFrom = documentDateFrom || moment().startOf('year').toDate()
	const validTo = documentDateTo || moment().endOf('year').toDate()
	const filters = useMemo(
		() => ({
			startDate: validFrom.toISOString(),
			endDate: validTo.toISOString(),
			organizations: selectedOrganizations.length ? selectedOrganizations : undefined,
			contractors: selectedContractors.length ? selectedContractors : undefined,
			contractorClasses: selectedContractorClasses.length ? selectedContractorClasses : undefined,
			contractorTypes: selectedContractorTypes.length ? selectedContractorTypes : undefined,
			language: i18n.language as 'uk' | 'en' | undefined,
		}),
		[validFrom, validTo, selectedOrganizations, selectedContractors, selectedContractorClasses, selectedContractorTypes, i18n.language]
	)
	const { data: reportData, isLoading, isFetching, isError } = useGetNetSettlementQuery(filters)
	const finalData: ReportResponse = reportData || {
		tree: [],
		totals: {
			openingNativeConv: 0,
			incomingNativeConv: 0,
			outgoingNativeConv: 0,
			closingNativeConv: 0,
			openingMgm: 0,
			incomingMgm: 0,
			outgoingMgm: 0,
			closingMgm: 0,
		},
	}
	return (
		<>
			<Row xs={1} sm={1} md={2} lg={2} xxl={4}>
				<ReportQuickSelect
					ranges={defaultRanges}
					documentDateFrom={documentDateFrom}
					documentDateTo={documentDateTo}
					setDocumentDateFrom={setDocumentDateFrom}
					setDocumentDateTo={setDocumentDateTo}
				/>
				<Col md={12} lg={12} xxl={6} className="text-right">
					<NetSettlementExcelButton data={reportData} showNative={showNative} dateFrom={documentDateFrom} dateTo={documentDateTo} />
				</Col>
			</Row>
			<Row xs={1} sm={1} md={2} lg={2} xxl={4} className="mt-3">
				<Col>
					<MultiselectFormGroup
						placeholder={t('organizations')}
						options={organizations.map((org, index) => ({ value: org._id, label: org.name, index }))}
						onChange={(selected) => setSelectedOrganizations(selected.map((s) => s.value))}
					/>
				</Col>
				<Col>
					<MultiselectFormGroup
						placeholder={t('contractors')}
						options={contractors.map((ctr, index) => ({ value: ctr._id, label: ctr.name, index }))}
						onChange={(selected) => setSelectedContractors(selected.map((s) => s.value))}
					/>
				</Col>
				<Col>
					<MultiselectFormGroup
						placeholder={t('contractor_class')}
						options={contractorClasses.map((cls, index) => ({
							value: cls._id,
							label: i18n.language === 'uk' ? cls.ukName : cls.enName,
							index,
						}))}
						onChange={(selected) => setSelectedContractorClasses(selected.map((s) => s.value))}
					/>
				</Col>
				<Col>
					<MultiselectFormGroup
						placeholder={t('contractor_type')}
						options={contractorTypes.map((type, index) => ({
							value: type._id,
							label: i18n.language === 'uk' ? type.ukName : type.enName,
							index,
						}))}
						onChange={(selected) => setSelectedContractorTypes(selected.map((s) => s.value))}
					/>
				</Col>
			</Row>
			<ReportSwitches useArchived={useArchived} setUseArchived={setUseArchived} showNative={showNative} setShowNative={setShowNative} />
			{isLoading || isFetching ? (
				<Loader />
			) : isError ? (
				<TableError>{t('request_error')}</TableError>
			) : finalData.tree.length > 0 ? (
				<NetSettlementTable tree={finalData.tree} totals={finalData.totals} showNative={showNative} useArchived={useArchived} />
			) : (
				<TableError>{t('no_data')}</TableError>
			)}
		</>
	)
}
