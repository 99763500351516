import {Col, Form, Row} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export default function NumberInput({ label, value, onChange, md = 3 }) {
    const { t } = useTranslation();
    return (
        <Form.Group className='pb-3'>
            <Form.Label>{t(label)}</Form.Label>
            <Row>
                <Col md={md} className='d-flex align-items-center'>
                    <Form.Control
                        type="number"
                        value={value}
                        onChange={onChange}
                    />
                </Col>
            </Row>
        </Form.Group>
    )
}