import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/axios.queryBuilder';
import { UpdateCronTaskDto, CronTask } from '../../types/cron';

export const cronApi = createApi({
	reducerPath: 'cronApi',
	baseQuery: axiosBaseQuery({
		baseUrl: '/cron',
	}),
	tagTypes: ['CronTasks'],
	endpoints: (builder) => ({
		updateCronTask: builder.mutation<any, { id: string; updateCronTaskDto: UpdateCronTaskDto }>({
			query: ({ id, updateCronTaskDto }) => ({
				url: `/${id}`,
				method: 'PUT',
				data: updateCronTaskDto,
			}),
			invalidatesTags: ['CronTasks'],
		}),
		forceLaunchCron: builder.mutation<any, { id: string }>({
			query: ({ id }) => ({
				url: `/${id}/force-launch`,
				method: 'PUT',
			}),
			invalidatesTags: ['CronTasks'],
		}),
		// forceStopCron: builder.mutation<any, { id: string }>({
		// 	query: ({ id }) => ({
		// 		url: `/${id}/force-stop`,
		// 		method: 'PUT',
		// 	}),
		// 	invalidatesTags: ['CronTasks'],
		// }),
		getAllCronTasks: builder.query<CronTask[], void>({
			query: () => ({
				url: `/`,
				method: 'GET',
			}),
			providesTags: ['CronTasks'],
		}),
		// stopAllCronTasks: builder.mutation<void, void>({
		// 	query: () => ({
		// 		url: `/stop-all`,
		// 		method: 'POST',
		// 	}),
		// 	invalidatesTags: ['CronTasks'],
		// }),
		clearCronTasksLogs: builder.mutation<void, void>({
			query: () => ({
				url: `/clear-logs`,
				method: 'POST',
			}),
			invalidatesTags: ['CronTasks'],
		}),
	}),
});

export const {
	useUpdateCronTaskMutation,
	useForceLaunchCronMutation,
	// useForceStopCronMutation,
	useGetAllCronTasksQuery,
	// useStopAllCronTasksMutation,
	useClearCronTasksLogsMutation
} = cronApi;
