import React, { useState, useMemo } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { MultiselectFormGroup } from '../../../../../components/FormGroups'
import { ReportResponse } from '../../../../../types/finance/reports'
import {
	useLoadAccountsSelectQuery,
	useLoadCashFlowTypesListQuery,
	useLoadContractorsSelectQuery,
	useLoadOrganizationsSelectQuery,
} from '../../../../../redux/slices/financeDictionaries'
import { useGetTurnoversQuery } from '../../../../../redux/slices/reports'
import Loader from '../../../../../components/Loaders/Loader'
import TurnoverExcelButton from './Buttons/TurnoverExcelButton'
import TableError from '../../../../../components/TableError'
import {
	defaultRanges,
	FIXED_COLUMN_STYLE,
	RecursiveTreeRowComponent,
	ReportQuickSelect,
	ReportSwitches,
	ReportTotalsRow,
	TurnoverReportMode
} from './Commons'

const modeOptions = [
	{ value: TurnoverReportMode.Accounts, label: 'by_accounts' },
	{ value: TurnoverReportMode.CashFlowType, label: 'by_cashflow_type' },
]

const TurnoversReportTable = ({ finalData, showNative, viewMode, useArchived }) => {
	const { t } = useTranslation()
	return (
		<Table bordered hover responsive className="table-striped table-sm mt-3">
			<thead>
			<tr>
				<th rowSpan={2} className="align-middle text-break text-wrap" style={FIXED_COLUMN_STYLE}>
					{`${t('account')} / ${t('cashFlowType')}`}
				</th>
				{showNative && (
					<th colSpan={4} className="text-center">
						{viewMode === TurnoverReportMode.Accounts ? t('accountCurrency') : t('systemNative')}
					</th>
				)}
				<th colSpan={4} className="text-center">
					{t('managementCurrency')}
				</th>
			</tr>
			<tr>
				{showNative && (
					<>
						<th className="text-center">{t('openingBalance')}</th>
						<th className="text-center">{t('income')}</th>
						<th className="text-center">{t('expense')}</th>
						<th className="text-center">{t('closingBalance')}</th>
					</>
				)}
				<th className="text-center">{t('openingBalance')}</th>
				<th className="text-center">{t('income')}</th>
				<th className="text-center">{t('expense')}</th>
				<th className="text-center">{t('closingBalance')}</th>
			</tr>
			</thead>
			<tbody>
			{finalData.tree.map((node) => (
				<RecursiveTreeRowComponent key={node.id} node={node} level={0} showNative={showNative} useArchived={useArchived} displayRaw />
			))}
			<ReportTotalsRow totals={finalData.totals} showNative={showNative} t={t} />
			</tbody>
		</Table>
	)
}

export default function TurnoversReport() {
	const { t, i18n } = useTranslation()
	const { data: accounts = [] } = useLoadAccountsSelectQuery()
	const { data: cfTypes = [] } = useLoadCashFlowTypesListQuery()
	const { data: contractors = [] } = useLoadContractorsSelectQuery()
	const { data: organizations = [] } = useLoadOrganizationsSelectQuery()
	const [documentDateFrom, setDocumentDateFrom] = useState(moment().startOf('year').toDate())
	const [documentDateTo, setDocumentDateTo] = useState(moment().endOf('year').toDate())
	const [showNative, setShowNative] = useState(true)
	const [useArchived, setUseArchived] = useState(false)
	const [viewMode, setViewMode] = useState(TurnoverReportMode.Accounts)
	const [selectedOrganizations, setSelectedOrganizations] = useState([])
	const [selectedContractors, setSelectedContractors] = useState([])
	const [selectedCashFlowTypes, setSelectedCashFlowTypes] = useState([])
	const [selectedAccounts, setSelectedAccounts] = useState([])
	const validFrom = documentDateFrom || moment().startOf('year').toDate()
	const validTo = documentDateTo || moment().endOf('year').toDate()
	const filters = useMemo(
		() => ({
			startDate: validFrom.toISOString(),
			endDate: validTo.toISOString(),
			accounts: selectedAccounts.length ? selectedAccounts : undefined,
			organizations: selectedOrganizations.length ? selectedOrganizations : undefined,
			contractors: selectedContractors.length ? selectedContractors : undefined,
			cashFlowTypes: selectedCashFlowTypes.length ? selectedCashFlowTypes : undefined,
			mode: viewMode,
			language: i18n.language as 'uk' | 'en' | undefined,
		}),
		[validFrom, validTo, selectedAccounts, selectedOrganizations, selectedContractors, selectedCashFlowTypes, viewMode, i18n.language]
	)
	const { data: reportData, isLoading, isFetching, isError } = useGetTurnoversQuery(filters)
	const finalData: ReportResponse = reportData || {
		tree: [],
		totals: {
			openingNativeConv: 0,
			incomingNativeConv: 0,
			outgoingNativeConv: 0,
			closingNativeConv: 0,
			openingMgm: 0,
			incomingMgm: 0,
			outgoingMgm: 0,
			closingMgm: 0,
		},
	}
	return (
		<>
			<Row xs={1} sm={1} md={2} lg={2} xxl={4}>
				<ReportQuickSelect
					ranges={defaultRanges}
					documentDateFrom={documentDateFrom}
					documentDateTo={documentDateTo}
					setDocumentDateFrom={setDocumentDateFrom}
					setDocumentDateTo={setDocumentDateTo}
				/>
				<Col md={12} lg={12} xxl={6} className="text-right">
					<TurnoverExcelButton data={reportData} showNative={showNative} dateFrom={documentDateFrom} dateTo={documentDateTo} />
				</Col>
			</Row>
			<Row xs={1} sm={1} md={2} lg={2} xxl={4} className="mt-3">
				<Col>
					<MultiselectFormGroup
						placeholder={t('organizations')}
						options={organizations.map((org, index) => ({ value: org._id, label: org.name, index }))}
						onChange={(selected) => setSelectedOrganizations(selected.map((s) => s.value))}
					/>
				</Col>
				<Col>
					<MultiselectFormGroup
						placeholder={t('contractors')}
						options={contractors.map((ctr, index) => ({ value: ctr._id, label: ctr.name, index }))}
						onChange={(selected) => setSelectedContractors(selected.map((s) => s.value))}
					/>
				</Col>
				<Col>
					<MultiselectFormGroup
						placeholder={t('accounts')}
						options={accounts.map((acc, index) => ({ value: acc._id, label: acc.name, index }))}
						onChange={(selected) => setSelectedAccounts(selected.map((s) => s.value))}
					/>
				</Col>
				<Col>
					<MultiselectFormGroup
						placeholder={t('cashFlowType')}
						options={cfTypes.map((cf, index) => ({ value: cf._id, label: cf.ukName || cf.enName, index }))}
						onChange={(selected) => setSelectedCashFlowTypes(selected.map((s) => s.value))}
					/>
				</Col>
			</Row>
			<ReportSwitches
				useArchived={useArchived}
				setUseArchived={setUseArchived}
				mode={viewMode}
				setMode={setViewMode}
				modeOptions={modeOptions}
				showNative={showNative}
				setShowNative={setShowNative}
			/>
			{isLoading || isFetching ? (
				<Loader />
			) : isError ? (
				<TableError>{t('request_error')}</TableError>
			) : finalData.tree.length > 0 ? (
				<TurnoversReportTable finalData={finalData} showNative={showNative} viewMode={viewMode} useArchived={useArchived} />
			) : (
				<TableError>{t('no_data')}</TableError>
			)}
		</>
	)
}
