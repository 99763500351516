import React, { useMemo, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Col, Row, Table } from 'react-bootstrap'
import { MultiselectFormGroup } from '../../../../../components/FormGroups'
import Loader from '../../../../../components/Loaders/Loader'
import TableError from '../../../../../components/TableError'
import { useLoadExpenseTypesListQuery, useLoadOrganizationsSelectQuery } from '../../../../../redux/slices/financeDictionaries'
import { useGetPnlQuery } from '../../../../../redux/slices/reports'
import { PnlReport } from '../../../../../types/finance/reports'
import { displayValue } from '../../../../../utils/reportsExcelParser'
import {
	NATIVE_CURRENCY_NAME,
	quarterRanges,
	DefaultReportHeader,
	DefaultTotalsRow,
	RecursiveMonthlyRow,
	ReportQuickSelect,
	ReportSwitches,
	getAlternatingRowBg,
	extractMonths,
	PnlReportMode
} from './Commons'
import PnlExcelButton from './Buttons/PnlReportExcelButton'

const modeOptions = [
	{ value: PnlReportMode.IncomeExpense, label: 'by_direction' },
	{ value: PnlReportMode.ExpenseType, label: 'by_expense_type' },
]

const AdditionalTotalsRows = ({ report, months, showNative }) => {
	const { t } = useTranslation()
	const rows = [
		// { key: 'marginTotals', label: t('marginProfit') },
		{ key: 'operatingProfitEBIT', label: t('operatingProfitEBIT') },
		{ key: 'interestTotals', label: t('interestOnLoans') },
		{ key: 'operatingProfitBeforeTaxation', label: t('operatingProfitBeforeTaxation') },
		{ key: 'taxesTotals', label: t('taxes') },
		{ key: 'netIncome', label: t('netIncome') },
	]
	const getCellValue = (record, monthKey, field, defaultValue = 0) =>
		record?.[monthKey]?.[field] ?? defaultValue
	return (
		<>
			{rows.map((total, idx) => {
				const bgColor = getAlternatingRowBg(idx)
				const overallData = report.additionalTotals[total.key]
				return (
					<tr key={total.key} className="fw-bold" style={{ backgroundColor: bgColor }}>
						<td>{total.label}</td>
						{months.map((m, idx2) => (
							<React.Fragment key={idx2}>
								{showNative && (
									<td className="text-right text-nowrap">
										{displayValue(getCellValue(report[total.key], m.key, 'native'), NATIVE_CURRENCY_NAME)}
									</td>
								)}
								<td className="text-right text-nowrap">
									{displayValue(getCellValue(report[total.key], m.key, 'mgm'))}
								</td>
							</React.Fragment>
						))}
						{showNative ? (
							<>
								<td className="text-right text-nowrap">
									{displayValue(overallData.native, NATIVE_CURRENCY_NAME)}
								</td>
								<td className="text-right text-nowrap">
									{displayValue(overallData.mgm)}
								</td>
							</>
						) : (
							<td className="text-right text-nowrap">
								{displayValue(overallData.mgm)}
							</td>
						)}
					</tr>
				)
			})}
		</>
	)
}

// New Component for the margin totals row
const MarginTotalsRow = ({ report, months, showNative, t }) => {
	const overallData = report.additionalTotals['marginTotals']
	return (
		<tr className="margin-total-row fw-bold" style={{ backgroundColor: '#d4f7d4' }}>
			<td>{t('marginProfit')}</td>
			{months.map((m, idx) => (
				<React.Fragment key={idx}>
					{showNative && (
						<td className="text-right text-nowrap">
							{displayValue(report.marginTotals?.[m.key]?.native, NATIVE_CURRENCY_NAME)}
						</td>
					)}
					<td className="text-right text-nowrap">
						{displayValue(report.marginTotals?.[m.key]?.mgm)}
					</td>
				</React.Fragment>
			))}
			{showNative ? (
				<>
					<td className="text-right text-nowrap">
						{displayValue(overallData.native, NATIVE_CURRENCY_NAME)}
					</td>
					<td className="text-right text-nowrap">
						{displayValue(overallData.mgm)}
					</td>
				</>
			) : (
				<td className="text-right text-nowrap">
					{displayValue(overallData.mgm)}
				</td>
			)}
		</tr>
	)
}

const PnlTable = ({ report, months, showNative, useArchived, showMarginTotals }) => {
	const { t } = useTranslation()
	return (
		<Table bordered hover responsive className="table-striped table-sm mt-3">
			<DefaultReportHeader
				firstHeader={`${t('direction')} / ${t('expenseType')}`}
				months={months}
				showNative={showNative}
				totalLabel={t('total')}
			/>
			<tbody>
			{report.rows.map((row, index) => (
				<React.Fragment key={row.id}>
					<RecursiveMonthlyRow
						row={row}
						level={0}
						months={months}
						monthIndices={months.map((_, i) => i)}
						showNative={showNative}
						useArchived={useArchived}
					/>
					{index === 0 && showMarginTotals && (
						<MarginTotalsRow report={report} months={months} showNative={showNative} t={t} />
					)}
				</React.Fragment>
			))}
			<DefaultTotalsRow
				months={months}
				reportTotals={{ ...report.monthlyTotals, overall: report.overallTotal }}
				showNative={showNative}
			/>
			<AdditionalTotalsRows report={report} months={months} showNative={showNative} />
			</tbody>
		</Table>
	)
}

export default function PnlReportTable() {
	const { t, i18n } = useTranslation()
	const { data: expTypes = [] } = useLoadExpenseTypesListQuery()
	const { data: organizations = [] } = useLoadOrganizationsSelectQuery()
	const [documentDateFrom, setDocumentDateFrom] = useState(moment().startOf('year').toDate())
	const [documentDateTo, setDocumentDateTo] = useState(moment().endOf('year').toDate())
	const [selectedExpenseTypes, setSelectedExpenseTypes] = useState([])
	const [selectedOrganizations, setSelectedOrganizations] = useState([])
	const [viewMode, setViewMode] = useState(PnlReportMode.IncomeExpense)
	const [showNative, setShowNative] = useState(true)
	const [useArchived, setUseArchived] = useState(false)
	// New flag to conditionally display the MarginTotalsRow component
	const [showMarginTotals, setShowMarginTotals] = useState(true)

	const validFrom = documentDateFrom || moment().startOf('year').toDate()
	const validTo = documentDateTo || moment().endOf('year').toDate()
	const filters = useMemo(
		() => ({
			startDate: validFrom.toISOString(),
			endDate: validTo.toISOString(),
			organizations: selectedOrganizations.length ? selectedOrganizations : undefined,
			expenseTypes: selectedExpenseTypes.length ? selectedExpenseTypes : undefined,
			language: i18n.language as 'uk' | 'en' | undefined,
			mode: viewMode,
		}),
		[validFrom, validTo, selectedOrganizations, selectedExpenseTypes, i18n.language, viewMode]
	)
	const { data: reportData, isLoading, isFetching, isError } = useGetPnlQuery(filters)
	const report: PnlReport = reportData || {
		rows: [],
		monthlyTotals: {},
		overallTotal: { raw: 0, native: 0, mgm: 0 },
		months: [],
		marginTotals: {},
		operatingProfitEBIT: {},
		interestTotals: {},
		operatingProfitBeforeTaxation: {},
		taxesTotals: {},
		netIncome: {},
	}
	const monthsArr = extractMonths(report.months || [])
	return (
		<>
			<Row xs={1} sm={1} md={2} lg={2} xxl={4}>
				<ReportQuickSelect
					ranges={quarterRanges}
					defaultIndex={5}
					documentDateFrom={documentDateFrom}
					documentDateTo={documentDateTo}
					setDocumentDateFrom={setDocumentDateFrom}
					setDocumentDateTo={setDocumentDateTo}
				/>
				<Col md={12} lg={12} xxl={6} className="text-right">
					<PnlExcelButton
						data={reportData}
						dateFrom={documentDateFrom}
						months={monthsArr}
						dateTo={documentDateTo}
						showNative={showNative}
					/>
				</Col>
			</Row>
			<Row xs={1} sm={1} md={2} lg={2} xxl={4} className="mt-3">
				<Col>
					<MultiselectFormGroup
						placeholder={t('organizations')}
						options={organizations.map((org, idx) => ({ value: org._id, label: org.name, index: idx }))}
						onChange={(selected) => setSelectedOrganizations(selected.map((s) => s.value))}
					/>
				</Col>
				<Col>
					<MultiselectFormGroup
						placeholder={t('expenseType')}
						options={expTypes.map((exp, idx) => ({
							value: exp._id,
							label: exp.ukName || exp.enName,
							index: idx
						}))}
						onChange={(selected) => setSelectedExpenseTypes(selected.map((s) => s.value))}
					/>
				</Col>
			</Row>
			<ReportSwitches
				useArchived={useArchived}
				setUseArchived={setUseArchived}
				mode={viewMode}
				setMode={setViewMode}
				modeOptions={modeOptions}
				showNative={showNative}
				setShowNative={setShowNative}
			/>
			{isLoading || isFetching ? (
				<Loader />
			) : isError ? (
				<TableError>{t('request_error')}</TableError>
			) : report.rows.length > 0 ? (
				<PnlTable
					report={report}
					months={monthsArr}
					showNative={showNative}
					useArchived={useArchived}
					showMarginTotals={showMarginTotals}
				/>
			) : (
				<TableError>{t('no_data')}</TableError>
			)}
		</>
	)
}
