import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTrash } from 'react-icons/fa6';
import {SingleselectFormGroup} from "../../../../components/FormGroups";
import TextInput from "../../../../components/Settings/TextInput";

interface RoadmapLinksPanelProps {
    roadmapLinks: Record<string, string>;
    positionsOptions: { value: string; label: string, index: number }[];
    onChange: (updatedRoadmapLinks: Record<string, string>) => void;
}

function RoadmapLinksPanel({ roadmapLinks, positionsOptions, onChange }: RoadmapLinksPanelProps) {
    const { t } = useTranslation();

    // Convert the roadmapLinks record to an array for rendering.
    const linksArray = Object.entries(roadmapLinks); // [ [position, url], ... ]

    // Handler for when the user changes the position select.
    const handlePositionChange = (oldKey: string, newPosition: string) => {
        if (oldKey === newPosition) return;
        if (roadmapLinks[newPosition] !== undefined) return; // Avoid duplicates.
        const updated = { ...roadmapLinks };
        const currentUrl = updated[oldKey];
        delete updated[oldKey];
        updated[newPosition] = currentUrl;
        onChange(updated);
    };

    // Handler for URL input changes.
    const handleUrlChange = (key: string, newUrl: string) => {
        onChange({ ...roadmapLinks, [key]: newUrl });
    };

    // Handler to remove a link from the record.
    const handleRemove = (key: string) => {
        const updated = { ...roadmapLinks };
        delete updated[key];
        onChange(updated);
    };

    // Handler to add a new roadmap link.
    const handleAdd = () => {
        const existingPositions = Object.keys(roadmapLinks);
        const availableOptions = positionsOptions.filter(
            (opt) => !existingPositions.includes(opt.value)
        );
        if (availableOptions.length === 0) return;
        const newPosition = availableOptions[0].value;
        onChange({ ...roadmapLinks, [newPosition]: '' });
    };

    return (
        <div className="roadmap-links-panel">
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>{t('roadmap_links')}</h5>
                <Button
                    variant="primary"
                    onClick={handleAdd}
                    style={{ width: '28px', height: '28px', borderRadius: '4px' }}
                    className="d-flex justify-content-center align-items-center shadow-sm p-0"
                    disabled={Object.keys(roadmapLinks).length >= positionsOptions.length}
                >
                    <FaPlus />
                </Button>
            </div>
            {linksArray.map(([position, url]) => {
                // For the select, allow the current position plus any positions that aren’t already used.
                const availableOptions = positionsOptions.filter(
                    (opt) =>
                        opt.value === position ||
                        !Object.keys(roadmapLinks).includes(opt.value)
                );
                const selectValue =
                    availableOptions.find((opt) => opt.value === position) || null;

                return (
                    <Card key={position} className="mb-3">
                        <Card.Body>
                            <Row className='mb-2'>
                                <Col xs={4}>
                                    <SingleselectFormGroup
                                        label={t('position')}
                                        options={availableOptions}
                                        isClearable={false}
                                        value={selectValue}
                                        onChange={(selectedOption: { value: string; label: string }) =>
                                            handlePositionChange(position, selectedOption.value)
                                        }
                                    />
                                </Col>
                                <Col xs={6}/>
                                <Col xs={2} className="d-flex align-items-center justify-content-end">
                                    <Button
                                        variant="outline-danger"
                                        onClick={() => handleRemove(position)}
                                        style={{ width: '28px', height: '28px', borderRadius: '4px' }}
                                        className="justify-content-center align-items-center d-flex shadow-sm p-0 mb-0 mt-2"
                                    >
                                        <FaTrash />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextInput
                                        label="url"
                                        value={url}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleUrlChange(position, e.target.value)
                                        }
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                );
            })}
        </div>
    );
}

export default RoadmapLinksPanel;
