import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../api/axios.queryBuilder'
import Promotion, {
	PromotionCreatingData,
	AgreePromotionData,
	RejectPromotionData
} from '../../types/promotion'

export const promotionsApi = createApi({
	reducerPath: 'promotionsApi',
	baseQuery: axiosBaseQuery({ baseUrl: 'promotion' }),
	tagTypes: ['Promotion'],
	endpoints: (builder) => ({
		getAllPromotions: builder.query<Promotion[], void>({
			query: () => ({ url: '/all', method: 'GET' }),
			providesTags: ['Promotion']
		}),
		getEmployeePromotions: builder.query<Promotion[], string>({
			query: (employeeId) => ({ url: `/${employeeId}/employee_promotions`, method: 'GET' }),
			providesTags: ['Promotion']
		}),
		deletePromotion: builder.mutation<Promotion, string>({
			query: (promotionId) => ({ url: `/${promotionId}`, method: 'DELETE' }),
			invalidatesTags: ['Promotion']
		}),
		addPromotion: builder.mutation<Promotion, PromotionCreatingData>({
			query: (promotionData) => ({
				url: '/create',
				method: 'POST',
				data: promotionData
			}),
			invalidatesTags: ['Promotion']
		}),
		repeatPromotionRequest: builder.mutation<Promotion, string>({
			query: (promotionId) => ({
				url: `/${promotionId}/repeat_request`,
				method: 'PUT'
			}),
			invalidatesTags: ['Promotion']
		}),
		agreePromotionRequest: builder.mutation<Promotion, AgreePromotionData>({
			query: (agreeData) => ({
				url: '/agree_promotion',
				method: 'PUT',
				data: agreeData
			}),
			invalidatesTags: ['Promotion']
		}),
		rejectPromotionRequest: builder.mutation<Promotion, RejectPromotionData>({
			query: (rejectData) => ({
				url: '/reject_promotion',
				method: 'PUT',
				data: rejectData
			}),
			invalidatesTags: ['Promotion']
		})
	})
})

export const {
	useGetAllPromotionsQuery,
	useGetEmployeePromotionsQuery,
	useDeletePromotionMutation,
	useAddPromotionMutation,
	useRepeatPromotionRequestMutation,
	useAgreePromotionRequestMutation,
	useRejectPromotionRequestMutation
} = promotionsApi
