import React, { useEffect } from 'react';
import NumberInput from 'components/Settings/NumberInput';
import { VacationsDistributionEnum } from 'types/settings';
import { SingleselectFormGroup } from 'components/FormGroups';
import {useTranslation} from "react-i18next";
import {Col, Row} from "react-bootstrap";

interface VacationDaysDistributionPanelProps {
    distributionType: string;
    periodValues: number[];
    minimalVacationDaysPerYear: number;
    distributionOptions: { index: number; value: VacationsDistributionEnum; label: string }[];
    onPeriodValuesChange: (newValues: number[]) => void;
    onDistributionTypeChange: (newDistribution: VacationsDistributionEnum) => void;
}

const getPeriodCount = (distribution: VacationsDistributionEnum): number => {
    switch (distribution) {
        case VacationsDistributionEnum.yearly:
            return 1;
        case VacationsDistributionEnum.semiAnnually:
            return 2;
        case VacationsDistributionEnum.quarterly:
            return 4;
        default:
            return 1;
    }
};

function VacationDaysDistributionPanel({
                                           distributionType,
                                           periodValues,
                                           minimalVacationDaysPerYear,
                                           distributionOptions,
                                           onPeriodValuesChange,
                                           onDistributionTypeChange,
                                       }: VacationDaysDistributionPanelProps) {
    const { t } = useTranslation();
    const periodCount = getPeriodCount(distributionType as VacationsDistributionEnum);

    // Ensure periodValues array has the correct length.
    useEffect(() => {
        if (periodValues.length !== periodCount) {
            const newValues = Array(periodCount).fill(0);
            const base = Math.floor(minimalVacationDaysPerYear / periodCount);
            const remainder = minimalVacationDaysPerYear % periodCount;
            for (let i = 0; i < periodCount; i++) {
                newValues[i] = base + (i < remainder ? 1 : 0);
            }
            onPeriodValuesChange(newValues);
        }
    }, [periodCount, minimalVacationDaysPerYear, periodValues, onPeriodValuesChange]);

    const handleChange = (index: number, value: number) => {
        const newValues = [...periodValues];
        newValues[index] = value;
        onPeriodValuesChange(newValues);
    };

    const total = periodValues.reduce((sum, num) => sum + num, 0);
    const isValid = total >= minimalVacationDaysPerYear;

    return (
        <div className='pb-3'>
            <SingleselectFormGroup
                label={t('vacation_days_distribution')}
                options={distributionOptions}
                isClearable={false}
                value={
                    distributionOptions.find(opt => opt.value === distributionType) || null
                }
                onChange={(selected) => {
                    if (selected) {
                        onDistributionTypeChange(selected.value);
                    }
                }}
            />
            <Row>
                {Array.from({ length: periodCount }).map((_, index) => (
                    <Col>
                        <NumberInput
                            key={'period' + index}
                            md={12}
                            label={`${t('period')} ${index + 1}`}
                            value={periodValues[index] || 0}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleChange(index, Number(e.target.value))
                            }
                        />
                    </Col>
                ))}
            </Row>
            <div>
                <strong>{t('total')}: {total}</strong>{' '}
                {!isValid && (
                    <span className='text-danger'>(Must be at least: {minimalVacationDaysPerYear})</span>
                )}
            </div>
        </div>
    );
}

export default VacationDaysDistributionPanel;
