import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'react-bootstrap';
import { IconType } from 'react-icons';
import SettingsButton from '../Buttons/SettingsButton'

type SidebarCategoryProps = {
  name: string;
  icon: IconType;
  isOpen: boolean;
  children: React.ReactNode;
  onClick: () => void;
  to: string;
  isActive: boolean;
  withSettings?: boolean;
};

export const SidebarCategory: React.FC<SidebarCategoryProps> = React.memo(({ name, icon: Icon, isOpen, children, onClick, isActive, withSettings, to }) => {
  const { t } = useTranslation();

  return (
    <li className={`sidebar-item ${isActive ? 'active' : ''}`}>
      <span
        data-toggle="collapse"
        className={`sidebar-link ${!isOpen ? 'collapsed' : ''}`}
        onClick={onClick}
        aria-expanded={isOpen}
      >
        <Icon size={18} className="align-middle mr-3" />
        <span className="align-middle">
            {t(name)}
            {
                (withSettings && isOpen) && (
                    <div className={`d-flex float-end float-right ${children ? 'mr-4' : 'mr-2'}`}>
                        <SettingsButton url={`/${to}/settings`}/>
                    </div>
                )
            }
        </span>
      </span>
      <Collapse in={isOpen}>
        <ul className="sidebar-dropdown list-unstyled">
          {children}
        </ul>
      </Collapse>
    </li>
  );
});

