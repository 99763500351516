import React, {
	useState,
	useEffect,
	useCallback,
	useRef,
	useContext
} from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../components/Loaders/Loader';
import TableError from '../../../../components/TableError';
import DateIntervalControl from '../../../../components/Settings/DateIntervalControl';
import {
	useGetEmployeeSettingsQuery,
	useUpdateEmployeeSettingsMutation
} from '../../../../redux/slices/settings';
import { useGetShortEmployeesQuery } from '../../../../redux/slices/employees';
import { toast } from 'react-toastify';
import { MomentTimeEntry, PromotionsSettings } from '../../../../types/settings';
import { SettingsContext } from 'components/Contexts/SettingsContext';
import PositionsSelect from "../../../../components/Settings/PositionsSelect";
import { PositionsEnum, positionName } from '../../../../types/positions';
import RoadmapLinksPanel from "./RoadmapLinksPanel";

function PromotionsSettingsPage() {
	const { t } = useTranslation();
	const { registerSaveHandler, registerCancelHandler, setIsDirty } =
		useContext(SettingsContext);

	const {
		data: settings,
		isLoading,
		error,
		refetch: refetchSettings
	} = useGetEmployeeSettingsQuery();
	const { data: employeesData, isLoading: isLoadingEmployees } =
		useGetShortEmployeesQuery();
	const [updateEmployeeSettings] = useUpdateEmployeeSettingsMutation();

	const initialTimeEntry = { amount: 0, unit: 'days' } as MomentTimeEntry;

	// Form state including roadmapLinks as a record.
	const [formState, setFormState] = useState<PromotionsSettings>({
		generalApprovers: [],
		overdueApprovers: [],
		timeToCheck: initialTimeEntry,
		promotionRequestsInterval: initialTimeEntry,
		rejectedPromotionRequestsInterval: initialTimeEntry,
		timeToFirstPromotion: initialTimeEntry,
		roadmapLinks: {}
	});
	const [initialFormState, setInitialFormState] =
		useState<PromotionsSettings | null>(null);

	// Update the form state for a given field.
	const handlePositionsSelectChange = (
		field: keyof PromotionsSettings
	) => (selected: any[]) => {
		const values = selected.map(item =>
			typeof item === 'object' && item !== null ? item.value : item
		);
		setFormState(prev => ({ ...prev, [field]: values }));
	};

	// Reset the form when settings load.
	const resetForm = useCallback(() => {
		if (settings?.promotionsSettings && employeesData) {
			const promotions = settings.promotionsSettings;
			setFormState(promotions);
			setInitialFormState(promotions);
		}
	}, [settings, employeesData]);

	useEffect(() => {
		resetForm();
	}, [resetForm]);

	// Determine if the form is dirty.
	useEffect(() => {
		if (initialFormState) {
			const dirty =
				JSON.stringify(formState) !== JSON.stringify(initialFormState);
			setIsDirty(dirty);
		}
	}, [formState, initialFormState, setIsDirty]);

	const handleDateIntervalChange = (
		field: keyof PromotionsSettings,
		newVal: MomentTimeEntry
	) => {
		setFormState(prev => ({ ...prev, [field]: newVal }));
	};

	// Keep refs for the latest state.
	const formStateRef = useRef(formState);
	useEffect(() => {
		formStateRef.current = formState;
	}, [formState]);

	const settingsRef = useRef(settings);
	useEffect(() => {
		settingsRef.current = settings;
	}, [settings]);

	// Register save and cancel handlers.
	useEffect(() => {
		registerSaveHandler(async () => {
			if (!settingsRef.current) return;
			try {
				const updatedSettings = {
					...settingsRef.current,
					promotionsSettings: {
						...settingsRef.current.promotionsSettings,
						...formStateRef.current
					}
				};
				await updateEmployeeSettings(updatedSettings);
				await refetchSettings();
				toast.success(t('settings_updated_successfully') as string);
				setInitialFormState(formStateRef.current);
			} catch (e) {
				toast.error(t('failed_to_update_settings') as string);
				resetForm();
			}
		});
		registerCancelHandler(() => {
			resetForm();
		});
	}, [
		registerSaveHandler,
		registerCancelHandler,
		updateEmployeeSettings,
		refetchSettings,
		t,
		resetForm
	]);

	// Build positions options from PositionsEnum.
	const positionsOptions = Object.values(PositionsEnum).map((pos, index) => ({
		value: pos,
		label: positionName(pos),
		index
	}));

	if (isLoading || isLoadingEmployees) return <Loader />;
	if (error) return <TableError>Error loading settings.</TableError>;

	return (
		<Row>
			<Col md={4}>
				<PositionsSelect
					label={t('general_approver')}
					positions={formState.generalApprovers}
					withEmployeesDisplay
					setPositions={handlePositionsSelectChange('generalApprovers')}
				/>
				<PositionsSelect
					label={t('overdue_approver')}
					positions={formState.overdueApprovers}
					withEmployeesDisplay
					setPositions={handlePositionsSelectChange('overdueApprovers')}
				/>
				<hr className="mt-0" />
				<DateIntervalControl
					label={t('time_to_check')}
					value={formState.timeToCheck}
					onChange={(newVal: MomentTimeEntry) =>
						handleDateIntervalChange('timeToCheck', newVal)
					}
				/>
				<DateIntervalControl
					label={t('promotion_request_interval')}
					value={formState.promotionRequestsInterval}
					onChange={(newVal: MomentTimeEntry) =>
						handleDateIntervalChange('promotionRequestsInterval', newVal)
					}
				/>
				<DateIntervalControl
					label={t('rejected_promotion_requests_interval')}
					value={formState.rejectedPromotionRequestsInterval}
					onChange={(newVal: MomentTimeEntry) =>
						handleDateIntervalChange('rejectedPromotionRequestsInterval', newVal)
					}
				/>
				<DateIntervalControl
					label={t('time_to_first_promotion')}
					value={formState.timeToFirstPromotion}
					onChange={(newVal: MomentTimeEntry) =>
						handleDateIntervalChange('timeToFirstPromotion', newVal)
					}
				/>
			</Col>
			<Col md={2} />
			<Col md={6}>
				<RoadmapLinksPanel
					roadmapLinks={formState.roadmapLinks}
					positionsOptions={positionsOptions}
					onChange={(updatedRoadmapLinks) =>
						setFormState(prev => ({ ...prev, roadmapLinks: updatedRoadmapLinks }))
					}
				/>
			</Col>
		</Row>
	);
}

export default PromotionsSettingsPage;
