export enum PositionsEnum {
	ceo = 'ceo',
	cto = 'cto',
	cpo = 'cpo',

	cfo = 'cfo',

	head_of_development = 'head_of_development',
	lead_developer = 'lead_developer',
	technical_art_director = 'technical_art_director',
	lead_three_d_artist = 'lead_three_d_artist',
	lead_concept_artist = 'lead_concept_artist',
	lead_level_designer = 'lead_level_designer',
	co_lead_developer = 'co_lead_developer',

	hr = 'hr',

	first_game_producer = 'first_game_producer',
	second_game_producer = 'second_game_producer',
	associate_producer = 'associate_producer',

	unity_developer = 'unity_developer',

	three_d_generalist = 'three_d_generalist',
	three_d_artist = 'three_d_artist',
	three_d_animator = 'three_d_animator',
	promo_artist = 'promo_artist',
	/* two_d_animator = 'two_d_animator', */
	concept_artist = 'concept_artist',

	release_manager = 'release_manager',
	qa = 'qa',

	game_designer = 'game_designer',
	level_designer = 'level_designer',
	economy_designer = 'economy_designer',
	sound_designer = 'sound_designer',
	motion_designer = 'motion_designer',

	business_development_manager = 'business_development_manager',
	user_acquisition_manager = 'user_acquisition_manager',
	community_manager = 'community_manager',

	full_stack_developer = 'full_stack_developer',
	javascript_developer = 'javascript_developer',
	project_manager = 'project_manager',

	office_manager = 'office_manager',
	system_administrator = 'system_administrator',

	lead_recruiter = 'lead_recruiter',
	recruiter = 'recruiter',
	secretary = 'secretary',
	document_manager = 'document_manager',
	assistant_manager = 'assistant_manager',
	masseur = 'masseur',
	cleaner = 'cleaner',

	other = 'other'
}

export const positionName = (position: PositionsEnum) => {
	switch (position) {
		case PositionsEnum.ceo:
			return 'CEO'
		case PositionsEnum.cto:
			return 'CTO'
		case PositionsEnum.cpo:
			return 'CPO'
		case PositionsEnum.cfo:
			return 'CFO'
		case PositionsEnum.first_game_producer:
			return 'Game Producer'
		case PositionsEnum.second_game_producer:
			return 'Head of Production'
		case PositionsEnum.associate_producer:
			return 'Associate Producer'
		case PositionsEnum.head_of_development:
			return 'Head of Development'
		case PositionsEnum.lead_developer:
			return 'Lead Of Developer'
		case PositionsEnum.co_lead_developer:
			return 'Co-lead Developer'
		case PositionsEnum.technical_art_director:
			return 'Technical Art Director'
		case PositionsEnum.lead_three_d_artist:
			return 'Lead 3D Artist'
		case PositionsEnum.lead_concept_artist:
			return 'Lead Concept Artist'
		case PositionsEnum.three_d_generalist:
			return '3D Generalist'
		case PositionsEnum.three_d_artist:
			return '3D Artist'
		case PositionsEnum.three_d_animator:
			return '3D Animator'
		case PositionsEnum.promo_artist:
			return 'Promo Artist'
		/* case PositionsEnum.two_d_animator:
			return '2D Animator' */
		case PositionsEnum.concept_artist:
			return 'Concept Artist'
		case PositionsEnum.unity_developer:
			return 'Unity Developer'
		case PositionsEnum.release_manager:
			return 'Release Manager'
		case PositionsEnum.qa:
			return 'QA'
		case PositionsEnum.office_manager:
			return 'Office Manager'
		case PositionsEnum.system_administrator:
			return 'System Administrator'
		case PositionsEnum.assistant_manager:
			return 'Assistant Manager'
		case PositionsEnum.hr:
			return 'HR'
		case PositionsEnum.business_development_manager:
			return 'Business Development Manager'
		case PositionsEnum.lead_level_designer:
			return 'Lead Level Designer'
		case PositionsEnum.level_designer:
			return 'Level Designer'
		case PositionsEnum.economy_designer:
			return 'Game Economy Designer'
		case PositionsEnum.game_designer:
			return 'Game Designer'
		case PositionsEnum.sound_designer:
			return 'Game Sound Designer'
		case PositionsEnum.motion_designer:
			return 'Motion Designer'
		case PositionsEnum.community_manager:
			return 'Community Manager'
		case PositionsEnum.lead_recruiter:
			return 'Lead recruiter'
		case PositionsEnum.recruiter:
			return 'Recruiter'
		case PositionsEnum.secretary:
			return 'Secretary'
		case PositionsEnum.document_manager:
			return 'Document manager'
		case PositionsEnum.user_acquisition_manager:
			return 'User Acquisition Manager'
		case PositionsEnum.full_stack_developer:
			return 'Full Stack Developer'
		case PositionsEnum.javascript_developer:
			return 'JavaScript Developer'
		case PositionsEnum.project_manager:
			return 'Project Manager'
		case PositionsEnum.masseur:
			return 'Masseur'
		case PositionsEnum.cleaner:
			return 'Cleaner'
		case PositionsEnum.other:
			return 'Other'
		default:
			return 'Other'
	}
}
