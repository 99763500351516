import moment from 'moment'
import { Col, Dropdown, ListGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NotificationTypeEnum, SystemNotification } from 'types/notification'
import { FiMessageCircle } from 'react-icons/fi'
import React from 'react'
import { MdErrorOutline, MdInfoOutline, MdWarningAmber } from 'react-icons/md'
import {
	useDeleteNotificationMutation,
	useLoadNotificationsQuery,
	useUpdateNotificationMutation
} from '../../redux/slices/notifications'

function MessageLabel(prop: { type: NotificationTypeEnum }) {
	const iconSize = 20

	switch (prop.type) {
		case NotificationTypeEnum.danger:
			return <MdErrorOutline className="text-danger p-0 m-0" size={iconSize} />

		case NotificationTypeEnum.warning:
			return <MdWarningAmber className="text-warning p-0 m-0" size={iconSize} />

		case NotificationTypeEnum.info:
			return <MdInfoOutline className="text-info p-0 m-0" size={iconSize} />

		default:
			return null
	}
}

interface MessageItemProps {
	message: SystemNotification
	onClick: () => void
	onDismiss: () => void
}

function MessageItem({ message, onClick, onDismiss }: MessageItemProps) {
	const { i18n, t } = useTranslation()
	const { type, date, title, text } = message

	return (
		<ListGroup.Item style={!message.isChecked ? { backgroundColor: 'rgba(159,196,255,0.3)' } : {}}>
			<Row className="align-items-center">
				<Col xs={1} className='align-self-start p-0'>
					<MessageLabel type={type}/>
				</Col>
				<Col xs={10} onClick={onClick} className='p-0'>
					<div className="text-dark">{title}</div>
					<div className="text-muted small mt-1">
						{i18n.exists(text) ? t(text) : text}
					</div>
				</Col>
				<Col xs={1} className="align-self-start p-0">
					<button
						type="button"
						className="close border-0"
						aria-label="Close"
						onClick={onDismiss}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</Col>
			</Row>
			<Row>
				<Col className="text-right text-muted small mt-1">
					{moment(date).calendar().toLowerCase()}
				</Col>
			</Row>
		</ListGroup.Item>
	)
}

export default function Messages() {
	const { data: messages = [], isLoading, isError } = useLoadNotificationsQuery()

	const [updateNotification] = useUpdateNotificationMutation()
	const [deleteNotification] = useDeleteNotificationMutation()

	if (isLoading || isError) return null

	const newMessagesCount = messages.filter((m) => !m.isChecked).length

	function handleonClick(message: SystemNotification) {
		if (!message.isChecked) {
			updateNotification(message._id)
		}
	}

	return (
		<Dropdown className="me-2 mr-2 nav-item" align="end">
			<Dropdown.Toggle className="bg-white px-0 text-dark border-0 shadow-none nav-link nav-icon dropdown-toggle ">
				<FiMessageCircle/>
				<div>
					{
						newMessagesCount > 0 && (
							<span className="danger_indicator">{newMessagesCount}</span>
						)
					}
				</div>
			</Dropdown.Toggle>
			<Dropdown.Menu className="dropdown-menu-lg p-0 message_dropdown_menu">
				<ListGroup>
					{
						messages.length > 0 ? (
							messages.map((message: SystemNotification) => (
								<MessageItem
									key={message._id}
									message={message}
									onClick={() => handleonClick(message)}
									onDismiss={() => deleteNotification(message._id)}
								/>
							))
						) : (
							<div className='d-flex align-items-center justify-content-center py-1'>
								No messages
							</div>
						)
					}
				</ListGroup>
			</Dropdown.Menu>
		</Dropdown>
	)
}
