import React from 'react';
import {Card, Button, Row, Col} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MultiselectFormGroup } from '../../../../components/FormGroups';
import { ConflictingPositions } from '../../../../types/settings';
import { FaPlus, FaTrash } from 'react-icons/fa6';

interface ConflictingPositionsPanelProps {
    conflictingPositions: ConflictingPositions[];
    positionsOptions: { value: string; label: string }[];
    onAdd: (newItem: ConflictingPositions) => void;
    onUpdate: (index: number, updatedItem: ConflictingPositions) => void;
    onRemove: (index: number) => void;
}

function ConflictingPositionsPanel({
                                       conflictingPositions,
                                       positionsOptions,
                                       onAdd,
                                       onUpdate,
                                       onRemove,
                                   }: ConflictingPositionsPanelProps) {
    const { t } = useTranslation();

    const allSelected = conflictingPositions.flatMap(card => card.conflictingPositions);

    const handleAdd = () => {
        const availableOptions = positionsOptions.filter(
            option => !allSelected.includes(option.value)
        );
        if (availableOptions.length === 0) return;
        const newCard: ConflictingPositions = {
            conflictingPositions: [],
        };
        onAdd(newCard);
    };

    const handleUpdate = (index: number, selectedOptions: { value: any; }[]) => {
        onUpdate(index, {
            conflictingPositions: selectedOptions.map((option: { value: any; }) => option.value),
        })
    }

    return (
        <div className="conflicting-positions-panel">
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>{t('conflicting_positions_groups')}</h5>
                <Button
                    variant="primary"
                    onClick={handleAdd}
                    style={{ width: '28px', height: '28px', borderRadius: '4px' }}
                    className="d-flex justify-content-center align-items-center shadow-sm p-0"
                    disabled={allSelected.length >= positionsOptions.length}
                >
                    <FaPlus />
                </Button>
            </div>
            {conflictingPositions.map((item, index) => {
                const otherSelected = conflictingPositions
                    .filter((_, idx) => idx !== index)
                    .flatMap(card => card.conflictingPositions);
                const availableOptions = positionsOptions.filter(
                    option =>
                        item.conflictingPositions.includes(option.value) ||
                        !otherSelected.includes(option.value)
                );

                const selectedFromSettings = positionsOptions.filter(option =>
                    item.conflictingPositions.includes(option.value)
                )

                return (
                    <Card key={index} className="mb-3">
                        <Card.Body className="d-flex align-items-center justify-content-between">
                            <Row className="w-100 d-flex">
                                <Col xs={11}>
                                    <MultiselectFormGroup
                                        label={`${t('group')} ${(index + 1)}`}
                                        options={availableOptions}
                                        value={selectedFromSettings}
                                        onChange={selectedOptions => handleUpdate(index, selectedOptions)}
                                    />
                                </Col>
                                <Col xs={1} className="d-flex pb-4 align-items-end">
                                    <Button
                                        variant="outline-danger"
                                        onClick={() => onRemove(index)}
                                        style={{ width: '28px', height: '28px', borderRadius: '4px' }}
                                        className="d-flex justify-content-center align-items-center shadow-sm p-0 ms-2"
                                    >
                                        <FaTrash />
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                );
            })}
        </div>
    );
}

export default ConflictingPositionsPanel;
