import { configureStore } from '@reduxjs/toolkit'
import { UserState } from 'redux/reducers/userReducer'
import rootReducer from '../reducers/index'
import { financeDictionariesApi } from '../slices/financeDictionaries'
import { rtkQueryErrorLogger } from '../middlewares/rtkQueryErrorLogger'
import { projectsApi } from '../slices/projects'
import { publishersApi } from '../slices/publishers'
import { operationStatementsApi } from '../slices/operationStatements'
import { invoicesApi } from '../slices/invoices'
import { salariesTableApi } from '../slices/salariesTable'
import { reportsApi } from '../slices/reports'
import { systemSettingsApi } from '../slices/systemSettings'
import { replenishmentRequestApi } from '../slices/replenishmentRequests'
import { cronApi } from '../slices/crons'
import { divisionsApi } from '../slices/divisions'
import { employeesApi } from '../slices/employees'
import { systemNotificationsApi } from '../slices/notifications'
import { settingsApi } from '../slices/settings'
import { promotionsApi } from '../slices/promotions'
import { sicknessesApi } from '../slices/sicknesses'
import { vacationsApi } from '../slices/vacations'
import { timeTrackingApi } from "../slices/timeTracking";

const store = configureStore({
	reducer: rootReducer,
	devTools: process.env.NODE_ENV === 'development',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		})
			.concat(projectsApi.middleware)
			.concat(publishersApi.middleware)
			.concat(financeDictionariesApi.middleware)
			.concat(operationStatementsApi.middleware)
			.concat(invoicesApi.middleware)
			.concat(salariesTableApi.middleware)
			.concat(reportsApi.middleware)
			.concat(systemSettingsApi.middleware)
			.concat(replenishmentRequestApi.middleware)
			.concat(cronApi.middleware)
			.concat(divisionsApi.middleware)
			.concat(employeesApi.middleware)
			.concat(systemNotificationsApi.middleware)
			.concat(settingsApi.middleware)
			.concat(promotionsApi.middleware)
			.concat(sicknessesApi.middleware)
			.concat(vacationsApi.middleware)
			.concat(timeTrackingApi.middleware)
			.concat(rtkQueryErrorLogger)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

export interface StoreType {
	theme: any;
	data: any;
	user: UserState;

	publishersApi: ReturnType<typeof publishersApi.reducer>;
	financeApi: ReturnType<typeof financeDictionariesApi.reducer>;
	projectsApi: ReturnType<typeof projectsApi.reducer>;
	operationStatementsApi: ReturnType<typeof operationStatementsApi.reducer>;
	invoicesApi: ReturnType<typeof invoicesApi.reducer>;
	salariesTableApi: ReturnType<typeof salariesTableApi.reducer>;
	reportsApi: ReturnType<typeof reportsApi.reducer>;
	replenishmentRequestApi: ReturnType<typeof replenishmentRequestApi.reducer>;
	systemSettingsApi: ReturnType<typeof systemSettingsApi.reducer>;
	cronApi: ReturnType<typeof cronApi.reducer>;
	divisionsApi: ReturnType<typeof divisionsApi.reducer>;
	employeesApi: ReturnType<typeof employeesApi.reducer>;
	systemNotificationsApi: ReturnType<typeof systemNotificationsApi.reducer>;
	settingsApi: ReturnType<typeof settingsApi.reducer>;
	promotionsApi: ReturnType<typeof promotionsApi.reducer>;
	sicknessesApi: ReturnType<typeof sicknessesApi.reducer>;
	vacationsApi: ReturnType<typeof vacationsApi.reducer>;
	timeTrackingApi: ReturnType<typeof timeTrackingApi.reducer>;
}
