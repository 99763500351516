import { Organization } from './organization'
import { Contractor } from './contractor'

export enum Conditions {
	prepayment = 'prepayment',
	deferral = 'deferral'
}

export enum Postponement {
	zero = '0',
	plus30 = '+30',
	minus30 = '-30',
	plus45 = '+45',
	minus45 = '-45',
	plus60 = '+60',
	minus60 = '-60',
	plus90 = '+90',
	minus90 = '-90',
}

export interface Contract {
	_id: string;
	organization: Organization;
	contractor: Contractor;
	type: ContractType;
	name: string;
	creationDate: string; //DateISO
 	conditions?: Conditions;
	postponement?: Postponement;
	isArchived: boolean;
	isSystem?: boolean;
}

export interface ContractSelect {
	_id: string;
	name: string;
	contractor: string;
}

export interface AddContractDto {
	organization: string;
	contractor: string;
	type: string;
	name: string;
	creationDate: string; //DateISO
	conditions?: string;
	postponement?: string;
}

export interface ContractType {
	_id: string;
	enName: string;
	ukName: string;
	isArchived: boolean;
}

export interface CreateContractTypeDto {
	enName: string;
	ukName: string;
}

export interface UpdateContractTypeDto {
	enName?: string;
	ukName?: string;
	isArchived?: boolean;
}