import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MomentTimeEntry, TimeUnit } from '../../types/settings';
import { SingleselectFormGroup } from '../FormGroups';
import { useTranslation } from 'react-i18next'

const unitOptions = [
	{ value: 'day', label: 'day', index: 0 },
	{ value: 'week', label: 'week', index: 1 },
	{ value: 'month', label: 'month', index: 2 },
	{ value: 'year', label: 'year', index: 3 },
];

export interface DateIntervalControlProps {
	label: string;
	value?: MomentTimeEntry;
	onChange: (value: any) => void;
	error?: string;
	isDisabled?: boolean;
}

function DateIntervalControl({
																	 label,
																	 value,
																	 onChange,
																	 error,
																	 isDisabled
																 }: DateIntervalControlProps) {
	const { t } = useTranslation()
	const handleChange = (field: 'amount' | 'unit') => (input: any) => {
		// Provide a default value if `value` is undefined
		const currentValue: MomentTimeEntry = value || { amount: 0, unit: 'day' };

		if (field === 'amount') {
			onChange({ ...currentValue, amount: Number(input.target.value) });
		} else {
			const unitValue =
				input && typeof input === 'object' && input.value
					? input.value
					: input;
			onChange({ ...currentValue, unit: unitValue as TimeUnit });
		}
	};

	let defaultOption: typeof unitOptions[number] | undefined;

	const timeUnitOptions = unitOptions.map((op, index) => {
		const option = { ...op, label: t(op.label), index };
		if (op.value === value?.unit) {
			defaultOption = option;
		}
		return option;
	});

	return (
		<Form.Group>
			<label>{label}</label>
			<Row className="d-flex align-items-center">
				<Col md={3} className="d-flex align-items-center pb-3">
					<Form.Control
						type="number"
						value={value?.amount}
						onChange={handleChange('amount')}
						isInvalid={!!error}
						disabled={isDisabled}
					/>
				</Col>
				<Col md={5} className="d-flex align-items-center">
					<SingleselectFormGroup
						label=""
						className='w-100'
						options={timeUnitOptions}
						value={defaultOption}
						onChange={handleChange('unit')}
						isClearable={false}
						isDisabled={isDisabled}
					/>
				</Col>
			</Row>
			{error && (
				<Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
			)}
		</Form.Group>
	);
}

export default DateIntervalControl;
