import { Container, Nav, Tab } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Lateness from './Lateness'
import Vacations from "./Vacations";
import {SettingsProvider} from "../../../../components/Contexts/SettingsContext";
import Header from "../../../../components/Settings/Header";

function HRSettingsContainer({ children }) {
	const { t } = useTranslation()

	return (
		<Container fluid className="p-0">
			<SettingsProvider>
			<Header />
			<div className={'tab'}>
				<Tab.Container id="left-tabs-example">
					<Nav variant="tabs">
						<Nav.Item>
							<Nav.Link
								as={NavLink}
								to="/HR/settings/vacations"
							>
								{t('HRSettings_vacations')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								as={NavLink}
								to="/HR/settings/lateness"
							>
								{t('HRSettings_lateness')}
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<Tab.Content>
						{children}
					</Tab.Content>
				</Tab.Container>
			</div>
			</SettingsProvider>
		</Container>
	)
}

export function VacationsSettingsTab() {
	return (
		<HRSettingsContainer>
			<Vacations/>
		</HRSettingsContainer>
	)
}

export function LatenessSettingsTab() {
	return (
		<HRSettingsContainer>
			<Lateness/>
		</HRSettingsContainer>
	)
}


