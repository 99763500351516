import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Row } from 'react-bootstrap'
import { FaSave, FaTimes } from 'react-icons/fa'
import { SettingsContext } from '../Contexts/SettingsContext'

const Header = () => {
	const { t } = useTranslation()
	const { isDirty, saveSettings, cancelSettings, isUpdating } = useContext(SettingsContext);

	return (
		<Row className="mb-2 mb-xxl-4">
			<Col xs="auto" className="d-none d-sm-block">
				<h3>{t('Settings_header')}</h3>
			</Col>

			<Col xs="auto" className="d-flex ml-auto text-right mt-n1">
				{
					isDirty && (
						<Button
							variant="secondary"
							onClick={cancelSettings}
							className="justify-content-center align-items-center d-flex shadow-sm mb-0 ml-2 mt-xs-2 mb-1"
							disabled={isUpdating}
						>
							<FaTimes className="mr-2" />
							<span>{t('cancel')}</span>
						</Button>
					)
				}
				<Button
					variant="primary"
					onClick={saveSettings}
					className='justify-content-center align-items-center d-flex shadow-sm mb-0 ml-2 mt-xs-2 mb-1'
					disabled={isUpdating || !isDirty}
				>
					<FaSave className="mr-2" />
					<span>{t('save_changes')}</span>
				</Button>
			</Col>
		</Row>
	)
}

export default Header
