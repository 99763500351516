import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import Loader from '../../../../components/Loaders/Loader';
import TableError from '../../../../components/TableError';
import {useGetHRSettingsQuery, useUpdateHRSettingsMutation} from '../../../../redux/slices/settings';
import {toast} from 'react-toastify';
import {SettingsContext} from 'components/Contexts/SettingsContext';
import ToggleSwitch from "../../../../components/Settings/ToggleSwitch";
import DateIntervalControl from "../../../../components/Settings/DateIntervalControl";
import {
	ConflictingPositions,
	MomentTimeEntry,
	VacationsDistributionEnum,
	VacationsSettings
} from '../../../../types/settings';
import NumberInput from "../../../../components/Settings/NumberInput";
import PositionsSelect from "../../../../components/Settings/PositionsSelect";
import {positionName, PositionsEnum} from '../../../../types/positions';
import ConflictingPositionsPanel from "./ConflictingPositionsPanel";
import VacationDaysDistributionPanel from "./VacationDaysDistributionPanel";

const defaultVacationsSettings: VacationsSettings = {
	autoApprove: false,
	allowVacationsBetweenPeriods: false,
	minimumEmployedTime: { amount: 0, unit: 'day' },
	minimalPeriodBetweenVacations: { amount: 0, unit: 'day' },
	minimalPeriodAfterReject: { amount: 0, unit: 'day' },
	minimalAdvanceTime: { amount: 0, unit: 'day' },
	maximalAdvanceTime: { amount: 0, unit: 'day' },
	minimalVacationDaysPerYear: 0,
	vacationDaysDistribution: VacationsDistributionEnum.semiAnnually,
	vacationDaysDistributionPerPeriods: [],
	minimalVacationDuration: { amount: 0, unit: 'day' },
	maximalVacationDuration: { amount: 0, unit: 'day' },
	approverEmployees: [],
	positionsThatAffectProjectDeadlines: [],
	positionsThatCanNotBeFrozen: [],
	positionsThatCanTakeRefunds: [],
	conflictingPositions: [],
};

function Vacations() {
	const { t } = useTranslation();
	const { registerSaveHandler, registerCancelHandler, setIsDirty } = useContext(SettingsContext);
	const { data: settings, isLoading, error, refetch: refetchSettings } = useGetHRSettingsQuery();
	const [updateHRSettings] = useUpdateHRSettingsMutation();
	const [formState, setFormState] = useState<VacationsSettings>(defaultVacationsSettings);
	const [initialFormState, setInitialFormState] = useState<VacationsSettings>(defaultVacationsSettings);

	const vacationsDistributionOptions = useMemo(() => {
		return Object.keys(VacationsDistributionEnum)
			.filter(key => isNaN(Number(key))) // Only use non-numeric keys
			.map((key, i) => ({
				index: i,
				value: VacationsDistributionEnum[key as keyof typeof VacationsDistributionEnum],
				label: t(VacationsDistributionEnum[key as keyof typeof VacationsDistributionEnum])
			}));
	}, [t]);

	useEffect(() => {
		if (settings && settings.vacationsSettings) {
			setFormState(settings.vacationsSettings);
			setInitialFormState(settings.vacationsSettings);
		}
	}, [settings]);

	useEffect(() => {
		const dirty = JSON.stringify(formState) !== JSON.stringify(initialFormState);
		setIsDirty(dirty);
	}, [formState, initialFormState, setIsDirty]);

	const handleToggleChange = (field: keyof VacationsSettings) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormState(prev => ({ ...prev, [field]: e.target.checked }));
	};

	const handleTimeIntervalChange = (field: keyof VacationsSettings, newVal: MomentTimeEntry) => {
		setFormState(prev => ({ ...prev, [field]: newVal }));
	};

	const handleNumberChange = (field: keyof VacationsSettings) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormState(prev => ({ ...prev, [field]: Number(e.target.value) }));
	};

	const handleMultiselectFormGroupChange = (field: keyof VacationsSettings) => (selected: any[]) => {
		const values = selected.map(item =>
			typeof item === 'object' && item !== null ? item.value : item
		);
		setFormState(prev => ({ ...prev, [field]: values }));
	};

	const handleDistributionTypeChange = (newDistribution: VacationsDistributionEnum) => {
		setFormState(prev => ({ ...prev, vacationDaysDistribution: newDistribution }));
	};

	const handlePeriodDistributionValuesChange = (newValues: number[]) => {
		setFormState(prev => ({ ...prev, vacationDaysDistributionPerPeriods: newValues }));
	};

	// --- Conflicting positions logic ---
	const addConflictingPosition = (newItem: ConflictingPositions) => {
		setFormState(prev => ({
			...prev,
			conflictingPositions: [
				...prev.conflictingPositions,
				newItem
			]
		}));
	};

	const updateConflictingPosition = (index: number, updatedItem: ConflictingPositions) => {
		const newList = [...formState.conflictingPositions];
		newList[index] = updatedItem;
		setFormState(prev => ({ ...prev, conflictingPositions: newList }));
	};

	const removeConflictingPosition = (index: number) => {
		const newList = formState.conflictingPositions.filter((_, i) => i !== index);
		setFormState(prev => ({ ...prev, conflictingPositions: newList }));
	};

	const formStateRef = useRef(formState);
	useEffect(() => { formStateRef.current = formState; }, [formState]);
	const settingsRef = useRef(settings);
	useEffect(() => { settingsRef.current = settings; }, [settings]);

	useEffect(() => {
		registerSaveHandler(async () => {
			if (!settingsRef.current) return;
			try {
				const updatedSettings = {
					...settingsRef.current,
					vacationsSettings: {
						...settingsRef.current.vacationsSettings,
						...formStateRef.current
					}
				};
				await updateHRSettings(updatedSettings);
				await refetchSettings();
				toast.success(t('settings_updated_successfully') as string);
				setInitialFormState(formStateRef.current);
			} catch (err) {
				toast.error(t('failed_to_update_settings') as string);
				setFormState(initialFormState);
			}
		});

		registerCancelHandler(() => {
			setFormState(initialFormState);
		});
	}, [registerSaveHandler, registerCancelHandler, updateHRSettings, refetchSettings, t, initialFormState]);

	// Compute positions options from PositionsEnum
	const positionsOptions = useMemo(() => {
		return Object.keys(PositionsEnum).map((key, i) => ({
			index: i,
			value: PositionsEnum[key],
			label: positionName(PositionsEnum[key])
		}));
	}, []);

	if (isLoading) return <Loader />;
	if (error) return <TableError>Error loading settings.</TableError>;

	return (
		<Row>
			<Col md={4}>
				<Form>
					<PositionsSelect
						positions={formState.approverEmployees}
						withEmployeesDisplay
						setPositions={handleMultiselectFormGroupChange('approverEmployees')}
						label={t('approver_employee')}
					/>
					<hr className="mt-0"/>
					<ToggleSwitch
						id="autoApprove"
						label={t('auto_approve')}
						checked={formState.autoApprove}
						onChange={handleToggleChange('autoApprove')}
					/>
					<ToggleSwitch
						id="allowVacationsBetweenPeriods"
						label={t('allow_vacations_between_periods')}
						checked={formState.allowVacationsBetweenPeriods}
						onChange={handleToggleChange('allowVacationsBetweenPeriods')}
					/>
					<hr className="mt-0"/>
					<NumberInput
						label="minimal_vacation_days_per_year"
						value={formState.minimalVacationDaysPerYear}
						onChange={handleNumberChange('minimalVacationDaysPerYear')}
					/>
					<DateIntervalControl
						label={t('minimum_employed_time')}
						value={formState.minimumEmployedTime}
						onChange={(newVal) => handleTimeIntervalChange('minimumEmployedTime', newVal)}
					/>
					<DateIntervalControl
						label={t('minimal_period_between_vacations')}
						value={formState.minimalPeriodBetweenVacations}
						onChange={(newVal) => handleTimeIntervalChange('minimalPeriodBetweenVacations', newVal)}
					/>
					<DateIntervalControl
						label={t('minimal_period_after_reject')}
						value={formState.minimalPeriodAfterReject}
						onChange={(newVal) => handleTimeIntervalChange('minimalPeriodAfterReject', newVal)}
					/>
					<DateIntervalControl
						label={t('minimal_advance_time')}
						value={formState.minimalAdvanceTime}
						onChange={(newVal) => handleTimeIntervalChange('minimalAdvanceTime', newVal)}
					/>
					<DateIntervalControl
						label={t('maximal_advance_time')}
						value={formState.maximalAdvanceTime}
						onChange={(newVal) => handleTimeIntervalChange('maximalAdvanceTime', newVal)}
					/>
					<DateIntervalControl
						label={t('minimal_vacation_duration')}
						value={formState.minimalVacationDuration}
						onChange={(newVal) => handleTimeIntervalChange('minimalVacationDuration', newVal)}
					/>
					<DateIntervalControl
						label={t('maximal_vacation_duration')}
						value={formState.maximalVacationDuration}
						onChange={(newVal) => handleTimeIntervalChange('maximalVacationDuration', newVal)}
					/>
					<hr className="mt-0"/>
					<VacationDaysDistributionPanel
						distributionType={formState.vacationDaysDistribution}
						periodValues={formState.vacationDaysDistributionPerPeriods}
						minimalVacationDaysPerYear={formState.minimalVacationDaysPerYear}
						distributionOptions={vacationsDistributionOptions}
						onPeriodValuesChange={handlePeriodDistributionValuesChange}
						onDistributionTypeChange={handleDistributionTypeChange}
					/>
					<hr className="mt-0"/>
					<PositionsSelect
						positions={formState.positionsThatAffectProjectDeadlines}
						setPositions={handleMultiselectFormGroupChange('positionsThatAffectProjectDeadlines')}
						label={t('positions_that_affect_project_deadlines')}
					/>
					<PositionsSelect
						positions={formState.positionsThatCanNotBeFrozen}
						setPositions={handleMultiselectFormGroupChange('positionsThatCanNotBeFrozen')}
						label={t('positions_that_can_not_be_frozen')}
					/>
					<PositionsSelect
						positions={formState.positionsThatCanTakeRefunds}
						setPositions={handleMultiselectFormGroupChange('positionsThatCanTakeRefunds')}
						label={t('positions_that_can_take_refunds')}
					/>
				</Form>
			</Col>
			<Col md={2} />
			<Col md={6}>
				<ConflictingPositionsPanel
					conflictingPositions={formState.conflictingPositions}
					positionsOptions={positionsOptions}
					onAdd={addConflictingPosition}
					onUpdate={updateConflictingPosition}
					onRemove={removeConflictingPosition}
				/>
			</Col>
		</Row>
	);
}

export default Vacations;
