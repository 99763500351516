import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SettingsButton from '../Buttons/SettingsButton'

interface SidebarItemProps {
    name: string;
    icon?: React.ComponentType<{ size: number; className: string }>;
    to: string;
    parentPath?: string;
    external_link?: boolean;
    isActive: boolean;
    onClick?: () => void;
    withSettings?: boolean;
}

export const SidebarItem: React.FC<SidebarItemProps> = React.memo(({
                                                                       name,
                                                                       icon: Icon,
                                                                       to,
                                                                       parentPath,
                                                                       external_link,
                                                                       isActive,
                                                                       onClick,
                                                                       withSettings
                                                                   }) => {
    const { t } = useTranslation();

    const handleClick = (e: React.MouseEvent) => {
        if (external_link) {
            e.preventDefault();
        }
        onClick?.();
    };

    if (external_link) {
        return (
            <li className="sidebar-item">
                <a href={to} className="sidebar-link" target="_blank" rel="noopener noreferrer">
                    {Icon && <Icon size={18} className="align-middle mr-3" />}
                    {t(name)}
                </a>
            </li>
        );
    }

    const styles = `sidebar-link ${(withSettings && isActive) ? 'd-flex justify-content-between' : ''} ${isActive ? 'active' : ''}`

    return (
        <li className={`sidebar-item ${isActive ? 'active' : ''}`}>
            <NavLink
                to={parentPath ? `${parentPath}${to === '/' ? '' : `/${to}`}` : to}
                className={styles}
                onClick={handleClick}
            >
                <div>
                    {Icon && <Icon size={18} className="align-middle mr-3" />}
                    <span>{t(name)}</span>
                </div>
                {
                    (withSettings && isActive) && (
                        <SettingsButton url={`${to}/settings`}/>
                    )
                }
            </NavLink>
        </li>
    );
});

