import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { routes } from '../../routes/routes';
import { RolesEnum } from '../../types/roles';
import logo from '../../assets/img/asya_logo_blue.png';
import selectors from '../../redux/selectors';
import { SidebarItem } from './SidebarItem';
import { SidebarCategory } from './SidebarCategory';
import { UserProfile } from './UserProfile';
import { useSidebar } from '../Contexts/SidebarContext';
import { useIsMobile } from '../../hooks/useIsMobile';

const normalizePath = (path: string) => `/${path}`.replace(/\/+/g, '/').replace(/\/$/, '') || '/';

const Sidebar: React.FC = () => {
	const { pathname } = useLocation();
	const employee = useSelector(selectors.getUserEmployee);
	const { isSidebarOpen, setSidebar } = useSidebar();
	const isMobile = useIsMobile();

	const [openRouteIndex, setOpenRouteIndex] = useState<number | null>(null);

	const toggleRoute = useCallback((index: number) => {
		setOpenRouteIndex(prevIndex => prevIndex === index ? null : index);
	}, []);

	const normalizedPath = useMemo(() => normalizePath(pathname), [pathname]);
	const isPathMatch = useCallback((path: string, parentPath?: string) => {
		const targetPath = parentPath ? normalizePath(`${parentPath}/${path}`) : normalizePath(path);
		return normalizedPath === targetPath || normalizedPath.startsWith(targetPath + '/');
	}, [normalizedPath]);

	const userRoles: Set<RolesEnum> = useMemo(() => new Set(employee.user_roles), [employee.user_roles]);

	const closeSidebarOnMobile = useCallback(() => {
		if (isMobile) setSidebar(false);
	}, [isMobile, setSidebar]);

	useEffect(() => {
		closeSidebarOnMobile();
	}, [pathname, closeSidebarOnMobile]);

	useEffect(() => {
		let touchStartX = 0;
		const handleTouchStart = (e: TouchEvent) => { touchStartX = e.touches[0].clientX; };
		const handleTouchEnd = (e: TouchEvent) => {
			const deltaX = e.changedTouches[0].clientX - touchStartX;
			if (Math.abs(deltaX) > 50) setSidebar(deltaX > 0);
		};

		document.addEventListener('touchstart', handleTouchStart);
		document.addEventListener('touchend', handleTouchEnd);
		return () => {
			document.removeEventListener('touchstart', handleTouchStart);
			document.removeEventListener('touchend', handleTouchEnd);
		};
	}, [setSidebar]);

	const renderRoutes = useMemo(() => {
		const hasRequiredRole = (requiredRoles?: RolesEnum[]) => {
			if (!requiredRoles?.length) return true;
			if (userRoles.has(RolesEnum.founder)) return true;
			if (userRoles.has(RolesEnum.admin) && !requiredRoles.includes(RolesEnum.founder)) return true;
			if (requiredRoles.includes(RolesEnum.founder) && !userRoles.has(RolesEnum.founder)) return false;
			return requiredRoles.some(role => userRoles.has(role));
		};

		const renderSidebarItem = (route: typeof routes[0], index: number, parentPath?: string) => (
			<SidebarItem
				key={index}
				name={route.name}
				to={route.path}
				icon={route.icon}
				external_link={route.external_link}
				parentPath={parentPath}
				isActive={isPathMatch(route.path, parentPath)}
				onClick={closeSidebarOnMobile}
				withSettings={route.withSettings}
			/>
		);

		return routes.reduce((acc, category, index) => {
			if (category.displayInSidebar === false || !hasRequiredRole(category.requiredRoles)) {
				return acc;
			}

			if (category.children) {
				const visibleChildren = category.children.filter(route =>
					route.displayInSidebar !== false && hasRequiredRole(route.requiredRoles)
				);

				if (visibleChildren.length === 0) return acc;

				acc.push(
					<SidebarCategory
						key={index}
						name={category.name}
						icon={category.icon!}
						to={category.path}
						isOpen={openRouteIndex === index}
						onClick={() => toggleRoute(index)}
						isActive={isPathMatch(category.path)}
						withSettings={category.withSettings}
					>
						{visibleChildren.map((route, childIndex) => renderSidebarItem(route, childIndex, category.path))}
					</SidebarCategory>
				);
			} else {
				acc.push(renderSidebarItem(category, index));
			}

			return acc;
		}, [] as React.ReactNode[]);
	}, [userRoles, openRouteIndex, toggleRoute, isPathMatch, closeSidebarOnMobile]);

	return (
		<nav className={`sidebar sidebar-sticky ${isSidebarOpen ? '' : 'toggled'}`}>
			<div className="sidebar-content">
				<PerfectScrollbar>
					<a className="sidebar-brand" href="/">
						<img src={logo} alt="Logo" width="100" />
					</a>
					<ul className="sidebar-nav">{renderRoutes}</ul>
				</PerfectScrollbar>
			</div>
		</nav>
	);
};

export default React.memo(Sidebar);

