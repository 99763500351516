import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import UniversalTable from 'components/Tables/UniversalTable'

import Moment from 'react-moment'

import 'moment/locale/ru'
import 'moment/locale/uk'

import { CurrencyRate } from '../../../../../types/finance/currency'
import { Col, Row } from 'react-bootstrap'
import RangeCalendar from '../../../../../components/RangeCalendar'
import { useLoadCurrenciesInRangeQuery } from '../../../../../redux/slices/financeDictionaries'
import moment from 'moment/moment'
import TableError from '../../../../../components/TableError'
import Loader from '../../../../../components/Loaders/Loader'

const columns = [
	{
		dataField: 'date',
		text: 'ExpensesTable_columnsDate',
		sort: true,
		width: '10%',
		className: 'text-center'
	},
	{
		dataField: 'EUR/USD',
		text: 'EUR/USD',
		sort: false,
		width: '20%',
		className: 'text-center'
	},
	{
		dataField: 'USD/UAH',
		text: 'USD/UAH',
		sort: false,
		width: '20%',
		className: 'text-center'
	},
	{
		dataField: 'EUR/UAH',
		text: 'EUR/UAH',
		sort: false,
		width: '20%',
		className: 'text-center'
	}
]

interface CurrencyRowProps {
	currencyRates: any,
}

function CurrencyRow({ currencyRates } : CurrencyRowProps) {
	const { t } = useTranslation()
	if (!currencyRates) return null

	return (
		<tr>
			<td className="text-center">
				<Moment format="DD.MM.YYYY" locale={t('locales')} date={currencyRates.date} />
			</td>
			{
				currencyRates.exchangeRates.map((entry: { name: string; rate: number }, index) =>
					<td className="my-0 py-0 text-center" key={index}>
						{entry.rate ? entry.rate.toFixed(4) : ''}
					</td>
				)
			}
		</tr>
	)
}

interface ExchangeRateEntry {
	date: string;
	exchangeRates: Array<{ name: string; rate: number }>;
}

function transformCurrencyRates(rates: CurrencyRate[]): ExchangeRateEntry[] {
	const result: ExchangeRateEntry[] = [];

	rates.forEach(({ date, name, rate }) => {
		let entry = result.find(e => e.date === date);
		if (!entry) {
			entry = { date, exchangeRates: [] };
			result.push(entry);
		}
		entry.exchangeRates.push({ name, rate });
	});

	return result;
}

function CurrencyRatesTable() {
	const { innerWidth } = window
	const { t } = useTranslation()

	const [selectStartOption, setSelectStartOption] = useState<Date>(moment().startOf('month').toDate())
	const [selectEndOption, setSelectEndOption] = useState<Date>(moment().endOf('month').toDate())

	const { data, isLoading, isError, refetch } = useLoadCurrenciesInRangeQuery({
		startDate: moment(selectStartOption).format('DD.MM.YYYY'),
		endDate: moment(selectEndOption).format('DD.MM.YYYY')
	}, { skip: !selectStartOption || !selectEndOption });

	useEffect(() => {
		if (selectStartOption && selectEndOption) {
			refetch();
		}
	}, [selectStartOption, selectEndOption, refetch]);

	function onDateChange(dates) {
		const [start, end] = dates
		setSelectStartOption(start)
		setSelectEndOption(end)
	}

	const currencyRates = data ? transformCurrencyRates(data) : [];

	const sortedCurrencyRates = currencyRates.sort(
		(a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
	)

	if (isLoading) return <Loader />
	if (isError) return <TableError>{t('request_error')}</TableError>;

	return (
		<>
			<Row xs={1} sm={2} lg={4} xxl={innerWidth > 1700 ? 6 : 5}>
				<Col className="text-center mb-4">
					<RangeCalendar
						startDate={selectStartOption}
						endDate={selectEndOption}
						withSelect={true}
						onChange={onDateChange}
					/>
				</Col>
			</Row>
			{
				sortedCurrencyRates && sortedCurrencyRates.length > 0 ?
					<UniversalTable
						name="CurrencyRatesTable_tableName"
						id="currencyRates"
						columns={columns}
						data={sortedCurrencyRates}
						row={(currencyRate, i) => <CurrencyRow key={i + 'currencyRow'} currencyRates={currencyRate} />}
						scrollable
						withPagination
					/> : <TableError>{t('no_data')}</TableError>
			}
		</>
	)
}

export default CurrencyRatesTable
