import React, { useEffect } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { uk, enUS as en } from 'date-fns/locale'

import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'

const localizationSettings = {
	en: { ...en, options: { ...en.options, weekStartsOn: 1 as const } },
	uk,
}

registerLocale('en', localizationSettings.en)
registerLocale('uk', localizationSettings.uk)

export default function RangeCalendar(props) {
	const { t, i18n } = useTranslation()

	useEffect(() => {
		registerLocale(i18n.language, localizationSettings[i18n.language])
	}, [i18n.language])

	const customClass = props.withFullWidth ? 'full-width' : ''

	return (
		<div className={`w-100 ${customClass}`}>
			{props.label && (
				<div className="text-center">
					<label className="d-block mb-2">{t(props.label)}</label>
				</div>
			)}
			<div className="text-center">
				<DatePicker
					className="range-calendar"
					locale={i18n.language}
					selected={props.startDate}
					onChange={props.onChange}
					startDate={props.startDate}
					endDate={props.endDate}
					excludeDates={[]}
					showTimeSelect={props.showTimeSelect}
					filterDate={props.validDate}
					selectsRange
					inline={!props.withSelect}
					dateFormat="dd.MM.yyyy"
					showMonthYearDropdown={undefined}
				/>
			</div>
		</div>
	)
}
