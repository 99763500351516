import React, { useMemo, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Table, Col, Row } from 'react-bootstrap'
import Loader from '../../../../../components/Loaders/Loader'
import { MultiselectFormGroup } from '../../../../../components/FormGroups'
import { useGetCashFlowQuery } from '../../../../../redux/slices/reports'
import { useLoadCashFlowTypesListQuery, useLoadOrganizationsSelectQuery } from '../../../../../redux/slices/financeDictionaries'
import CashFlowExcelButton from './Buttons/CashFlowReportExcelButton'
import TableError from '../../../../../components/TableError'
import { displayValue } from '../../../../../utils/reportsExcelParser'
import CollapsibleAccountClosingTable from './AccountsSubTable'
import {
	NATIVE_CURRENCY_NAME,
	quarterRanges,
	DefaultReportHeader,
	DefaultTotalsRow,
	ReportQuickSelect,
	ReportSwitches,
	extractMonths,
	CashFlowReportMode
} from './Commons'
import { RecursiveMonthlyRow } from './Commons'

const modeOptions = [
	{ value: CashFlowReportMode.IncomeExpense, label: 'by_direction' },
	{ value: CashFlowReportMode.CashFlowType, label: 'by_cashflow_type' },
]

const BalanceRow = ({ label, data, months, showNative, bgColor }) => (
	<tr className={`fw-bold ${bgColor}`}>
		<td>{label}</td>
		{months.map((m, idx) => (
			<React.Fragment key={idx}>
				{showNative && (
					<td className="text-right text-nowrap">
						{displayValue(data[m.key]?.native, NATIVE_CURRENCY_NAME)}
					</td>
				)}
				<td className="text-right text-nowrap">{displayValue(data[m.key]?.mgm)}</td>
			</React.Fragment>
		))}
		<td className="text-right text-nowrap"></td>
		{showNative && <td className="text-right text-nowrap"></td>}
	</tr>
)

const CashFlowTable = ({ report, months, showNative, useArchived }) => {
	const { t } = useTranslation()
	return (
		<Table bordered hover responsive className="table-striped table-sm mt-3">
			<DefaultReportHeader
				firstHeader={`${t('direction')} / ${t('cashFlowType')}`}
				months={months}
				showNative={showNative}
				totalLabel={t('total')}
			/>
			<tbody>
			{report.rows.map((row) => (
				<RecursiveMonthlyRow
					key={row.id}
					row={row}
					level={0}
					months={months}
					monthIndices={months.map((_, i) => i)}
					showNative={showNative}
					useArchived={useArchived}
				/>
			))}
			<DefaultTotalsRow months={months} reportTotals={{ ...report.monthlyTotals, overall: report.overallTotal }} showNative={showNative} />
			<BalanceRow label={t('starting_cash')} data={report.overallOpening} months={months} showNative={showNative} bgColor="bg-info" />
			<BalanceRow label={t('closing_cash')} data={report.overallClosing} months={months} showNative={showNative} bgColor="bg-success" />
			</tbody>
		</Table>
	)
}

export default function CashFlowReportTable() {
	const { t, i18n } = useTranslation()
	const { data: cfTypes = [] } = useLoadCashFlowTypesListQuery()
	const { data: organizations = [] } = useLoadOrganizationsSelectQuery()
	const [documentDateFrom, setDocumentDateFrom] = useState(moment().startOf('year').toDate())
	const [documentDateTo, setDocumentDateTo] = useState(moment().endOf('year').toDate())
	const [selectedCashFlowTypes, setSelectedCashFlowTypes] = useState([])
	const [selectedOrganizations, setSelectedOrganizations] = useState([])
	const [viewMode, setViewMode] = useState(CashFlowReportMode.IncomeExpense)
	const [showNative, setShowNative] = useState(true)
	const [useArchived, setUseArchived] = useState(false)
	const validFrom = documentDateFrom || moment().startOf('year').toDate()
	const validTo = documentDateTo || moment().endOf('year').toDate()
	const filters = useMemo(
		() => ({
			startDate: validFrom.toISOString(),
			endDate: validTo.toISOString(),
			organizations: selectedOrganizations.length ? selectedOrganizations : undefined,
			cashFlowTypes: selectedCashFlowTypes.length ? selectedCashFlowTypes : undefined,
			language: i18n.language as 'uk' | 'en' | undefined,
			mode: viewMode,
		}),
		[validFrom, validTo, selectedOrganizations, selectedCashFlowTypes, i18n.language, viewMode]
	)
	const { data: reportData, isLoading, isFetching, isError } = useGetCashFlowQuery(filters)
	const report = reportData || {
		rows: [],
		accountMonthly: [],
		monthlyTotals: {},
		overallTotal: {},
		overallOpening: {},
		overallClosing: {},
		months: [],
	}
	const monthsArr = extractMonths(report.months || [])
	return (
		<>
			<Row xs={1} sm={1} md={2} lg={2} xxl={4}>
				<ReportQuickSelect
					ranges={quarterRanges}
					defaultIndex={5}
					documentDateFrom={documentDateFrom}
					documentDateTo={documentDateTo}
					setDocumentDateFrom={setDocumentDateFrom}
					setDocumentDateTo={setDocumentDateTo}
				/>
				<Col md={12} lg={12} xxl={6} className="text-right">
					<CashFlowExcelButton data={reportData} dateFrom={documentDateFrom} months={monthsArr} dateTo={documentDateTo} showNative={showNative} />
				</Col>
			</Row>
			<Row xs={1} sm={1} md={2} lg={2} xxl={4} className="mt-3">
				<Col>
					<MultiselectFormGroup
						placeholder={t('organizations')}
						options={organizations.map((org, idx) => ({ value: org._id, label: org.name, index: idx }))}
						onChange={(selected) => setSelectedOrganizations(selected.map((s) => s.value))}
					/>
				</Col>
				<Col>
					<MultiselectFormGroup
						placeholder={t('cashFlowType')}
						options={cfTypes.map((cf, idx) => ({ value: cf._id, label: cf.ukName || cf.enName, index: idx }))}
						onChange={(selected) => setSelectedCashFlowTypes(selected.map((s) => s.value))}
					/>
				</Col>
			</Row>
			<ReportSwitches
				useArchived={useArchived}
				setUseArchived={setUseArchived}
				mode={viewMode}
				setMode={setViewMode}
				modeOptions={modeOptions}
				showNative={showNative}
				setShowNative={setShowNative}
			/>
			{isLoading || isFetching ? (
				<Loader />
			) : isError ? (
				<TableError>{t('request_error')}</TableError>
			) : report.rows.length > 0 ? (
				<>
					<CashFlowTable report={report} months={monthsArr} showNative={showNative} useArchived={useArchived} />
					<CollapsibleAccountClosingTable data={report.accountsTable} months={monthsArr} overall={report.accountsOverall} showNative={showNative} />
				</>
			) : (
				<TableError>{t('no_data')}</TableError>
			)}
		</>
	)
}
