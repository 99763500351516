import React from 'react'
import WindowedSelect, { ActionMeta, components, Props, StylesConfig } from 'react-windowed-select';
import { Form, InputGroup } from 'react-bootstrap'
import { ColorSchemeEnum, colourStyles } from '../utils/rainbowColors'

interface SelectProps extends Omit<Props, 'onChange'> {
	prefix?: React.ReactNode;
	error?: boolean;
	onChange: (newValue: any, actionMeta: ActionMeta<any>) => void;
	styles?: StylesConfig<unknown, boolean, any>;
	schema?: ColorSchemeEnum;
}

const Control: React.FC<any> = ({ children, ...props }) => {
	const { prefix } = props.selectProps;
	return (
		<components.Control
			{...props}
			innerProps={{
				...props.innerProps,
				style: { ...props.innerProps?.style, alignItems: 'stretch' }
			}}
		>
			{prefix && (
				<div
					style={{
						backgroundColor: '#e9ecef',
						width: '36px',
						borderRadius: '3px 0 0 3px',
						padding: '7px 10px 7px 10px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					{prefix}
				</div>
			)}
			{children}
		</components.Control>
	);
};

export const Multiselect: React.FC<SelectProps> = (props) => (
	<WindowedSelect
		windowThreshold={100}
		isMulti
		components={{ Control }}
		styles={props.styles ?? colourStyles(props.error, props.schema)}
		{...props}
	/>
)

export const Singleselect: React.FC<SelectProps> = (props) => (
	<WindowedSelect
		windowThreshold={100}
		components={{ Control }}
		isClearable
		styles={props.styles ?? colourStyles(props.error, props.schema)}
		{...props}
	/>
)

interface FormGroupProps {
	label?: string;
	error?: boolean;
	className?: string;
	labelClassName?: string;
	children?: React.ReactNode;
}

const FormGroup: React.FC<FormGroupProps> = ({ label, error, className, labelClassName, children }) => (
	<Form.Group className={className}>
		{label && <label className={`${ error ? 'text-danger' : '' } ${labelClassName || ''}`}>{label}</label>}
		{children}
	</Form.Group>
)

export const MultiselectFormGroup: React.FC<SelectProps & FormGroupProps> = (props) => (
	<FormGroup label={props.label} error={props.error} className={`${props.className} pb-3`}>
		<Multiselect{...props} />
	</FormGroup>
)

export const SingleselectFormGroup: React.FC<SelectProps & FormGroupProps> = (props) => (
	<FormGroup label={props.label} error={props.error} className={`${props.className} pb-3`}>
		<Singleselect {...props} />
	</FormGroup>
)

interface InputFormGroupProps extends FormGroupProps {
	name: string;
	type?: string;
	helpText?: string;
	prefix?: React.ReactNode;
	postfix?: React.ReactNode;
	value?: string | number;
	default?: string;
	max?: number | string;
	placeholder?: string;
	disabled?: boolean;
	errorText?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputFormGroup: React.FC<InputFormGroupProps> = ({
    name,
    label,
    type,
    helpText,
    prefix,
    postfix,
    value,
    default: defaultValue,
	max,
    placeholder,
    error,
    disabled,
    errorText,
    onChange,
    className
}) => (
	<FormGroup label={label} error={error} className={`${className} pb-3`}>
		<InputGroup>
			{prefix && <InputGroup.Text className='custom-input-group-prefix'>{prefix}</InputGroup.Text>}
			<Form.Control
				type={type}
				name={name}
				value={value}
				max={max}
				defaultValue={defaultValue}
				placeholder={placeholder}
				onChange={onChange}
				style={error ? { borderColor: 'var(--danger)' } : {}}
				disabled={disabled}
			/>
			{postfix && <InputGroup.Text>{postfix}</InputGroup.Text>}
		</InputGroup>
		<Form.Text className={error && errorText ? 'text-danger' : 'text-muted'}>
			{error && errorText ? errorText : helpText}
		</Form.Text>
	</FormGroup>
)