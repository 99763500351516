import { useTranslation } from 'react-i18next'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaFileExcel } from 'react-icons/fa'
import React from 'react'
import { downloadCashFlowExcel } from '../../../../../../utils/reportsExcelParser'
import { Month } from '../../../../../../types/finance/reports'

interface CashFlowExcelButtonProps {
	data?: any;
	months: Month[];
	dateFrom: Date;
	dateTo: Date;
	showNative: boolean;
}

export default function CashFlowExcelButton({ data, dateFrom, dateTo, months, showNative }: CashFlowExcelButtonProps) {
	const { t } = useTranslation()

	if (!data || data.length <= 0) return null

	const load = async () => {
		return await downloadCashFlowExcel(data, dateFrom, dateTo, months, showNative)
	};

	const renderTooltip = (props) => (
		<Tooltip id="tooltip-left" className={'custom-tooltip left'} {...props}>
			{t('DownloadCashFlowReport_text')}
		</Tooltip>
	)

	return (
		<OverlayTrigger placement="left" overlay={renderTooltip}>
			<Button
				id='CashFlowReportDowloadButton'
				variant="outline-success"
				onClick={load}
				className='rounded-lg shadow-sm ml-2 mb-0'
			>
				<FaFileExcel />
			</Button>
		</OverlayTrigger>
	)
}