import { Container, Nav, Tab } from 'react-bootstrap'
import Header from '../../../../components/Settings/Header'
import { NavLink } from 'react-router-dom'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Promotions from './Promotions'
import { SettingsProvider } from '../../../../components/Contexts/SettingsContext'

function EmployeeSettingsContainer({ children }) {
	const { t } = useTranslation()

	return (
		<Container fluid className="p-0">
			<SettingsProvider>
			<Header />
			<div className={'tab'}>
				<Tab.Container id="left-tabs-example">
					<Nav variant="tabs">
						<Nav.Item>
							<Nav.Link
								as={NavLink}
								to="/employees/settings/promotions"
							>
								{t('EmployeeSettings_promotions')}
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<Tab.Content>
						{children}
					</Tab.Content>
				</Tab.Container>
			</div>
			</SettingsProvider>
		</Container>
	)
}

export function EmployeeSettingsTab() {
	return (
		<EmployeeSettingsContainer>
			<Promotions/>
		</EmployeeSettingsContainer>
	)
}

