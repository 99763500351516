import React from 'react'
import { CiSettings } from "react-icons/ci";
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import RoleWrapper from '../RoleWrapper'
import { RolesEnum } from 'types/roles'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function AdminPanel() {
	const { t } = useTranslation()

	const renderTooltip = (props) => (
		<Tooltip id="tooltip-bottom" className={'custom-tooltip bottom'} {...props}>{t('AdminPanel_textTooltip')}</Tooltip>
	);

	return (
		<RoleWrapper requared_role={[RolesEnum.admin]}>
			<div className="nav-item mt-1 mr-3">
				<OverlayTrigger
					placement="bottom"
					overlay={renderTooltip}
				>
					<Link to="/system_settings">
						<CiSettings size={32} id="TooltipAdminPanelButton" />
					</Link>
				</OverlayTrigger>
			</div>
		</RoleWrapper>
	)
}
