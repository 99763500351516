import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/axios.queryBuilder';
import {
	CreateInvoiceDto,
	UpdateInvoiceDto,
	FilterInvoiceDto,
	Invoice,
	InvoiceSelect,
	InvoiceClass,
	CreateInvoiceClassDto,
	UpdateInvoiceClassDto,
	PayInvoiceDto
} from '../../types/finance/invoice'

export const invoicesApi = createApi({
	reducerPath: 'invoicesApi',
	baseQuery: axiosBaseQuery({
		baseUrl: `finance`,
	}),
	tagTypes: ['Invoices', 'InvoiceFile', 'InvoiceClasses'],
	endpoints: (builder) => ({
		createInvoice: builder.mutation<Invoice, CreateInvoiceDto>({
			query: (body) => ({
				url: `/invoice`,
				method: 'POST',
				data: body,
			}),
			invalidatesTags: ['Invoices'],
		}),

		updateInvoice: builder.mutation<
			Invoice,
			{ id: string; changes: UpdateInvoiceDto }
		>({
			query: ({ id, changes }) => ({
				url: `/invoice/${id}`,
				method: 'PATCH',
				data: changes,
			}),
			invalidatesTags: ['Invoices'],
		}),

		deleteInvoice: builder.mutation<void, string>({
			query: (id) => ({
				url: `/invoice/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Invoices'],
		}),

		deleteInvoiceFile: builder.mutation<void, string>({
			query: (id) => ({
				url: `/invoice/${id}/file`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Invoices'],
		}),

		addInvoiceFile: builder.mutation<
			{ fileId: string; fileName: string },
			{ id: string; file: File }
		>({
			query: ({ id, file }) => {
				const formData = new FormData();
				formData.append('file', file);

				return {
					url: `/invoice/${id}/file`,
					method: 'POST',
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				};
			},
			invalidatesTags: ['Invoices', 'InvoiceFile'],
		}),

		downloadInvoiceFile: builder.mutation<any, string>({
			query: (id) => ({
				url: `/invoice/${id}/file`,
				method: 'GET',
			})
		}),

		getInvoices: builder.query<
			{ data: Invoice[]; total: number },
			FilterInvoiceDto
		>({
			query: (params) => ({
				url: `/invoices`,
				method: 'GET',
				params,
			}),
			providesTags: ['Invoices'],
		}),

		getInvoicesForSelect: builder.query<InvoiceSelect[], void>({
			query: () => ({
				url: `/invoicesSelect`,
				method: 'GET',
			}),
			providesTags: ['Invoices'],
		}),

		payInvoice: builder.mutation<void, PayInvoiceDto>({
			query: ({ id, accountId, paymentDate, bankCommission, actualSum }) => ({
				url: `/invoice/${id}/pay`,
				method: 'PATCH',
				data: { accountId, paymentDate, bankCommission, actualSum },
			}),
			invalidatesTags: ['Invoices'],
		}),

		executeInvoice: builder.mutation<void, string>({
			query: (id) => ({
				url: `/invoice/${id}/execute`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Invoices'],
		}),

		unexecuteInvoice: builder.mutation<void, string>({
			query: (id) => ({
				url: `/invoice/${id}/unexecute`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Invoices'],
		}),

		setNoFile: builder.mutation<void, string>({
			query: (id) => ({
				url: `/invoice/${id}/no-file`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Invoices'],
		}),

		sendToContractor: builder.mutation<
			void,
			{ id: string; issueDate: Date }
		>({
			query: ({ id, issueDate }) => ({
				url: `/invoice/${id}/send`,
				method: 'PATCH',
				data: { issueDate },
			}),
			invalidatesTags: ['Invoices'],
		}),

		getInvoiceNumbers: builder.query<string[], void>({
			query: () => ({
				url: `/invoices-numbers`,
				method: 'GET',
			}),
			providesTags: ['Invoices'],
		}),

		getInvoiceClasses: builder.query<InvoiceClass[], void>({
			query: () => ({ url: '/classes/list', method: 'get' }),
			providesTags: ['InvoiceClasses'],
		}),
		getUnarchivedInvoiceClasses: builder.query<InvoiceClass[], void>({
			query: () => ({ url: '/classes/listUnarchived', method: 'get' }),
			providesTags: ['InvoiceClasses'],
		}),
		createInvoiceClass: builder.mutation<InvoiceClass, CreateInvoiceClassDto>({
			query: (newClass) => ({ url: '/classes', method: 'post', data: newClass }),
			invalidatesTags: ['InvoiceClasses'],
		}),
		updateInvoiceClass: builder.mutation<InvoiceClass, { id: string, changes: Partial<UpdateInvoiceClassDto> }>({
			query: ({ id, changes }) => ({ url: `/classes/${id}`, method: 'patch', data: changes }),
			invalidatesTags: ['InvoiceClasses'],
		}),
		archiveInvoiceClass: builder.mutation<InvoiceClass, string>({
			query: (id) => ({ url: `/classes/${id}/archive`, method: 'patch' }),
			invalidatesTags: ['InvoiceClasses'],
		}),
		unarchiveInvoiceClass: builder.mutation<InvoiceClass, string>({
			query: (id) => ({ url: `/classes/${id}/unarchive`, method: 'patch' }),
			invalidatesTags: ['InvoiceClasses'],
		}),
	}),
});

export const {
	useCreateInvoiceMutation,
	useUpdateInvoiceMutation,
	useDeleteInvoiceMutation,
	useDeleteInvoiceFileMutation,
	useGetInvoicesForSelectQuery,

	useAddInvoiceFileMutation,
	useDownloadInvoiceFileMutation,

	useGetInvoicesQuery,
	usePayInvoiceMutation,
	useExecuteInvoiceMutation,
	useUnexecuteInvoiceMutation,
	useSetNoFileMutation,
	useSendToContractorMutation,
	useGetInvoiceNumbersQuery,

	useGetInvoiceClassesQuery,
	useGetUnarchivedInvoiceClassesQuery,
	useCreateInvoiceClassMutation,
	useUpdateInvoiceClassMutation,
	useArchiveInvoiceClassMutation,
	useUnarchiveInvoiceClassMutation
} = invoicesApi;
