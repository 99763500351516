import React from 'react'
import { useTranslation } from 'react-i18next';
import UniversalTable from 'components/Tables/UniversalTable';

import { Account } from '../../../../../types/finance/account';
import {
	useArchiveAccountMutation,
	useDeleteAccountMutation, useLoadAccountsQuery, useSetDefaultAccountMutation,
	useUnarchiveAccountMutation
} from '../../../../../redux/slices/financeDictionaries'
import { FaArchive, FaTrashAlt } from 'react-icons/fa'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import UpdateAccountModal from '../Modals/UpdateAccountModal'
import { RolesEnum } from '../../../../../types/roles'
import TableError from '../../../../../components/TableError'
import DangerModal from '../../../../../components/Modals/DangerModal'
import SuccessModal from '../../../../../components/Modals/SuccessModal'
import EditCheckbox from '../../../../../components/Buttons/EditCheckbox'
import Loader from '../../../../../components/Loaders/Loader'

const columns = [
	{
		dataField: 'organization',
		text: 'AccountsTable_Organization',
		sort: false,
		width: '15%',
		className: 'text-center',
	},
	{
		dataField: 'type',
		text: 'AccountsTable_Type',
		sort: false,
		width: '5%',
		className: 'text-center',
	},
	{
		dataField: 'currency',
		text: 'AccountsTable_Currency',
		sort: false,
		width: '5%',
		className: 'text-center',
	},
	{
		dataField: 'name',
		text: 'AccountsTable_Name',
		sort: false,
		width: '15%',
		className: 'text-center',
	},
	{
		dataField: 'beneficiaryName',
		text: 'AccountsTable_BeneficiaryName',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'IBAN',
		text: 'AccountsTable_IBAN',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'bankName',
		text: 'AccountsTable_BankName',
		sort: false,
		width: '15%',
		className: 'text-center',
	},
	{
		dataField: 'swiftCode',
		text: 'AccountsTable_SwiftCode',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'actions',
		text: 'AccountsTable_Actions',
		sort: false,
		width: '10%',
		className: 'text-center',
	}
];

interface AccountRowProps {
	account: Account;
	handlers: any
}

function AccountRow({ account, handlers }: AccountRowProps) {
	const { t } = useTranslation();

	const [archiveAccount, unarchiveAccount, deleteAccount, setDefaultAccount] = handlers

	return (
		<tr>
			<td className="text-center">{account.organization ? account.organization.name : ''}</td>
			<td className="text-center">{t(account.type.name)}</td>
			<td className="text-center">{account.currency ? account.currency.name : ''}</td>
			<td className="text-center">{account.name}</td>
			<td className="text-center">{account.beneficiaryName || '-'}</td>
			<td className="text-center">{account.IBAN || '-'}</td>
			<td className="text-center">{account.bankName || '-'}</td>
			<td className="text-center">{account.swiftCode || '-'}</td>
			<td className="text-center">
				<div className='d-flex align-middle justify-content-center'>
					{
						!account.isArchived && (
							<>
								<UpdateAccountModal account={account} />
								<EditCheckbox
									checked={account.isDefault}
									title={t('set_default')}
									onCheck={() => setDefaultAccount(account._id)}
								/>
							</>
						)
					}
					{
						account.isArchived ? (
							<SuccessModal
								id={account._id}
								title={'unarchive'}
								icon={FaArrowRotateLeft}
								onAccept={() => unarchiveAccount(account._id)}
								tooltipText={'unarchive'}
								requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
							/>
						) : (
							<DangerModal
								id={account._id}
								title={'archive'}
								icon={FaArchive}
								onAccept={() => archiveAccount(account._id)}
								tooltipText={'archive'}
								requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
							/>
						)
					}
					{
						account.isArchived && (
							<DangerModal
								id={account._id}
								title="DeleteAccountModal_title"
								icon={FaTrashAlt}
								onAccept={() => deleteAccount(account._id)}
								tooltipText={'delete'}
								requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
							/>
						)
					}
				</div>
			</td>
		</tr>
	);
}

function AccountsTable() {
	const { t } = useTranslation()

	const [archiveAccount] = useArchiveAccountMutation()
	const [unarchiveAccount] = useUnarchiveAccountMutation()
	const [deleteAccount] = useDeleteAccountMutation()
	const [setDefaultAccount] = useSetDefaultAccountMutation()

	const { data, isLoading, isError } = useLoadAccountsQuery();

	if (isLoading) return <Loader />;
	if (isError) return <TableError>{t('request_error')}</TableError>;

	const unarchivedData = (data && data.filter(entry => !entry.isArchived)) || []
	const archivedData = (data && data.filter(entry => entry.isArchived)) || []

	const handlers = [archiveAccount, unarchiveAccount, deleteAccount, setDefaultAccount]

	return <>
		{
			unarchivedData && unarchivedData.length > 0 ?
				<UniversalTable
					name={'AccountsTable_tableName'}
					id={'accountsTable AccountsTable_tableName'}
					columns={columns}
					data={unarchivedData}
					row={(account, i) => <AccountRow handlers={handlers} key={account._id + 'accountRow'} account={account} />}
					scrollable
				/> : <TableError>{t('no_data')}</TableError>
		}
		{
			archivedData && archivedData.length > 0 ?
				<UniversalTable
					name={'AccountsTable_tableNameArchived'}
					id={'accountsTable AccountsTable_tableNameArchived'}
					columns={columns}
					data={archivedData}
					row={(account, i) => <AccountRow handlers={handlers} key={account._id + 'accountArchivedRow'} account={account} />}
					scrollable
				/> : null
		}
	</>
}

export default AccountsTable;
