import { SortDirectionEnum } from 'types/enums'
import { roundTo } from './number'
import { PositionsEnum } from '../types/positions'
import { LevelEnum } from '../types/employeesLevels'

export const sortByReleaseDate = (data, sortDirection) => {
	const sortedArray = data?.sort((a, b) => {
		if (sortDirection === SortDirectionEnum.asc) {
			if (!a.dates.release_done && !b.dates.release_done) {
				return 0
			}
			if (!a.dates.release_done) {
				return 1
			}
			if (!b.dates.release_done) {
				return -1
			}

			const aDate = new Date(a.dates.release_done).getTime()
			const bDate = new Date(b.dates.release_done).getTime()

			return aDate - bDate
		} else if (sortDirection === SortDirectionEnum.desc) {
			if (!a.dates.release_done && !b.dates.release_done) {
				return 0
			}
			if (!a.dates.release_done) {
				return -1
			}
			if (!b.dates.release_done) {
				return 1
			}

			const aDate = new Date(a.dates.release_done).getTime()
			const bDate = new Date(b.dates.release_done).getTime()

			return bDate - aDate
		}
		return null
	})
	if (sortedArray) {
		return sortedArray
	}
	return
}

export const sortByOperationType = (data, sortDirection) => {
	if (!data || !Array.isArray(data)) {
		return [];
	}

	// Group data by type
	const groupedByType = data.reduce((acc, entry) => {
		const { type } = entry;
		if (!acc[type]) {
			acc[type] = [];
		}
		acc[type].push(entry);
		return acc;
	}, {});

	// Sort entries within each type by operationDate
	Object.keys(groupedByType).forEach(type => {
		groupedByType[type].sort((a, b) => {
			const aDate = new Date(a.operationDate).getTime();
			const bDate = new Date(b.operationDate).getTime();
			return sortDirection === SortDirectionEnum.asc ? aDate - bDate : bDate - aDate;
		});
	});

	// Sort types and flatten the grouped results
	const sortedTypes = Object.keys(groupedByType).sort((a, b) => {
		return sortDirection === SortDirectionEnum.asc ? a.localeCompare(b) : b.localeCompare(a);
	});

	// Flatten sorted groups into a single array
	return sortedTypes.flatMap(type => groupedByType[type]);
};

const safeSort = (data, compareFn) => {
	const copiedArray = [...data];
	return copiedArray.sort(compareFn);
};

// General Date Sorting Function
export const sortByDate = (data, sortDirection, dateField) => {
	if (!data || !Array.isArray(data) || !dateField) {
		return [];
	}

	return safeSort(data, (a, b) => {
		const aDate = new Date(a[dateField]).getTime();
		const bDate = new Date(b[dateField]).getTime();

		if (sortDirection === SortDirectionEnum.asc) {
			return aDate - bDate;
		} else if (sortDirection === SortDirectionEnum.desc) {
			return bDate - aDate;
		}
		return 0;
	});
};

export const sortBySum = (data, sortDirection) => {
	if (!data || !Array.isArray(data)) {
		return [];
	}

	return safeSort(data, (a, b) => {
		if (sortDirection === SortDirectionEnum.asc) {
			return a.sum - b.sum;
		} else if (sortDirection === SortDirectionEnum.desc) {
			return b.sum - a.sum;
		}
		return 0;
	});
};

export const sortBySalary = (data, sortDirection) => {
	if (!data || !Array.isArray(data)) {
		return [];
	}

	return safeSort(data, (a, b) => {
		if (sortDirection === SortDirectionEnum.asc) {
			return a.salary - b.salary;
		} else if (sortDirection === SortDirectionEnum.desc) {
			return b.salary - a.salary;
		}
		return 0;
	});
};

export const sortByPosition = (data, sortDirection) => {
	if (!data || !Array.isArray(data)) {
		return [];
	}

	const positions = Object.values(PositionsEnum)

	return safeSort(data, (a, b) => {
		if (sortDirection === SortDirectionEnum.asc) {
			return positions.indexOf(a.position) - positions.indexOf(b.position);
		} else if (sortDirection === SortDirectionEnum.desc) {
			return positions.indexOf(b.position) - positions.indexOf(a.position)
		}
		return 0;
	});
};

export const sortByLevel = (data, sortDirection) => {
	if (!data || !Array.isArray(data)) {
		return [];
	}

	const positions = Object.values(LevelEnum)

	return safeSort(data, (a, b) => {
		if (sortDirection === SortDirectionEnum.asc) {
			return positions.indexOf(a.level) - positions.indexOf(b.level);
		} else if (sortDirection === SortDirectionEnum.desc) {
			return positions.indexOf(b.level) - positions.indexOf(a.level)
		}
		return 0;
	});
};

export const sortByEmployeeName = (data, sortDirection) => {
	if (!data || !Array.isArray(data)) {
		return [];
	}

	return safeSort(data, (a, b) => {
		const aName = a.first_name + a.last_name
		const bName = b.first_name + b.last_name

		if (sortDirection === SortDirectionEnum.asc) {
			return aName.localeCompare(bName);
		} else if (sortDirection === SortDirectionEnum.desc) {
			return bName.localeCompare(aName);
		}
		return 0;
	});
};

export const sortByCpi = (data, sortDirection) => {
	const sortedArray = data?.sort((a, b) => {
		const aCpi = a.results.cpi
		const bCpi = b.results.cpi

		if (sortDirection === SortDirectionEnum.asc) {
			return aCpi - bCpi
		} else if (sortDirection === SortDirectionEnum.desc) {
			return bCpi - aCpi
		}
		return null
	})
	if (sortedArray) {
		return sortedArray
	}
	return
}

export const sortByRetention = (data, sortDirection) => {
	const sortedArray = data?.sort((a, b) => {
		const aRet = a.results.retention
		const bRet = b.results.retention
		if (sortDirection === SortDirectionEnum.asc) {
			return aRet - bRet
		} else if (sortDirection === SortDirectionEnum.desc) {
			return bRet - aRet
		}
		return null
	})
	if (sortedArray) {
		return sortedArray
	}
	return
}

export const sortByMarginality = (data, sortDirection) => {
	const sortedArray = data?.sort((a: { profit: number; full_cost: number }, b: { profit: number; full_cost: number }) => {
		const aMarj = roundTo(((100 * (a.profit - a.full_cost)) / a.full_cost), 0)
		const bMarj = roundTo(((100 * (b.profit - b.full_cost)) / b.full_cost), 0)

		if (sortDirection === SortDirectionEnum.asc) {
			return aMarj - bMarj
		} else if (sortDirection === SortDirectionEnum.desc) {
			return bMarj - aMarj
		}
		return null
	})
	if (sortedArray) {
		return sortedArray
	}
	return
}

export const sortByPromotion = (data, sortDirection) => {
	const sortedArray = data?.sort((a, b) => {
		if (sortDirection === SortDirectionEnum.asc) {
			if (!a.lastPromotion && !b.lastPromotion) {
				return 0
			}
			if (!a.lastPromotion) {
				return -1
			}
			if (!b.lastPromotion) {
				return 1
			}

			const aDate = new Date(a.lastPromotion.date).getTime()
			const bDate = new Date(b.lastPromotion.date).getTime()

			return aDate - bDate
		} else if (sortDirection === SortDirectionEnum.desc) {
			if (!a.lastPromotion && !b.lastPromotion) {
				return 0
			}
			if (!a.lastPromotion) {
				return 1
			}
			if (!b.lastPromotion) {
				return -1
			}

			const aDate = new Date(a.lastPromotion.date).getTime()
			const bDate = new Date(b.lastPromotion.date).getTime()

			return bDate - aDate
		}
		return null
	})

	if (sortedArray) {
		return sortedArray
	}

	return
}
