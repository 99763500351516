import { Button } from 'react-bootstrap'
import { FaCog } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

export default function SettingsButton({ url }) {
	return (
		<NavLink to={url} className="text-decoration-none">
			<Button variant="ghost" className="mx-2 p-0 text-secondary">
				<FaCog className="p-0 m-0" size={16} />
			</Button>
		</NavLink>
	);
}