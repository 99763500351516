import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../api/axios.queryBuilder'
import {
	CreateFrozenPeriodDto,
	CreateVacationRequestDto,
	FilterVacationPeriodsDto,
	FilterVacationRequestDto,
	FilterVacationsDto,
	VacationFrozenPeriod,
	RejectVacationRequestDto,
	UpdateVacationDto,
	UpdateVacationRequestDto,
	Vacation,
	VacationPeriod,
	VacationPeriodGroups,
	VacationRequestAvailability,
	VacationsAllowedDates,
	VacationRequestsData, VacationsConflict
} from "../../types/vacation";
import Employee from "../../types/employee";

export const vacationsApi = createApi({
	reducerPath: 'vacationsApi',
	baseQuery: axiosBaseQuery({ baseUrl: 'vacations' }),
	tagTypes: ['Vacation', 'FrozenPeriod'],
	endpoints: (builder) => ({
		// Frozen endpoints
		getAllFrozenPeriods: builder.query<VacationFrozenPeriod[], void>({
			query: () => ({ url: '/frozen', method: 'GET' }),
			providesTags: ['FrozenPeriod']
		}),
		createFrozenPeriod: builder.mutation<VacationFrozenPeriod, CreateFrozenPeriodDto>({
			query: (dto) => ({
				url: '/frozen',
				method: 'POST',
				data: dto
			}),
			invalidatesTags: ['FrozenPeriod']
		}),
		deleteFrozenPeriod: builder.mutation<{ success: boolean }, string>({
			query: (id) => ({
				url: `/frozen/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['FrozenPeriod']
		}),

		// Vacation periods endpoints
		getCurrentEmployeePeriods: builder.query<VacationPeriodGroups, string>({
			query: (employeeId) => ({ url: `/periods/current/${employeeId}`, method: 'GET' }),
			providesTags: ['Vacation']
		}),
		getPaginatedPeriodsGroupedByEmployee: builder.query<
			{ data: { employee: Employee; periods: VacationPeriod[] }[]; total: number },
			FilterVacationPeriodsDto
		>({
			query: (data) => (
				{
					url: '/periods/all',
					method: 'GET',
					params: data
				}
			),
			providesTags: ['Vacation']
		}),
		updatePeriodAllocatedDays: builder.mutation<any, { periodId: string; newAllocatedDays: number }>({
			query: ({ periodId, newAllocatedDays }) => ({
				url: `/periods/${periodId}`,
				method: 'PUT',
				data: { newAllocatedDays }
			}),
			invalidatesTags: ['Vacation']
		}),

		// Availability endpoints
		isVacationRequestAvailable: builder.query<VacationRequestAvailability, string>({
			query: (employeeId) => (
				{
					url: `/availability/request/${employeeId}`,
					method: 'GET'
				}),
			providesTags: ['Vacation', 'FrozenPeriod']
		}),
		getAllowedVacationDates: builder.query<VacationsAllowedDates, string>({
			query: (employeeId) => (
				{
					url: `/availability/${employeeId}`,
					method: 'GET'
				}),
			providesTags: ['Vacation', 'FrozenPeriod']
		}),

		// Vacation request endpoints
		getAllVacationRequests: builder.query<VacationRequestsData, FilterVacationRequestDto>({
			query: (data) => ({
				url: `/request`,
				method: 'GET',
				params: data
			}),
			providesTags: ['Vacation']
		}),
		createVacationRequest: builder.mutation<any, CreateVacationRequestDto>({
			query: (dto) => ({
				url: '/request',
				method: 'POST',
				data: dto
			}),
			invalidatesTags: ['Vacation']
		}),
		updateVacationRequest: builder.mutation<any, { id: string, changes: UpdateVacationRequestDto }>({
			query: ({id, changes}) => ({
				url: `/request/update/${id}`,
				method: 'POST',
				data: changes
			}),
			invalidatesTags: ['Vacation']
		}),
		autoCreateVacationRequest: builder.mutation<any, CreateVacationRequestDto>({
			query: (dto) => ({
				url: '/request/execute',
				method: 'POST',
				data: dto
			}),
			invalidatesTags: ['Vacation']
		}),
		approveVacationRequest: builder.mutation<any, string>({
			query: (requestId) => ({
				url: `/request/approve/${requestId}`,
				method: 'POST',
			}),
			invalidatesTags: ['Vacation']
		}),
		denyVacationRequest: builder.mutation<any, RejectVacationRequestDto>({
			query: (dto) => ({
				url: `/request/reject`,
				method: 'POST',
				data: dto
			}),
			invalidatesTags: ['Vacation']
		}),
		deleteVacationRequest: builder.mutation<any, string>({
			query: (requestId) => ({
				url: `/request/${requestId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Vacation']
		}),

		// Vacation endpoints
		getAllVacations: builder.query<Vacation[], void>({
			query: () => ({
				url: `/`,
				method: 'GET'
			}),
			providesTags: ['Vacation']
		}),
		getAllVacationsPaginated: builder.query<{ data: Vacation[]; total: number; }, FilterVacationsDto>({
			query: (data) => ({
				url: `/paginated`,
				method: 'GET',
				params: data
			}),
			providesTags: ['Vacation']
		}),
		getAllVacationsConflicts: builder.query<VacationsConflict[] ,void>({
			query: () => ({
				url: `/conflicts`,
				method: 'GET'
			}),
			providesTags: ['Vacation']
		}),
		updateVacation: builder.mutation<any, { id: string, changes: UpdateVacationDto }>({
			query: ({id, changes}) => ({
				url: `/${id}`,
				method: 'PUT',
				data: changes
			}),
			invalidatesTags: ['Vacation']
		}),
		markVacationAsResolved: builder.mutation<any, string>({
			query: (id) => ({
				url: `/markResolved/${id}`,
				method: 'PUT',
			}),
			invalidatesTags: ['Vacation']
		}),
		deleteVacation: builder.mutation<any, string>({
			query: (id) => ({
				url: `/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Vacation']
		})
	})
})

export const {
	useGetAllFrozenPeriodsQuery,
	useCreateFrozenPeriodMutation,
	useDeleteFrozenPeriodMutation,

	useGetCurrentEmployeePeriodsQuery,
	useGetPaginatedPeriodsGroupedByEmployeeQuery,
	useUpdatePeriodAllocatedDaysMutation,

	useGetAllowedVacationDatesQuery,
	useIsVacationRequestAvailableQuery,

	useGetAllVacationRequestsQuery,
	useCreateVacationRequestMutation,
	useUpdateVacationRequestMutation,
	useAutoCreateVacationRequestMutation,
	useApproveVacationRequestMutation,
	useDenyVacationRequestMutation,
	useDeleteVacationRequestMutation,

	useGetAllVacationsQuery,
	useGetAllVacationsPaginatedQuery,
	useGetAllVacationsConflictsQuery,
	useUpdateVacationMutation,
	useMarkVacationAsResolvedMutation,
	useDeleteVacationMutation
} = vacationsApi