import { rainbowColorNames } from 'utils/rainbowColors'
import { PositionsEnum } from './positions'

export enum LevelEnum {
	trainee = 'trainee',

	junior_starter = 'junior_starter',
	junior_base = 'junior_base',
	junior_strong = 'junior_strong',

	middle_starter = 'middle_starter',
	middle_base = 'middle_base',
	middle_strong = 'middle_strong',
	middle_experienced = 'middle_experienced',
	middle_advanced = 'middle_advanced',
	middle_expert = 'middle_expert'
}

export const positionsWithoutPromotion = [
	PositionsEnum.ceo,
	PositionsEnum.cto,
	PositionsEnum.head_of_development,
	PositionsEnum.lead_developer,
	PositionsEnum.technical_art_director,
	PositionsEnum.lead_three_d_artist,
	PositionsEnum.lead_concept_artist,
	PositionsEnum.lead_level_designer,
	PositionsEnum.cpo,
	PositionsEnum.cfo,
	PositionsEnum.first_game_producer,
	PositionsEnum.second_game_producer,
	PositionsEnum.associate_producer,
	PositionsEnum.project_manager,
	PositionsEnum.office_manager,
	PositionsEnum.system_administrator,
	PositionsEnum.document_manager,
	PositionsEnum.user_acquisition_manager,
	PositionsEnum.secretary,
	PositionsEnum.assistant_manager,
	PositionsEnum.recruiter,
	PositionsEnum.hr,
	PositionsEnum.cleaner,
	PositionsEnum.masseur,
	PositionsEnum.promo_artist
]

/* type DefaulSalariesInfo = {
	[key in LevelEnum]: { min: number; max: number; to_next: number }
} */

const byLevelInfo = Object.keys(LevelEnum).reduce((acc, level) => {
	acc[level] = { min: 0, max: 0, to_next: 6 }

	return acc
}, {})

const defaultSalaries = Object.keys(PositionsEnum).reduce((acc, position) => {
	if (
		![
			...positionsWithoutPromotion,
			PositionsEnum.unity_developer,
			PositionsEnum.three_d_generalist,
			PositionsEnum.three_d_artist,
			PositionsEnum.javascript_developer,
			PositionsEnum.full_stack_developer,
			PositionsEnum.level_designer
		].includes(PositionsEnum[position])
	) {
		acc[position] = byLevelInfo
	}

	return acc
}, {})

const salaries = {
	[PositionsEnum.unity_developer]: {
		[LevelEnum.junior_base]: { min: 500, max: 700, to_next: 3.5 },
		[LevelEnum.junior_strong]: { min: 701, max: 900, to_next: 4.5 },
		[LevelEnum.middle_starter]: { min: 901, max: 1100, to_next: 6.5 },
		[LevelEnum.middle_base]: { min: 1101, max: 1300, to_next: 4.5 },
		[LevelEnum.middle_strong]: { min: 1301, max: 1500, to_next: 10 },
		[LevelEnum.middle_experienced]: { min: 1501, max: 1800, to_next: 12 },
		[LevelEnum.middle_advanced]: { min: 1801, max: 2100, to_next: 0 },
		[LevelEnum.middle_expert]: { min: 2101, max: 2400, to_next: 0 }
	},
	[PositionsEnum.three_d_generalist]: {
		[LevelEnum.junior_base]: { min: 400, max: 600, to_next: 3.5 },
		[LevelEnum.junior_strong]: { min: 601, max: 800, to_next: 4.5 },
		[LevelEnum.middle_starter]: { min: 801, max: 1100, to_next: 6.5 },
		[LevelEnum.middle_base]: { min: 1101, max: 1300, to_next: 8.5 },
		[LevelEnum.middle_strong]: { min: 1301, max: 1500, to_next: 11 },
		[LevelEnum.middle_experienced]: { min: 1501, max: 1800, to_next: 13 },
		[LevelEnum.middle_advanced]: { min: 1801, max: 2100, to_next: 13 },
		[LevelEnum.middle_expert]: { min: 2101, max: 2400, to_next: 0 }
	},
	[PositionsEnum.three_d_artist]: {
		[LevelEnum.junior_base]: { min: 400, max: 600, to_next: 3.5 },
		[LevelEnum.junior_strong]: { min: 601, max: 800, to_next: 4.5 },
		[LevelEnum.middle_starter]: { min: 801, max: 1100, to_next: 6.5 },
		[LevelEnum.middle_base]: { min: 1101, max: 1300, to_next: 8.5 },
		[LevelEnum.middle_strong]: { min: 1301, max: 1500, to_next: 11 },
		[LevelEnum.middle_experienced]: { min: 1501, max: 1800, to_next: 13 },
		[LevelEnum.middle_advanced]: { min: 1801, max: 2100, to_next: 13 },
		[LevelEnum.middle_expert]: { min: 2101, max: 2400, to_next: 0 }
	},
	[PositionsEnum.javascript_developer]: {
		[LevelEnum.junior_starter]: { min: 400, max: 500, to_next: 2.5 },
		[LevelEnum.junior_base]: { min: 501, max: 700, to_next: 2.5 },
		[LevelEnum.junior_strong]: { min: 701, max: 900, to_next: 3.5 },
		[LevelEnum.middle_starter]: { min: 901, max: 1100, to_next: 4.5 },
		[LevelEnum.middle_base]: { min: 1101, max: 1300, to_next: 4.5 },
		[LevelEnum.middle_strong]: { min: 1301, max: 1500, to_next: 10 },
		[LevelEnum.middle_experienced]: { min: 1501, max: 1800, to_next: 12 },
		[LevelEnum.middle_advanced]: { min: 0, max: 0, to_next: 0 },
		[LevelEnum.middle_expert]: { min: 0, max: 0, to_next: 0 }
	},
	[PositionsEnum.full_stack_developer]: {
		[LevelEnum.junior_starter]: { min: 600, max: 700, to_next: 2 },
		[LevelEnum.junior_base]: { min: 701, max: 900, to_next: 4 },
		[LevelEnum.junior_strong]: { min: 901, max: 1100, to_next: 6 },
		[LevelEnum.middle_starter]: { min: 1101, max: 1400, to_next: 6 },
		[LevelEnum.middle_base]: { min: 1401, max: 1700, to_next: 6 },
		[LevelEnum.middle_strong]: { min: 1701, max: 2000, to_next: 8 },
		[LevelEnum.middle_experienced]: { min: 2001, max: 2500, to_next: 8 },
		[LevelEnum.middle_advanced]: { min: 0, max: 0, to_next: 0 },
		[LevelEnum.middle_expert]: { min: 0, max: 0, to_next: 0 }
	},
	[PositionsEnum.level_designer]: {
		[LevelEnum.junior_starter]: { min: 400, max: 500, to_next: 3 },
		[LevelEnum.junior_base]: { min: 501, max: 600, to_next: 4 },
		[LevelEnum.junior_strong]: { min: 601, max: 750, to_next: 6 },
		[LevelEnum.middle_starter]: { min: 751, max: 950, to_next: 8 },
		[LevelEnum.middle_base]: { min: 951, max: 1150, to_next: 10 },
		[LevelEnum.middle_strong]: { min: 1151, max: 1350, to_next: 10 },
		[LevelEnum.middle_experienced]: { min: 0, max: 0, to_next: 0 },
		[LevelEnum.middle_advanced]: { min: 0, max: 0, to_next: 0 },
		[LevelEnum.middle_expert]: { min: 0, max: 0, to_next: 0 }
	},
	...defaultSalaries
}

export const nextLevelDetector = (level: LevelEnum) => {
	let nextLevel: LevelEnum = level

	Object.keys(LevelEnum).forEach((key, idx) => {
		if (LevelEnum[key] !== LevelEnum.middle_expert && level === LevelEnum[key]) {
			nextLevel = Object.keys(LevelEnum)[idx + 1] as LevelEnum
		}
	})

	return nextLevel
}

export const salariesDetector = (position: PositionsEnum, level?: LevelEnum) => {
	if (
		positionsWithoutPromotion.includes(position) ||
		!level ||
		!Object.keys(LevelEnum).includes(level) ||
		!salaries[position] ||
		!salaries[position][level]
	) {
		return { min: 0, max: 0, to_next: 0 }
	}

	console.log(salaries)

	return salaries[position][level]
}

export const levelName = (level: LevelEnum) => {
	if (!level || !Object.keys(LevelEnum).includes(level)) return 'NL'

	return level
		.split('_')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
}

export const levelTag = (level: LevelEnum) => {
	switch (level) {
		case LevelEnum.junior_starter:
			return 'J1'
		case LevelEnum.junior_base:
			return 'J2'
		case LevelEnum.junior_strong:
			return 'J3'
		case LevelEnum.middle_starter:
			return 'M1'
		case LevelEnum.middle_base:
			return 'M2'
		case LevelEnum.middle_strong:
			return 'M3'
		case LevelEnum.middle_experienced:
			return 'M4'
		case LevelEnum.middle_advanced:
			return 'M5'
		case LevelEnum.middle_expert:
			return 'M6'
		default:
			return 'NL'
	}
}

export const levelColorIndex = (level: LevelEnum) => {
	switch (level) {
		case LevelEnum.junior_starter:
		case LevelEnum.junior_base:
		case LevelEnum.junior_strong:
			return 2
		case LevelEnum.middle_starter:
		case LevelEnum.middle_base:
		case LevelEnum.middle_strong:
			return 3
		case LevelEnum.middle_experienced:
		case LevelEnum.middle_advanced:
			return 5
		case LevelEnum.middle_expert:
			return 7
		default:
			return 4
	}
}

export const levelColor = (level: LevelEnum) => {
	return rainbowColorNames(levelColorIndex(level))
}
