import React from 'react'
import { Container, Spinner } from 'react-bootstrap'

const Loader = (props: { size?: 'sm' | 'lg' | 'tiny' }) => (
	<Container
		fluid
		className={`${
			(props.size === 'sm' || props.size === 'tiny') ? 'm-2' : 'vh-50'
		} d-flex justify-content-center align-items-center`}
	>
		<Spinner
			size={props.size === 'tiny' ? 'sm' : undefined}
			animation="grow"
			variant='primary'
			className={props.size !== 'tiny' ? 'me-2' : undefined}
		/>
	</Container>
)

export default Loader
