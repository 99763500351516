import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import UniversalTable from 'components/Tables/UniversalTable';

import {
	useArchiveContractorMutation,
	useDeleteContractorMutation,
	useLoadContractorsCodesQuery,
	useLoadContractorsNamesQuery,
	useLoadFilteredContractorsQuery,
	useUnarchiveContractorMutation
} from '../../../../../redux/slices/financeDictionaries'
import { FaArchive, FaTrashAlt } from 'react-icons/fa'
import { FaArrowRotateLeft, FaLock } from 'react-icons/fa6'
import UpdateContractorModal from '../Modals/UpdateContractorModal'
import { RolesEnum } from '../../../../../types/roles'
import { Contractor } from '../../../../../types/finance/contractor'
import TableError from '../../../../../components/TableError'
import DangerModal from '../../../../../components/Modals/DangerModal'
import SuccessModal from '../../../../../components/Modals/SuccessModal'
import Loader from '../../../../../components/Loaders/Loader'
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import CreateContractorModal from '../Modals/CreateContractorModal'
import { MultiselectFormGroup } from '../../../../../components/FormGroups'

const columns = [
	{
		dataField: 'name',
		text: 'ContractorsTable_name',
		sort: false,
		width: '20%',
		className: 'text-center',
	},
	{
		dataField: 'type',
		text: 'ContractorsTable_Type',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'class',
		text: 'ContractorsTable_Class',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'fullName',
		text: 'ContractorsTable_fullName',
		sort: false,
		width: '20%',
		className: 'text-center',
	},
	{
		dataField: 'taxCode',
		text: 'ContractorsTable_taxCode',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'address',
		text: 'ContractorsTable_registrationAddress',
		sort: false,
		width: '20%',
		className: 'text-center',
	},
	{
		dataField: 'actions',
		text: 'actions',
		sort: false,
		width: '10%',
		className: 'text-center',
	}
];

interface ContractorRowProps {
	contractor: Contractor;
	handlers: any
}

function ContractorRow({ contractor, handlers }: ContractorRowProps) {
	const { t, i18n } = useTranslation();

	const [archiveContractor, unarchiveContractor, deleteContractor] = handlers

	const renderTooltip = (content: string) => (props: any) => (
		<Tooltip id={`tooltip-${contractor._id}`} className={'custom-tooltip top'} {...props}>
			{content}
		</Tooltip>
	);

	return (
		<tr>
			<td className="text-center">{contractor.name}</td>
			<td className="text-center">{(i18n.language === 'uk' ? contractor.type.ukName : contractor.type.enName)}</td>
			<td className="text-center">{(i18n.language === 'uk' ? contractor.class.ukName : contractor.class.enName)}</td>
			<td className="text-center">{contractor.fullName}</td>
			<td className="text-center">{contractor.taxCode}</td>
			<td className="text-center">{contractor.registrationAddress}</td>
			<td className="text-center">
				<div className='d-flex align-middle justify-content-center'>
					{
						contractor.isSystem ? (
							<>
								<OverlayTrigger placement="top" overlay={renderTooltip(t('is_system'))}>
									<Button
										variant="outline-muted"
										size="sm"
										className="d-flex align-items-center justify-content-center p-1"
										style={{ width: "28px", height: "28px", borderRadius: "4px" }}
										disabled={true}
										onClick={() => {}}
										aria-label={t('is_system')}
									>
										<FaLock size={18} />
									</Button>
								</OverlayTrigger>
							</>
						) : (
							<>
								{
									!contractor.isArchived && (
										<UpdateContractorModal contractor={contractor} />
									)
								}
								{
									contractor.isArchived ? (
										<SuccessModal
											id={contractor._id}
											title={'unarchive'}
											icon={FaArrowRotateLeft}
											onAccept={() => unarchiveContractor(contractor._id)}
											tooltipText={'unarchive'}
											requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
										/>
									) : (
										<DangerModal
											id={contractor._id}
											title={'archive'}
											onAccept={() => archiveContractor(contractor._id)}
											icon={FaArchive}
											tooltipText={'archive'}
											requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
										/>
									)
								}
								{
									contractor.isArchived && (
										<DangerModal
											id={contractor._id}
											title="DeleteContractorModal_title"
											onAccept={() => deleteContractor(contractor._id)}
											icon={FaTrashAlt}
											tooltipText={'delete'}
											requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
										/>
									)
								}
							</>
						)
					}
				</div>
			</td>
		</tr>
	);
}

function ContractorsTable() {
	const { t } = useTranslation()

	const [currentPageExec, setCurrentPageExec] = useState(1)
	const [itemsPerPageExec, setItemsPerPageExec] = useState(10)
	const [currentPageUnexec, setCurrentPageUnexec] = useState(1)
	const [itemsPerPageUnexec, setItemsPerPageUnexec] = useState(10)

	const [archiveContractor] = useArchiveContractorMutation()
	const [unarchiveContractor] = useUnarchiveContractorMutation()
	const [deleteContractor] = useDeleteContractorMutation()

	const { data: contractorNames = [], isLoading: namesLoading } = useLoadContractorsNamesQuery()
	const { data: contractorCodes = [], isLoading: codesLoading } = useLoadContractorsCodesQuery()

	const [selectedContractorNames, setSelectedContractorNames] = useState<string[]>([]);
	const [selectedContractorCodes, setSelectedContractorCodes] = useState<string[]>([]);

	const {
		data: archivedRawData = { data: [], total: 0 },
		isLoading: archivedLoading,
		isError: isErrorArchived,
		refetch: refetchArchived
	} = useLoadFilteredContractorsQuery({
		page: currentPageExec,
		limit: itemsPerPageExec,
		codes: selectedContractorCodes,
		names: selectedContractorNames,
		isArchived: true
	});

	const {
		data: unarchivedRawData = { data: [], total: 0 },
		isLoading: unarchivedLoading,
		isError: isErrorUnarchived,
		refetch: refetchUnarchived
	} = useLoadFilteredContractorsQuery({
		page: currentPageUnexec,
		limit: itemsPerPageUnexec,
		codes: selectedContractorCodes,
		names: selectedContractorNames,
		isArchived: false
	});

	useEffect(() => {
		refetchArchived()
		refetchUnarchived()
	}, [
		selectedContractorCodes,
		selectedContractorNames,
		currentPageExec,
		itemsPerPageExec,
		currentPageUnexec,
		itemsPerPageUnexec,
		refetchArchived,
		refetchUnarchived
	]);

	if (archivedLoading || unarchivedLoading || namesLoading || codesLoading) return <Loader />;
	if (isErrorArchived || isErrorUnarchived) return <TableError>{t('request_error')}</TableError>;

	const contractorNamesForSelectOptions = contractorNames.map((name, index) => ({
		value: name,
		label: name,
		index
	}));

	const contractorCodesForSelectOptions = contractorCodes.map((code, index) => ({
		value: code,
		label: code,
		index
	}));

	const handleContractorNamesChange = (selectedOptions) => {
		setSelectedContractorNames(selectedOptions.map((option) => option.value));
	};

	const handleContractorCodesChange = (selectedOptions) => {
		setSelectedContractorCodes(selectedOptions.map((option) => option.value));
	};

	const unarchivedContractorData = unarchivedRawData.data || []
	const archivedContractorData = archivedRawData.data || []

	const handlers = [archiveContractor, unarchiveContractor, deleteContractor]

	return <>
		<Row className="d-flex align-items-center justify-content-center">
			<Col className="d-flex w-50">
				<div className="w-25 pr-2">
					<MultiselectFormGroup
						placeholder={t("ContractorsTable_name")}
						options={contractorNamesForSelectOptions}
						onChange={handleContractorNamesChange}
					/>
				</div>
				<div className="w-25">
					<MultiselectFormGroup
						placeholder={t("ContractorsTable_taxCode")}
						options={contractorCodesForSelectOptions}
						onChange={handleContractorCodesChange}
					/>
				</div>
			</Col>

			<Col xs="auto" className="text-right mb-3">
				<CreateContractorModal />
			</Col>
		</Row>
		{
			unarchivedRawData.total > 0 ?
				<UniversalTable
					name='ContractorsTable_tableName'
					id='contractorsTable ContractorsTable_tableName'
					columns={columns}
					data={unarchivedContractorData}
					row={(contractor, i) => <ContractorRow handlers={handlers} key={contractor._id + 'contractorRow'} contractor={contractor} />}
					scrollable
					externalCurrentPage={currentPageUnexec}
					onCurrentPageChange={setCurrentPageUnexec}
					externalItemsPerPage={itemsPerPageUnexec}
					onItemsPerPageChange={setItemsPerPageUnexec}
					totalItems={unarchivedRawData.total || 0}
				/> : <TableError>{t('no_data')}</TableError>
		}
		{
			archivedRawData.total > 0 ?
				<UniversalTable
					name='ContractorsTable_tableNameArchived'
					id='contractorsTable ContractorsTable_tableNameArchived'
					columns={columns}
					data={archivedContractorData}
					row={(contractor, i) => <ContractorRow handlers={handlers} key={contractor._id + 'contractorArchivedRow'} contractor={contractor} />}
					scrollable
					externalCurrentPage={currentPageExec}
					onCurrentPageChange={setCurrentPageExec}
					externalItemsPerPage={itemsPerPageExec}
					onItemsPerPageChange={setItemsPerPageExec}
					totalItems={archivedRawData.total || 0}
				/> : null
		}
	</>
}

export default ContractorsTable;
