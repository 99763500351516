import Employee from './employee'

export enum NotificationTypeEnum {
	info = 'info',
	warning = 'warning',
	danger = 'danger'
}

export interface SystemNotification {
	_id: string
	date: string
	type: NotificationTypeEnum
	isChecked: boolean
	receiver: Employee
	text: string
	title: string
	isArchived: boolean
	archivedDate?: Date
}
