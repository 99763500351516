import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { FaChevronRight } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { displayValue, groupMonthsByYear } from '../../../../../utils/reportsExcelParser'
import RangeCalendar from '../../../../../components/RangeCalendar'
import { SingleselectFormGroup } from '../../../../../components/FormGroups'
import moment from 'moment'

export const NATIVE_CURRENCY_NAME = 'UAH'
export const MANAGEMENT_CURRENCY_NAME = 'USD'
export const FIXED_COLUMN_STYLE = { width: '400px', minWidth: '400px', maxWidth: '400px' }
export const CLOSING_BG_COLOR = 'rgba(255, 181, 72, 0.6)'
export const ARCHIVED_BG_COLOR = '#d3d3d3'
export const ROOT_BG_COLOR = 'rgba(157,203,255, 0.5)'

export const defaultRanges = [
	{ value: '1d', label: 'for_one_day', index: 0 },
	{ value: '1w', label: 'for_one_week', index: 1 },
	{ value: '1m', label: 'for_one_month', index: 2 },
	{ value: '1y', label: 'for_one_year', index: 3 },
	{ value: 'hy', label: 'for_half_year', index: 4 },
	{ value: 'at', label: 'for_all_time', index: 5 },
]

export const quarterRanges = [
	{ value: 'q1', label: 'for_first_quarter', index: 0 },
	{ value: 'q2', label: 'for_second_quarter', index: 1 },
	{ value: 'q3', label: 'for_third_quarter', index: 2 },
	{ value: 'q4', label: 'for_fourth_quarter', index: 3 },
	{ value: 'hy', label: 'for_half_year', index: 4 },
	{ value: 'y', label: 'for_one_year', index: 5 },
	{ value: 'at', label: 'for_all_time', index: 6 },
]

export enum TurnoverReportMode {
	CashFlowType = 'cashFlowType',
	Accounts = 'accounts',
}

export enum CashFlowReportMode {
	CashFlowType = 'cashFlowType',
	IncomeExpense = 'incomeExpense',
}

export enum PnlReportMode {
	ExpenseType = 'expenseType',
	IncomeExpense = 'incomeExpense',
}

export const getIndentStyle = (level: number) => ({ ...FIXED_COLUMN_STYLE, paddingLeft: `${15 * level}px` })
export const getChevronStyle = (isOpen: boolean) => ({ transition: 'transform 0.2s', transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)' })
export const getAlternatingRowBg = (index: number) => index % 2 === 0 ? '#d4f7d4' : '#e6ffe6'

export const extractMonths = (months: string[]) => {
	return months.map((m) => {
		const date = moment(m, 'YYYY-MM')
		return { key: m, month: date.format('MMM'), year: date.format('YYYY') }
	})
}

export function ReportQuickSelect({
									  ranges,
									  defaultIndex = 3,
									  documentDateFrom,
									  documentDateTo,
									  setDocumentDateFrom,
									  setDocumentDateTo,
								  }) {
	const { t } = useTranslation()
	const handleQuickSelect = (option) => {
		let newFrom, newTo
		switch (option.value) {
			case '1d':
				newFrom = moment().startOf('day').toDate()
				newTo = moment().endOf('day').toDate()
				break
			case '1w':
				newFrom = moment().startOf('week').toDate()
				newTo = moment().endOf('week').toDate()
				break
			case '1m':
				newFrom = moment().startOf('month').toDate()
				newTo = moment().endOf('month').toDate()
				break
			case '1y':
			case 'y':
				newFrom = moment().startOf('year').toDate()
				newTo = moment().endOf('year').toDate()
				break
			case 'hy':
				newFrom = moment().startOf('year').add(6, 'months').toDate()
				newTo = moment().endOf('year').toDate()
				break
			case 'at':
				newFrom = moment('01.01.2022', 'DD.MM.YYYY').toDate()
				newTo = moment().endOf('year').toDate()
				break
			case 'q1':
				newFrom = moment().startOf('year').toDate()
				newTo = moment().month(2).endOf('month').toDate()
				break
			case 'q2':
				newFrom = moment().month(3).startOf('month').toDate()
				newTo = moment().month(5).endOf('month').toDate()
				break
			case 'q3':
				newFrom = moment().month(6).startOf('month').toDate()
				newTo = moment().month(8).endOf('month').toDate()
				break
			case 'q4':
				newFrom = moment().month(9).startOf('month').toDate()
				newTo = moment().endOf('year').toDate()
				break
			default:
				newFrom = moment().startOf('year').toDate()
				newTo = moment().endOf('year').toDate()
				break
		}
		setDocumentDateFrom(newFrom)
		setDocumentDateTo(newTo)
	}
	return (
		<>
			<Col className="mb-3 mb-md-0">
				<RangeCalendar
					startDate={documentDateFrom}
					endDate={documentDateTo}
					withSelect
					withFullWidth
					onChange={([start, end]) => {
						setDocumentDateFrom(start)
						setDocumentDateTo(end)
					}}
				/>
			</Col>
			<Col>
				<SingleselectFormGroup
					placeholder=""
					options={ranges.map(r => ({ ...r, label: t(r.label) }))}
					defaultValue={{ ...ranges[defaultIndex], label: t(ranges[defaultIndex].label) }}
					onChange={val => val && handleQuickSelect(val)}
					isClearable={false}
					error={false}
				/>
			</Col>
		</>
	)
}

export function RecursiveMonthlyRow({ row, level, months, monthIndices, showNative, useArchived }) {
	const [isOpen, setIsOpen] = useState(false)
	if (!useArchived && row.isArchived) return null
	const isLeaf = !row.children || row.children.length === 0
	const bgColor = row.isArchived ? ARCHIVED_BG_COLOR : level === 0 ? ROOT_BG_COLOR : 'inherit'
	const renderCell = (idx) => {
		const monthKey = months[idx].key
		const cell = row.monthly[monthKey] || { raw: 0, native: 0, mgm: 0 }
		return showNative ? (
			<React.Fragment key={`cell-${idx}`}>
				<td className="text-right text-nowrap">
					{isLeaf ? displayValue(cell.raw, row.currency) : displayValue(cell.native, NATIVE_CURRENCY_NAME)}
				</td>
				<td className="text-right text-nowrap">
					{displayValue(cell.mgm)}
				</td>
			</React.Fragment>
		) : (
			<td key={idx} className="text-right text-nowrap">{displayValue(cell.mgm)}</td>
		)
	}
	return (
		<>
			<tr style={{ cursor: !isLeaf ? 'pointer' : 'default', backgroundColor: bgColor }} onClick={() => !isLeaf && setIsOpen(!isOpen)}>
				<td className="text-break text-wrap align-middle" style={getIndentStyle(level)}>
					{!isLeaf && (
						<FaChevronRight size={12} className="me-1 mx-1" style={getChevronStyle(isOpen)} />
					)}
					{row.name}
				</td>
				{monthIndices.map(idx => renderCell(idx))}
				{showNative ? (
					<>
						<td className="text-right text-nowrap">{displayValue(row.total.native, NATIVE_CURRENCY_NAME)}</td>
						<td className="text-right text-nowrap">{displayValue(row.total.mgm)}</td>
					</>
				) : (
					<td className="text-right text-nowrap">{displayValue(row.total.mgm)}</td>
				)}
			</tr>
			{isOpen && row.children && row.children.map(child => (
				<RecursiveMonthlyRow
					key={child.id}
					row={child}
					level={level + 1}
					months={months}
					monthIndices={monthIndices}
					showNative={showNative}
					useArchived={useArchived}
				/>
			))}
		</>
	)
}

export function RenderAmountGroup({ opening, incoming, outgoing, closing, currency }) {
	return (
		<>
			<td className="text-right text-nowrap align-middle">{displayValue(opening, currency)}</td>
			<td className="text-right text-nowrap align-middle">{displayValue(incoming, currency)}</td>
			<td className="text-right text-nowrap align-middle">{displayValue(outgoing, currency)}</td>
			<td className="text-right text-nowrap align-middle" style={{ backgroundColor: CLOSING_BG_COLOR }}>
				{displayValue(closing, currency)}
			</td>
		</>
	)
}

export function RecursiveTreeRowComponent({
											  node,
											  level,
											  showNative,
											  useArchived,
											  displayRaw = false,
										  }: {
	node: any
	level: number
	showNative: boolean
	useArchived: boolean
	displayRaw?: boolean
}) {
	const [isOpen, setIsOpen] = useState(false)
	if (!useArchived && node.isArchived) return null
	const isLeaf = !node.children || node.children.length === 0
	const bgColor = node.isArchived ? ARCHIVED_BG_COLOR : level === 0 ? ROOT_BG_COLOR : 'inherit'
	const indentStyle = getIndentStyle(level)
	const getValue = (key: string, type = 'native') => node.amounts[key][type]
	return (
		<>
			<tr style={{ cursor: !isLeaf ? 'pointer' : 'default', backgroundColor: bgColor }} onClick={() => !isLeaf && setIsOpen(!isOpen)}>
				<td className="text-break text-wrap align-middle" style={indentStyle}>
					{!isLeaf && (
						<FaChevronRight size={12} className="me-1 mx-1" style={getChevronStyle(isOpen)} />
					)}
					{node.name}
				</td>
				{showNative && (() => {
					const useRaw = isLeaf || (!isLeaf && displayRaw)
					return (
						<RenderAmountGroup
							opening={getValue('opening', useRaw ? 'raw' : undefined)}
							incoming={getValue('incoming', useRaw ? 'raw' : undefined)}
							outgoing={getValue('outgoing', useRaw ? 'raw' : undefined)}
							closing={getValue('closing', useRaw ? 'raw' : undefined)}
							currency={useRaw ? node.currency : NATIVE_CURRENCY_NAME}
						/>
					)
				})()}
				<RenderAmountGroup
					opening={node.amounts.opening.mgm}
					incoming={node.amounts.incoming.mgm}
					outgoing={node.amounts.outgoing.mgm}
					closing={node.amounts.closing.mgm}
					currency={MANAGEMENT_CURRENCY_NAME}
				/>
			</tr>
			{isOpen && node.children && node.children.map((child: any) => (
				<RecursiveTreeRowComponent
					key={child.id}
					node={child}
					level={level + 1}
					showNative={showNative}
					useArchived={useArchived}
					displayRaw={displayRaw}
				/>
			))}
		</>
	)
}

export function DefaultReportHeader({ firstHeader, months, showNative, totalLabel }) {
	const yearGroups = groupMonthsByYear(months)
	const headerColSpan = showNative ? 2 : 1
	return (
		<thead>
		<tr>
			<th rowSpan={2} className="align-middle text-break text-wrap" style={FIXED_COLUMN_STYLE}>
				{firstHeader}
			</th>
			{yearGroups.map((group, idx) => (
				<th key={idx} colSpan={group.count * headerColSpan} className="text-center">
					{group.year}
				</th>
			))}
			<th rowSpan={2} colSpan={headerColSpan} className="align-middle text-center text-break text-wrap">
				{totalLabel}
			</th>
		</tr>
		<tr>
			{months.map((m, idx) => (
				<th key={idx} className="text-center" colSpan={headerColSpan}>
					{m.month}
				</th>
			))}
		</tr>
		</thead>
	)
}

export function DefaultTotalsRow({ months, reportTotals, showNative }) {
	const { t } = useTranslation()
	const getCellValue = (monthKey, field, defaultValue = 0) => reportTotals[monthKey] ? reportTotals[monthKey][field] : defaultValue
	return (
		<tr className="fw-bold bg-warning">
			<td>{t('total')}</td>
			{months.map((m, idx) => (
				<React.Fragment key={idx}>
					{showNative && (
						<td className="text-right text-nowrap">
							{displayValue(getCellValue(m.key, 'native'), NATIVE_CURRENCY_NAME)}
						</td>
					)}
					<td className="text-right text-nowrap">{displayValue(getCellValue(m.key, 'mgm'))}</td>
				</React.Fragment>
			))}
			{showNative ? (
				<>
					<td className="text-right text-nowrap">
						{displayValue(reportTotals['overall']?.native || 0, NATIVE_CURRENCY_NAME)}
					</td>
					<td className="text-right text-nowrap">{displayValue(reportTotals['overall']?.mgm || 0)}</td>
				</>
			) : (
				<td className="text-right text-nowrap">{displayValue(reportTotals['overall']?.mgm || 0)}</td>
			)}
		</tr>
	)
}

interface ReportSwitchesProps {
	useArchived: boolean
	setUseArchived: any
	mode?: string
	setMode?: any
	modeOptions?: { value: string; label: string }[]
	showNative: boolean
	setShowNative: any
}

export function ReportSwitches({
								   useArchived,
								   setUseArchived,
								   mode,
								   setMode,
								   modeOptions,
								   showNative,
								   setShowNative,
							   }: ReportSwitchesProps) {
	const { t } = useTranslation()
	return (
		<Row>
			<Col className="d-flex flex-wrap align-items-center justify-content-start px-3 py-2">
				<span>{t('filter') + ':'}</span>
				<Button variant={!useArchived ? 'primary' : 'outline-primary'} onClick={() => setUseArchived(false)} className="my-1 mx-2 shadow-none">
					{t('no_use_archived')}
				</Button>
				<Button variant={useArchived ? 'primary' : 'outline-primary'} onClick={() => setUseArchived(true)} className="my-1 shadow-none">
					{t('use_archived')}
				</Button>
			</Col>
			<Col className="d-flex flex-wrap align-items-center justify-content-center px-3 py-2">
				{mode && setMode && modeOptions && (
					<>
						<span className="mr-2">{t('group_by') + ':'}</span>
						{modeOptions.map(opt => (
							<Button key={opt.value} variant={mode === opt.value ? 'primary' : 'outline-primary'} onClick={() => setMode(opt.value)} className="my-1 mr-2 shadow-none">
								{t(opt.label)}
							</Button>
						))}
					</>
				)}
			</Col>
			<Col className="d-flex flex-wrap align-items-center justify-content-end px-3 py-2">
				<span>{t('display_with') + ':'}</span>
				<Button variant={showNative ? 'primary' : 'outline-primary'} onClick={() => setShowNative(true)} className="my-1 mx-2 shadow-none">
					{t('full_report')}
				</Button>
				<Button variant={!showNative ? 'primary' : 'outline-primary'} onClick={() => setShowNative(false)} className="my-1 shadow-none">
					{t('short_report')}
				</Button>
			</Col>
		</Row>
	)
}

export function ReportTotalsRow({ totals, showNative, t, nativeCurrency = NATIVE_CURRENCY_NAME }) {
	return (
		<tr className="fw-bold bg-warning">
			<td>{t('total')}</td>
			{showNative && (
				<>
					<td className="text-right text-nowrap">{displayValue(totals.openingNativeConv, nativeCurrency)}</td>
					<td className="text-right text-nowrap">{displayValue(totals.incomingNativeConv, nativeCurrency)}</td>
					<td className="text-right text-nowrap">{displayValue(totals.outgoingNativeConv, nativeCurrency)}</td>
					<td className="text-right text-nowrap">{displayValue(totals.closingNativeConv, nativeCurrency)}</td>
				</>
			)}
			<td className="text-right text-nowrap">{displayValue(totals.openingMgm)}</td>
			<td className="text-right text-nowrap">{displayValue(totals.incomingMgm)}</td>
			<td className="text-right text-nowrap">{displayValue(totals.outgoingMgm)}</td>
			<td className="text-right text-nowrap">{displayValue(totals.closingMgm)}</td>
		</tr>
	)
}