import { combineReducers } from 'redux'

import theme from './themeReducer'
import data from './data'
import user from './userReducer'

import { financeDictionariesApi } from '../slices/financeDictionaries';
import { projectsApi } from '../slices/projects'
import { publishersApi } from '../slices/publishers'
import { operationStatementsApi } from '../slices/operationStatements'
import { invoicesApi } from '../slices/invoices'
import { salariesTableApi } from '../slices/salariesTable'
import { reportsApi } from '../slices/reports'
import { systemSettingsApi } from '../slices/systemSettings'
import { replenishmentRequestApi } from '../slices/replenishmentRequests'
import { cronApi } from '../slices/crons'
import { divisionsApi } from '../slices/divisions'
import { employeesApi } from '../slices/employees'
import { systemNotificationsApi } from '../slices/notifications'
import { settingsApi } from '../slices/settings'
import { promotionsApi } from '../slices/promotions'
import { sicknessesApi } from '../slices/sicknesses'
import { vacationsApi } from '../slices/vacations'
import {timeTrackingApi} from "../slices/timeTracking";

const rootReducer = combineReducers({
	theme,
	data,
	user,

	[financeDictionariesApi.reducerPath]: financeDictionariesApi.reducer,
	[projectsApi.reducerPath]: projectsApi.reducer,
	[publishersApi.reducerPath]: publishersApi.reducer,
	[operationStatementsApi.reducerPath]: operationStatementsApi.reducer,
	[invoicesApi.reducerPath]: invoicesApi.reducer,
	[salariesTableApi.reducerPath]: salariesTableApi.reducer,
	[reportsApi.reducerPath]: reportsApi.reducer,
	[systemSettingsApi.reducerPath]: systemSettingsApi.reducer,
	[replenishmentRequestApi.reducerPath]: replenishmentRequestApi.reducer,
	[cronApi.reducerPath]: cronApi.reducer,
	[divisionsApi.reducerPath]: divisionsApi.reducer,
	[employeesApi.reducerPath]: employeesApi.reducer,
	[systemNotificationsApi.reducerPath]: systemNotificationsApi.reducer,
	[settingsApi.reducerPath]: settingsApi.reducer,
	[promotionsApi.reducerPath]: promotionsApi.reducer,
	[sicknessesApi.reducerPath]: sicknessesApi.reducer,
	[vacationsApi.reducerPath]: vacationsApi.reducer,
	[timeTrackingApi.reducerPath]: timeTrackingApi.reducer,
})

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;