import React, { useState, useCallback, useMemo } from 'react';

export const SettingsContext = React.createContext({
	isDirty: false,
	setIsDirty: (value: boolean) => {},
	saveSettings: () => Promise.resolve(),
	cancelSettings: () => Promise.resolve(),
	isUpdating: false,
	registerSaveHandler: (handler: () => Promise<void>) => {},
	registerCancelHandler: (handler: () => void) => {}
});

export const SettingsProvider = ({ children }) => {
	const [isDirty, setIsDirty] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const [saveHandler, setSaveHandler] = useState<(() => Promise<void>) | null>(null);
	const [cancelHandler, setCancelHandler] = useState<(() => void) | null>(null);

	// Memoize registration functions so they remain stable between renders.
	const registerSaveHandler = useCallback((handler: () => Promise<void>) => {
		setSaveHandler(() => handler);
	}, []);

	const registerCancelHandler = useCallback((handler: () => void) => {
		setCancelHandler(() => handler);
	}, []);

	// Memoize save and cancel actions.
	const saveSettings = useCallback(async () => {
		if (saveHandler) {
			setIsUpdating(true);
			try {
				await saveHandler();
				setIsDirty(false);
			} catch (error) {
				// Optionally handle error.
			} finally {
				setIsUpdating(false);
			}
		}
	}, [saveHandler]);

	const cancelSettings = useCallback(async () => {
		if (cancelHandler) {
			await cancelHandler();
			setIsDirty(false);
		}
	}, [cancelHandler]);

	// Memoize context value to avoid unnecessary re-renders.
	const contextValue = useMemo(
		() => ({
			isDirty,
			setIsDirty,
			saveSettings,
			cancelSettings,
			isUpdating,
			registerSaveHandler,
			registerCancelHandler
		}),
		[isDirty, saveSettings, cancelSettings, isUpdating, registerSaveHandler, registerCancelHandler]
	);

	return (
		<SettingsContext.Provider value={contextValue}>
			{children}
		</SettingsContext.Provider>
	);
};
