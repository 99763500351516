import {
	AccountClosingRow,
	Month,
	AmountTotals,
} from '../../../../../types/finance/reports'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { displayValue } from '../../../../../utils/reportsExcelParser'
import { Button, Card, Collapse, Table } from 'react-bootstrap'
import { FaChevronRight } from 'react-icons/fa'
import { FIXED_COLUMN_STYLE, NATIVE_CURRENCY_NAME } from './Commons'

interface CollapsibleAccountClosingTableProps {
	data: AccountClosingRow[]
	months: Month[]
	overall: Record<string, AmountTotals>
	showNative?: boolean
}

export default function CollapsibleAccountClosingTable({
											data,
											months,
											overall,
											showNative = true
										}: CollapsibleAccountClosingTableProps) {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const toggleFolding = () => setIsOpen(!isOpen)
	const headerColSpan = showNative ? 2 : 1

	const renderCells = (cell: AmountTotals, monthKey: string, currency?: string) =>
		showNative ? (
			<>
				<td key={`${monthKey}-native`} className="text-right">
					{displayValue(cell.raw, currency)}
				</td>
				<td key={`${monthKey}-mgm`} className="text-right">
					{displayValue(cell.mgm)}
				</td>
			</>
		) : (
			<td key={`${monthKey}-mgm`} className="text-right">
				{displayValue(cell.mgm)}
			</td>
		)

	const renderTotalCells = (cell: AmountTotals, monthKey: string) =>
		showNative ? (
			<>
				<td key={`${monthKey}-native`} className="text-right">
					{displayValue(cell.native, NATIVE_CURRENCY_NAME)}
				</td>
				<td key={`${monthKey}-mgm`} className="text-right">
					{displayValue(cell.mgm)}
				</td>
			</>
		) : (
			<td key={`${monthKey}-mgm`} className="text-right">
				{displayValue(cell.mgm)}
			</td>
		)

	return (
		<Card className="mt-3">
			<Card.Header className="d-flex align-items-center justify-content-between">
				<Card.Title as="h4" className="mb-0" style={FIXED_COLUMN_STYLE}>
					<Button
						variant="link"
						onClick={toggleFolding}
						aria-controls="collapseAccountClosingTable"
						aria-expanded={isOpen}
						className="p-0 me-2 d-flex align-items-center"
					>
						<FaChevronRight
							size={12}
							style={{ transform: isOpen ? 'rotate(90deg)' : 'none' }}
							className="me-2"
						/>
						{t('CashFlowReport_accountsTable')}
					</Button>
				</Card.Title>
			</Card.Header>
			<Collapse in={isOpen}>
				<div id="collapseAccountClosingTable">
					<Table bordered hover responsive className="table-striped table-sm mt-3">
						<thead>
						<tr>
							<th style={FIXED_COLUMN_STYLE}>{t('account')}</th>
							{months.map((month) => (
								<th key={month.key} colSpan={headerColSpan} className="text-center">
									{month.month}
								</th>
							))}
						</tr>
						</thead>
						<tbody>
						{data.map((row) => (
							<tr key={row.accountId}>
								<td>{row.accountName}</td>
								{months.map((month) => {
									const cell = row.closingByMonth[month.key] || { raw: 0, native: 0, mgm: 0 }
									return renderCells(cell, month.key, row.currency)
								})}
							</tr>
						))}
						<tr className="fw-bold bg-warning no-wrap">
							<td>{t('total')}</td>
							{months.map((month) => {
								const cell = overall[month.key] || { raw: 0, native: 0, mgm: 0 }
								return renderTotalCells(cell, month.key)
							})}
						</tr>
						</tbody>
					</Table>
				</div>
			</Collapse>
		</Card>
	)
}