import * as checkins from './checkins'
import * as conceptArts from './conceptArts'
import * as concepts from './concepts'
import * as employees from './employees'
import * as subscriptions from './subscriptions'
import * as projects from './projects'
import * as statistics from './statistics'
import * as trainingProjects from './trainingProjects'
import * as visits from './visits'
import * as ideas from './ideas'
import * as surveys from './surveys'
import * as parties from './parties'
import * as todolists from './todolists'
import * as kpi from './kpi'

const dataOperations = {
	checkins,
	conceptArts,
	concepts,
	employees,
	subscriptions,
	projects,
	statistics,
	trainingProjects,
	visits,
	ideas,
	surveys,
	parties,
	todolists,
	kpi
}

export default dataOperations
