import { useTranslation } from 'react-i18next'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaFileExcel } from 'react-icons/fa'
import React from 'react'
import { downloadTurnoversExcel } from '../../../../../../utils/reportsExcelParser'

interface TurnoverExcelButtonProps {
	data?: any;
	showNative?: boolean;
	dateFrom: Date,
	dateTo: Date
}

export default function TurnoverExcelButton({ data, showNative = true, dateFrom, dateTo }: TurnoverExcelButtonProps) {
	const { t } = useTranslation()

	if (!data || data.length <= 0) return null

	const load = async () => {
		return await downloadTurnoversExcel(data, showNative, dateFrom, dateTo)
	};

	const renderTooltip = (props) => (
		<Tooltip id="tooltip-left" className={'custom-tooltip left'} {...props}>
			{t('DownloadTurnoverReport_text')}
		</Tooltip>
	)

	return (
		<OverlayTrigger placement="left" overlay={renderTooltip}>
			<Button
				id='TurnoverReportDowloadButton'
				variant="outline-success"
				onClick={load}
				className='rounded-lg shadow-sm ml-2 mb-0'
			>
				<FaFileExcel />
			</Button>
		</OverlayTrigger>
	)
}