import React from 'react'
import { useTranslation } from 'react-i18next';

import UniversalTable from 'components/Tables/UniversalTable';

import {
	useArchiveContractsMutation,
	useDeleteContractsMutation,
	useLoadContractsQuery,
	useUnarchiveContractsMutation
} from '../../../../../redux/slices/financeDictionaries'

import { FaArchive, FaTrashAlt } from 'react-icons/fa'
import { FaArrowRotateLeft, FaLock } from 'react-icons/fa6'
import UpdateContractModal from '../Modals/UpdateContractModal'
import { RolesEnum } from '../../../../../types/roles'
import { Contract } from '../../../../../types/finance/contract'
import TableError from '../../../../../components/TableError'
import Moment from 'react-moment'
import SuccessModal from '../../../../../components/Modals/SuccessModal'
import DangerModal from '../../../../../components/Modals/DangerModal'
import Loader from '../../../../../components/Loaders/Loader'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

const columns = [
	{
		dataField: 'order_number',
		text: '№',
		sort: false,
		width: '5%',
		className: 'text-center'
	},
	{
		dataField: 'organization',
		text: 'ContractsTable_organization',
		sort: false,
		width: '20%',
		className: 'text-center',
	},
	{
		dataField: 'contractor',
		text: 'ContractsTable_contractor',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'type',
		text: 'ContractsTable_Type',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'name',
		text: 'ContractsTable_name',
		sort: false,
		width: '15%',
		className: 'text-center',
	},
	{
		dataField: 'creationDate',
		text: 'ContractsTable_creationDate',
		sort: true,
		width: '15%',
		className: 'text-center',
	},
	{
		dataField: 'conditions',
		text: 'ContractsTable_conditions',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'postponement',
		text: 'ContractsTable_postponement',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'actions',
		text: 'actions',
		sort: false,
		width: '5%',
		className: 'text-center',
	}
];

interface ContractRowProps {
	contract: Contract;
	handlers: any;
	index: number;
}

function ContractRow({ contract, handlers, index }: ContractRowProps) {
	const { t, i18n } = useTranslation();

	const [archiveContract, unarchiveContract, deleteContract] = handlers

	const renderTooltip = (content: string) => (props: any) => (
		<Tooltip id={`tooltip-${contract._id}`} className={'custom-tooltip top'} {...props}>
			{content}
		</Tooltip>
	);

	return (
		<tr>
			<td className="text-center">{index + 1}</td>
			<td className="text-center">{contract.organization ? contract.organization.name : '-'}</td>
			<td className="text-center">{contract.contractor ? contract.contractor.name : '-'}</td>
			<td className="text-center">{contract.type ? (i18n.language === 'uk' ? contract.type.ukName : contract.type.enName) : "-"}</td>
			<td className="text-center">{contract.name}</td>
			<td className="text-center">
				<Moment format="DD.MM.YYYY" locale={t('locales')} date={contract.creationDate} />
			</td>
			<td className="text-center">{t(contract.conditions || '-')}</td>
			<td className="text-center">{contract.postponement}</td>
			<td className="text-center">
				<div className='d-flex align-middle justify-content-center'>
					{
						contract.isSystem ? (
							<>
								<OverlayTrigger placement="top" overlay={renderTooltip(t('is_system'))}>
									<Button
										variant="outline-muted"
										size="sm"
										className="d-flex align-items-center justify-content-center p-1"
										style={{ width: "28px", height: "28px", borderRadius: "4px" }}
										disabled={true}
										onClick={() => {}}
										aria-label={t('is_system')}
									>
										<FaLock size={18} />
									</Button>
								</OverlayTrigger>
							</>
						) : (
							<>
								{
									!contract.isArchived && (
										<UpdateContractModal contract={contract} />
									)
								}
								{
									contract.isArchived ? (
										<SuccessModal
											id={contract._id}
											title='unarchive'
											icon={FaArrowRotateLeft}
											onAccept={() => unarchiveContract(contract._id)}
											tooltipText='unarchive'
											requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
										/>
									) : (
										<DangerModal
											id={contract._id}
											title='archive'
											onAccept={() => archiveContract(contract._id)}
											icon={FaArchive}
											tooltipText='archive'
											requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
										/>
									)
								}
								{
									contract.isArchived && (
										<DangerModal
											id={contract._id}
											title="DeleteContractModal_title"
											onAccept={() => deleteContract(contract._id)}
											icon={FaTrashAlt}
											tooltipText='delete'
											requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
										/>
									)
								}
							</>
						)
					}
				</div>
			</td>
		</tr>
	);
}

function ContractsTable() {
	const { t } = useTranslation()

	const [archiveContract] = useArchiveContractsMutation()
	const [unarchiveContract] = useUnarchiveContractsMutation()
	const [deleteContract] = useDeleteContractsMutation()

	const { data: contractData, isLoading, isError } = useLoadContractsQuery();

	if (isLoading) return <Loader />;
	if (isError) return <TableError>{t('request_error')}</TableError>;

	const unarchivedContractData = (contractData && contractData.filter(entry => !entry.isArchived)) || []
	const archivedContractData = (contractData && contractData.filter(entry => entry.isArchived)) || []

	const handlers = [archiveContract, unarchiveContract, deleteContract]

	const sortedData = unarchivedContractData.sort(
		(a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
	)

	return <>
		{
			unarchivedContractData && unarchivedContractData.length > 0 ?
				<UniversalTable
					name={'ContractsTable_tableName'}
					id={'contractsTable ContractsTable_tableName'}
					columns={columns}
					data={sortedData}
					row={(contract, i) => <ContractRow handlers={handlers} key={contract._id + 'contractRow'} contract={contract} index={i} />}
					scrollable
					withPagination
				/> : <TableError>{t('no_data')}</TableError>
		}
		{
			archivedContractData && archivedContractData.length > 0 ?
				<UniversalTable
					name={'ContractsTable_tableNameArchived'}
					id={'contractsTable ContractsTable_tableNameArchived'}
					columns={columns}
					data={archivedContractData}
					row={(contract, i) => <ContractRow handlers={handlers} key={contract._id + 'contractRow'} contract={contract} index={i} />}
					scrollable
					withPagination
				/> : null
		}
	</>
}

export default ContractsTable;
