import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/axios.queryBuilder';
import {
	EmployeeSettings, FinancesSettings,
	GantChartSettings,
	GeneralSettings, HRSettings,
	IntegrationsSettings,
	MainPageSettings,
	NotificationsSettings,
	ProjectsSettings, PublishersSettings, StatisticsSettings
} from '../../types/settings'

export const settingsApi = createApi({
	reducerPath: 'settingsApi',
	baseQuery: axiosBaseQuery({ baseUrl: 'settings/' }),
	tagTypes: ['Settings'],
	endpoints: (builder) => ({
		// General Settings
		getGeneralSettings: builder.query<GeneralSettings, void>({
			query: () => ({ url: 'general', method: 'GET' }),
			providesTags: ['Settings'],
		}),
		updateGeneralSettings: builder.mutation<GeneralSettings, any>({
			query: (data) => ({ url: 'general', method: 'PATCH', data }),
			invalidatesTags: ['Settings'],
		}),

		// Integrations Settings
		getIntegrationsSettings: builder.query<IntegrationsSettings, void>({
			query: () => ({ url: 'integrations', method: 'GET' }),
			providesTags: ['Settings'],
		}),
		updateIntegrationsSettings: builder.mutation<IntegrationsSettings, any>({
			query: (data) => ({ url: 'integrations', method: 'PATCH', data }),
			invalidatesTags: ['Settings'],
		}),

		// Notifications Settings
		getNotificationsSettings: builder.query<NotificationsSettings, void>({
			query: () => ({ url: 'notifications', method: 'GET' }),
			providesTags: ['Settings'],
		}),
		updateNotificationsSettings: builder.mutation<NotificationsSettings, any>({
			query: (data) => ({ url: 'notifications', method: 'PATCH', data }),
			invalidatesTags: ['Settings'],
		}),

		// Main Page Settings
		getMainPageSettings: builder.query<MainPageSettings, void>({
			query: () => ({ url: 'main-page', method: 'GET' }),
			providesTags: ['Settings'],
		}),
		updateMainPageSettings: builder.mutation<MainPageSettings, any>({
			query: (data) => ({ url: 'main-page', method: 'PATCH', data }),
			invalidatesTags: ['Settings'],
		}),

		// Projects Settings
		getProjectsSettings: builder.query<ProjectsSettings, void>({
			query: () => ({ url: 'projects', method: 'GET' }),
			providesTags: ['Settings'],
		}),
		updateProjectsSettings: builder.mutation<ProjectsSettings, any>({
			query: (data) => ({ url: 'projects', method: 'PATCH', data }),
			invalidatesTags: ['Settings'],
		}),

		// Gant Chart Settings
		getGantChartSettings: builder.query<GantChartSettings, void>({
			query: () => ({ url: 'gant-chart', method: 'GET' }),
			providesTags: ['Settings'],
		}),
		updateGantChartSettings: builder.mutation<GantChartSettings, any>({
			query: (data) => ({ url: 'gant-chart', method: 'PATCH', data }),
			invalidatesTags: ['Settings'],
		}),

		// HR Settings
		getHRSettings: builder.query<HRSettings, void>({
			query: () => ({ url: 'hr', method: 'GET' }),
			providesTags: ['Settings'],
		}),
		updateHRSettings: builder.mutation<HRSettings, any>({
			query: (data) => ({ url: 'hr', method: 'PATCH', data }),
			invalidatesTags: ['Settings'],
		}),

		// Statistics Settings
		getStatisticsSettings: builder.query<StatisticsSettings, void>({
			query: () => ({ url: 'statistics', method: 'GET' }),
			providesTags: ['Settings'],
		}),
		updateStatisticsSettings: builder.mutation<StatisticsSettings, any>({
			query: (data) => ({ url: 'statistics', method: 'PATCH', data }),
			invalidatesTags: ['Settings'],
		}),

		// Employee Settings
		getEmployeeSettings: builder.query<EmployeeSettings, void>({
			query: () => ({ url: 'employee', method: 'GET' }),
			providesTags: ['Settings'],
		}),
		updateEmployeeSettings: builder.mutation<EmployeeSettings, any>({
			query: (data) => ({ url: 'employee', method: 'PATCH', data }),
			invalidatesTags: ['Settings'],
		}),

		// Publishers Settings
		getPublishersSettings: builder.query<PublishersSettings, void>({
			query: () => ({ url: 'publishers', method: 'GET' }),
			providesTags: ['Settings'],
		}),
		updatePublishersSettings: builder.mutation<PublishersSettings, any>({
			query: (data) => ({ url: 'publishers', method: 'PATCH', data }),
			invalidatesTags: ['Settings'],
		}),

		// Finances Settings
		getFinancesSettings: builder.query<FinancesSettings, void>({
			query: () => ({ url: 'finances', method: 'GET' }),
			providesTags: ['Settings'],
		}),
		updateFinancesSettings: builder.mutation<FinancesSettings, any>({
			query: (data) => ({ url: 'finances', method: 'PATCH', data }),
			invalidatesTags: ['Settings'],
		}),
	}),
});

export const {
	useGetGeneralSettingsQuery,
	useUpdateGeneralSettingsMutation,
	useGetIntegrationsSettingsQuery,
	useUpdateIntegrationsSettingsMutation,
	useGetNotificationsSettingsQuery,
	useUpdateNotificationsSettingsMutation,
	useGetMainPageSettingsQuery,
	useUpdateMainPageSettingsMutation,
	useGetProjectsSettingsQuery,
	useUpdateProjectsSettingsMutation,
	useGetGantChartSettingsQuery,
	useUpdateGantChartSettingsMutation,
	useGetHRSettingsQuery,
	useUpdateHRSettingsMutation,
	useGetStatisticsSettingsQuery,
	useUpdateStatisticsSettingsMutation,
	useGetEmployeeSettingsQuery,
	useUpdateEmployeeSettingsMutation,
	useGetPublishersSettingsQuery,
	useUpdatePublishersSettingsMutation,
	useGetFinancesSettingsQuery,
	useUpdateFinancesSettingsMutation,
} = settingsApi;
