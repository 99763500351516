import ExcelJS from 'exceljs'
import moment from 'moment'
import i18n from 'i18next'
import { formatNumber } from './number'
import { Buffer } from 'buffer'
import { downloadBlobFile } from './file'
import { currencySign } from '../types/finance/currency'
import {
	AccountClosingRow,
	AmountTotals,
	CashFlowReport,
	MonthlyRow,
	PnlReport
} from '../types/finance/reports'
import {
	MANAGEMENT_CURRENCY_NAME,
	NATIVE_CURRENCY_NAME
} from '../pages/dashboards/Finance/Reports/Tables/Commons'

const TEXT_KEYS = {
	account: 'account',
	cashFlowType: 'cashFlowType',
	expenseType: 'expenseType',
	direction: 'direction',
	systemNative: 'systemNative',
	managementCurrency: 'managementCurrency',
	openingBalance: 'openingBalance',
	income: 'income',
	expense: 'expense',
	closingBalance: 'closingBalance',
	total: 'total',
	type: 'type',
	incoming: 'incoming',
	outcoming: 'outcoming',
	starting_cash: 'starting_cash',
	closing_cash: 'closing_cash',
	turnover_report: 'turnover_report',
	net_settlement_report: 'net_settlement_report',
	cash_flow_report: 'cash_flow_report',
	pnl_report: 'pnl_report'
}

const CONFIG = {
	// Updated widths: cashFlowWidths now match accountWidths
	cashFlowWidths: { fixed: 40, data: 25 },
	accountWidths: { fixed: 40, data: 25 },
	rowHeights: { header1: 25, header2: 20, dataLeaf: 30, dataNonLeaf: 18, totals: 18 },
	HEADER_FONT: { bold: true, color: { argb: 'FF000000' } },
	HEADER_ALIGNMENT: { horizontal: 'center' as const, vertical: 'middle' as const, wrapText: true },
	DATA_ALIGNMENT_RIGHT: { horizontal: 'right' as const, vertical: 'middle' as const, wrapText: false },
	DATA_ALIGNMENT_LEFT: { horizontal: 'left' as const, vertical: 'middle' as const, wrapText: true },
	TOTALS_FONT: { bold: true },
	COLORS: {
		headerFill: 'BDD7EE',
		headerBorder: 'FFCCCCCC',
		dataBorder: 'FFD9D9D9',
		totalsFill: 'FFFFEB9C',
		totalsBorder: 'FFBFBFBF',
		totalsFont: 'FF000000'
	},
	BG: { info: 'FFD1ECF1', success: 'FFD4EDDA' }
}

const BORDER_STYLE = {
	header: { style: 'thin' as ExcelJS.BorderStyle, color: { argb: CONFIG.COLORS.headerBorder } },
	data: { style: 'thin' as ExcelJS.BorderStyle, color: { argb: CONFIG.COLORS.dataBorder } },
	totals: { style: 'thin' as ExcelJS.BorderStyle, color: { argb: CONFIG.COLORS.totalsBorder } }
}

export function displayValue(val: number, currencyName?: string): string {
	return val === 0 ? '' : `${formatNumber(val)} ${currencySign(currencyName ?? MANAGEMENT_CURRENCY_NAME)}`
}

const setCellBorder = (cell: ExcelJS.Cell, border: Partial<ExcelJS.Border>) => {
	cell.border = { top: border, left: border, bottom: border, right: border }
}

export const applyHeaderCellStyle = (cell: ExcelJS.Cell): void => {
	cell.font = CONFIG.HEADER_FONT
	cell.alignment = CONFIG.HEADER_ALIGNMENT
	cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.COLORS.headerFill } }
	setCellBorder(cell, BORDER_STYLE.header)
}

const mergeAndStyleCells = (
	worksheet: ExcelJS.Worksheet,
	startRow: number,
	startCol: number,
	endRow: number,
	endCol: number,
	value: any,
	styleFn: (cell: ExcelJS.Cell) => void
) => {
	try { worksheet.mergeCells(startRow, startCol, endRow, endCol) } catch {}
	const cell = worksheet.getCell(startRow, startCol)
	cell.value = value
	styleFn(cell)
}

export function groupMonthsByYear(months: { month: string; year: string }[]): { year: string; count: number }[] {
	const groups: { year: string; count: number }[] = []
	months.forEach(m => {
		if (!groups.length || groups[groups.length - 1].year !== m.year) {
			groups.push({ year: m.year, count: 1 })
		} else {
			groups[groups.length - 1].count++
		}
	})
	return groups
}

export function flattenHierarchicalData(rows: MonthlyRow[]): MonthlyRow[] {
	return rows.reduce((acc, row) => {
		acc.push(row)
		if (row.children && row.children.length > 0) {
			acc.push(...flattenHierarchicalData(row.children))
		}
		return acc
	}, [] as MonthlyRow[])
}

function createCashFlowHeaders(
	worksheet: ExcelJS.Worksheet,
	months: { month: string; year: string; key: string }[],
	showNative: boolean,
	startRow: number
): { rowIndex: number; colCount: number } {
	const headerColSpan = showNative ? 2 : 1
	const colCount = 1 + (months.length + 1) * headerColSpan
	Array.from({ length: colCount }).forEach((_, idx) => {
		worksheet.getColumn(idx + 1).width = idx === 0 ? CONFIG.cashFlowWidths.fixed : CONFIG.cashFlowWidths.data
	})
	const yearGroups = groupMonthsByYear(months)
	let rowIndex = startRow
	const headerRow1 = worksheet.getRow(rowIndex)
	headerRow1.height = CONFIG.rowHeights.header1
	worksheet.mergeCells(rowIndex, 1, rowIndex + 1, 1)
	const firstHeaderCell = worksheet.getCell(rowIndex, 1)
	firstHeaderCell.value = i18n.t(`${TEXT_KEYS.direction} / ${TEXT_KEYS.cashFlowType}`, { defaultValue: 'Direction / Cash Flow Type' })
	applyHeaderCellStyle(firstHeaderCell)
	let colCursor = 2
	yearGroups.forEach(group => {
		const startCol = colCursor
		const endCol = colCursor + group.count * headerColSpan - 1
		mergeAndStyleCells(worksheet, rowIndex, startCol, rowIndex, endCol, Number(group.year), applyHeaderCellStyle)
		colCursor = endCol + 1
	})
	worksheet.mergeCells(rowIndex, colCount - headerColSpan + 1, rowIndex + 1, colCount)
	const totalHeaderCell = worksheet.getCell(rowIndex, colCount - headerColSpan + 1)
	totalHeaderCell.value = i18n.t(TEXT_KEYS.total, { defaultValue: 'Total' })
	applyHeaderCellStyle(totalHeaderCell)
	rowIndex++
	const headerRow2 = worksheet.getRow(rowIndex)
	headerRow2.height = CONFIG.rowHeights.header2
	if (showNative) {
		months.forEach((m, idx) => {
			const startCol = 2 + idx * 2
			worksheet.mergeCells(rowIndex, startCol, rowIndex, startCol + 1)
			const cell = worksheet.getCell(rowIndex, startCol)
			cell.value = m.month
			applyHeaderCellStyle(cell)
		})
	} else {
		months.forEach((m, idx) => {
			const cell = worksheet.getCell(rowIndex, idx + 2)
			cell.value = m.month
			applyHeaderCellStyle(cell)
		})
	}
	rowIndex++
	return { rowIndex, colCount }
}

function writeHierarchicalTableAt(
	worksheet: ExcelJS.Worksheet,
	report: CashFlowReport,
	months: { month: string; year: string; key: string }[],
	startRow: number,
	showNative: boolean
): number {
	const { rowIndex, colCount } = createCashFlowHeaders(worksheet, months, showNative, startRow)
	let rowCursor = rowIndex
	const headerColSpan = showNative ? 2 : 1
	const processRow = (row: MonthlyRow, level: number): void => {
		const excelRow = worksheet.getRow(rowCursor)
		excelRow.height = row.children && row.children.length ? CONFIG.rowHeights.dataNonLeaf : CONFIG.rowHeights.dataLeaf
		const firstCell = excelRow.getCell(1)
		firstCell.value = row.name
		firstCell.alignment = { ...CONFIG.DATA_ALIGNMENT_LEFT, indent: level }
		setCellBorder(firstCell, BORDER_STYLE.data)
		months.forEach((m, idx) => {
			const startCol = 2 + idx * headerColSpan
			const cellData = row.monthly[m.key] || { raw: 0, native: 0, mgm: 0 }
			if (showNative) {
				const cellMgmt = excelRow.getCell(startCol)
				const valueMgmt = row.children && row.children.length ? cellData.raw : cellData.native
				cellMgmt.value = displayValue(valueMgmt, row.currency || NATIVE_CURRENCY_NAME)
				cellMgmt.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
				setCellBorder(cellMgmt, BORDER_STYLE.data)
				const cellMgm = excelRow.getCell(startCol + 1)
				cellMgm.value = displayValue(cellData.mgm)
				cellMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
				setCellBorder(cellMgm, BORDER_STYLE.data)
			} else {
				try { worksheet.mergeCells(rowCursor, startCol, rowCursor, startCol + headerColSpan - 1) } catch {}
				const cell = excelRow.getCell(startCol)
				cell.value = displayValue(cellData.mgm)
				cell.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
				setCellBorder(cell, BORDER_STYLE.data)
			}
		})
		const totalStartCol = colCount - headerColSpan + 1
		if (showNative) {
			const cellTotalMgmt = excelRow.getCell(totalStartCol)
			cellTotalMgmt.value = displayValue(row.total.native, NATIVE_CURRENCY_NAME)
			cellTotalMgmt.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellTotalMgmt, BORDER_STYLE.data)
			const cellTotalMgm = excelRow.getCell(totalStartCol + 1)
			cellTotalMgm.value = displayValue(row.total.mgm)
			cellTotalMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellTotalMgm, BORDER_STYLE.data)
		} else {
			try { worksheet.mergeCells(rowCursor, totalStartCol, rowCursor, totalStartCol + headerColSpan - 1) } catch {}
			const cellTotal = excelRow.getCell(totalStartCol)
			cellTotal.value = displayValue(row.total.mgm)
			cellTotal.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellTotal, BORDER_STYLE.data)
		}
		excelRow.outlineLevel = level
		excelRow.commit()
		rowCursor++
		if (row.children && row.children.length) {
			row.children.forEach(child => processRow(child, level + 1))
		}
	}
	report.rows.forEach(row => processRow(row, 0))
	const summaryRow = worksheet.getRow(rowCursor)
	summaryRow.height = CONFIG.rowHeights.totals
	summaryRow.getCell(1).value = i18n.t(TEXT_KEYS.total, { defaultValue: 'Total' })
	months.forEach((m, idx) => {
		const startCol = 2 + idx * headerColSpan
		if (showNative) {
			const cellMgmt = summaryRow.getCell(startCol)
			cellMgmt.value = displayValue(report.monthlyTotals[m.key]?.native || 0, NATIVE_CURRENCY_NAME)
			cellMgmt.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellMgmt, BORDER_STYLE.data)
			const cellMgm = summaryRow.getCell(startCol + 1)
			cellMgm.value = displayValue(report.monthlyTotals[m.key]?.mgm || 0)
			cellMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellMgm, BORDER_STYLE.data)
		} else {
			try { worksheet.mergeCells(rowCursor, startCol, rowCursor, startCol + headerColSpan - 1) } catch {}
			const cell = summaryRow.getCell(startCol)
			cell.value = displayValue(report.monthlyTotals[m.key]?.mgm || 0)
			cell.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cell, BORDER_STYLE.data)
		}
	})
	if (showNative) {
		const cellTotalMgmt = summaryRow.getCell(colCount - headerColSpan + 1)
		cellTotalMgmt.value = displayValue(report.overallTotal.native, NATIVE_CURRENCY_NAME)
		cellTotalMgmt.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
		setCellBorder(cellTotalMgmt, BORDER_STYLE.data)
		const cellTotalMgm = summaryRow.getCell(colCount)
		cellTotalMgm.value = displayValue(report.overallTotal.mgm)
		cellTotalMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
		setCellBorder(cellTotalMgm, BORDER_STYLE.data)
	} else {
		try { worksheet.mergeCells(rowCursor, colCount - headerColSpan + 1, rowCursor, colCount) } catch {}
		const cellTotal = summaryRow.getCell(colCount - headerColSpan + 1)
		cellTotal.value = displayValue(report.overallTotal.mgm)
		cellTotal.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
		setCellBorder(cellTotal, BORDER_STYLE.data)
	}
	summaryRow.eachCell(cell => {
		cell.font = CONFIG.TOTALS_FONT
		cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.COLORS.totalsFill } }
		setCellBorder(cell, BORDER_STYLE.totals)
	})
	summaryRow.commit()
	rowCursor++
	const startingRow = worksheet.getRow(rowCursor)
	startingRow.height = CONFIG.rowHeights.totals
	startingRow.getCell(1).value = i18n.t(TEXT_KEYS.starting_cash, { defaultValue: 'Starting Cash' })
	months.forEach((m, idx) => {
		const startCol = 2 + idx * headerColSpan
		if (showNative) {
			const cellMgmt = startingRow.getCell(startCol)
			cellMgmt.value = displayValue(report.overallOpening[m.key]?.native || 0, NATIVE_CURRENCY_NAME)
			cellMgmt.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellMgmt, BORDER_STYLE.data)
			const cellMgm = startingRow.getCell(startCol + 1)
			cellMgm.value = displayValue(report.overallOpening[m.key]?.mgm || 0)
			cellMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellMgm, BORDER_STYLE.data)
		} else {
			const cell = startingRow.getCell(idx + 2)
			cell.value = displayValue(report.overallOpening[m.key]?.mgm || 0)
			cell.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cell, BORDER_STYLE.data)
		}
	})
	startingRow.commit()
	rowCursor++
	const closingRow = worksheet.getRow(rowCursor)
	closingRow.height = CONFIG.rowHeights.totals
	closingRow.getCell(1).value = i18n.t(TEXT_KEYS.closing_cash, { defaultValue: 'Closing Cash' })
	months.forEach((m, idx) => {
		const startCol = 2 + idx * headerColSpan
		if (showNative) {
			const cellMgmt = closingRow.getCell(startCol)
			cellMgmt.value = displayValue(report.overallClosing[m.key]?.native || 0, NATIVE_CURRENCY_NAME)
			cellMgmt.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellMgmt, BORDER_STYLE.data)
			const cellMgm = closingRow.getCell(startCol + 1)
			cellMgm.value = displayValue(report.overallClosing[m.key]?.mgm || 0)
			cellMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellMgm, BORDER_STYLE.data)
		} else {
			const cell = closingRow.getCell(idx + 2)
			cell.value = displayValue(report.overallClosing[m.key]?.mgm || 0)
			cell.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cell, BORDER_STYLE.data)
		}
	})
	closingRow.eachCell(cell => {
		cell.font = CONFIG.TOTALS_FONT
		cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.BG.success } }
		setCellBorder(cell, BORDER_STYLE.totals)
	})
	closingRow.commit()
	rowCursor++
	worksheet.properties.outlineProperties = { summaryBelow: false, summaryRight: false }
	return rowCursor
}

function writeAccountClosingTableAt(
	worksheet: ExcelJS.Worksheet,
	accounts: AccountClosingRow[],
	months: { month: string; year: string; key: string }[],
	startRow: number,
	showNative: boolean,
	overall: Record<string, AmountTotals>
): number {
	const totalColCount = 1 + months.length * (showNative ? 2 : 1)
	for (let idx = 1; idx <= totalColCount; idx++) {
		worksheet.getColumn(idx).width = idx === 1 ? CONFIG.accountWidths.fixed : CONFIG.accountWidths.data
	}
	let rowCursor = startRow
	const headerRow1 = worksheet.getRow(rowCursor)
	headerRow1.height = CONFIG.rowHeights.header1
	headerRow1.getCell(1).value = i18n.t(TEXT_KEYS.account, { defaultValue: 'Account' })
	applyHeaderCellStyle(headerRow1.getCell(1))
	try { worksheet.mergeCells(rowCursor, 1, rowCursor + 1, 1) } catch {}
	let colCursor = 2
	const yearGroups = groupMonthsByYear(months)
	yearGroups.forEach(group => {
		const startCol = colCursor
		const endCol = colCursor + group.count * (showNative ? 2 : 1) - 1
		mergeAndStyleCells(worksheet, rowCursor, startCol, rowCursor, endCol, Number(group.year), applyHeaderCellStyle)
		colCursor = endCol + 1
	})
	headerRow1.commit()
	rowCursor++
	const headerRow2 = worksheet.getRow(rowCursor)
	months.forEach((m, idx) => {
		const startCol = 2 + idx * (showNative ? 2 : 1)
		if (showNative) {
			worksheet.mergeCells(rowCursor, startCol, rowCursor, startCol + 1)
			const cell = headerRow2.getCell(startCol)
			cell.value = m.month
			applyHeaderCellStyle(cell)
		} else {
			const cell = headerRow2.getCell(startCol)
			cell.value = m.month
			applyHeaderCellStyle(cell)
		}
	})
	headerRow2.commit()
	rowCursor++
	accounts.forEach(account => {
		const row = worksheet.getRow(rowCursor)
		row.height = CONFIG.rowHeights.dataLeaf
		row.getCell(1).value = account.accountName
		row.getCell(1).alignment = CONFIG.DATA_ALIGNMENT_LEFT
		setCellBorder(row.getCell(1), BORDER_STYLE.data)
		months.forEach((m, idx) => {
			const startCol = 2 + idx * (showNative ? 2 : 1)
			const cellData = account.closingByMonth[m.key] || { raw: 0, native: 0, mgm: 0 }
			if (showNative) {
				const cellRaw = row.getCell(startCol)
				cellRaw.value = displayValue(cellData.raw, account.currency)
				cellRaw.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
				setCellBorder(cellRaw, BORDER_STYLE.data)
				const cellMgm = row.getCell(startCol + 1)
				cellMgm.value = displayValue(cellData.mgm)
				cellMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
				setCellBorder(cellMgm, BORDER_STYLE.data)
			} else {
				try { worksheet.mergeCells(rowCursor, startCol, rowCursor, startCol) } catch {}
				const cell = row.getCell(startCol)
				cell.value = displayValue(cellData.mgm)
				cell.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
				setCellBorder(cell, BORDER_STYLE.data)
			}
		})
		row.commit()
		rowCursor++
	})
	const totalsRowExcel = worksheet.getRow(rowCursor)
	totalsRowExcel.height = CONFIG.rowHeights.totals
	totalsRowExcel.getCell(1).value = i18n.t(TEXT_KEYS.total, { defaultValue: 'Total' })
	months.forEach((m, idx) => {
		const startCol = 2 + idx * (showNative ? 2 : 1)
		if (showNative) {
			const cellRaw = totalsRowExcel.getCell(startCol)
			cellRaw.value = displayValue(overall[m.key]?.raw || 0)
			cellRaw.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellRaw, BORDER_STYLE.data)
			const cellMgm = totalsRowExcel.getCell(startCol + 1)
			cellMgm.value = displayValue(overall[m.key]?.mgm || 0)
			cellMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellMgm, BORDER_STYLE.data)
		} else {
			const cell = totalsRowExcel.getCell(startCol)
			cell.value = displayValue(overall[m.key]?.mgm || 0)
			cell.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cell, BORDER_STYLE.data)
		}
	})
	totalsRowExcel.eachCell(cell => {
		cell.font = CONFIG.TOTALS_FONT
		cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.COLORS.totalsFill } }
		setCellBorder(cell, BORDER_STYLE.totals)
	})
	totalsRowExcel.commit()
	rowCursor++
	return rowCursor
}

function createGenericReportHeaders(
	worksheet: ExcelJS.Worksheet,
	headersConfig: { firstColLabel: string; group1: string; group2: string; subHeaders: string[] },
	startRow: number
): number {
	const colCount = headersConfig.subHeaders.length
	Array.from({ length: colCount }).forEach((_, idx) => {
		worksheet.getColumn(idx + 1).width = idx === 0 ? 40 : 18
	})
	const headerRow1 = worksheet.getRow(startRow)
	headerRow1.height = CONFIG.rowHeights.header1
	worksheet.mergeCells(startRow, 1, startRow + 1, 1)
	const firstCell = worksheet.getCell(startRow, 1)
	firstCell.value = headersConfig.firstColLabel
	applyHeaderCellStyle(firstCell)
	if (!headersConfig.group2) {
		worksheet.mergeCells(startRow, 2, startRow, colCount)
		const groupCell = worksheet.getCell(startRow, 2)
		groupCell.value = headersConfig.group1
		applyHeaderCellStyle(groupCell)
	} else {
		const remaining = colCount - 1
		const group1Cols = Math.floor(remaining / 2)
		worksheet.mergeCells(startRow, 2, startRow, 1 + group1Cols)
		const group1Cell = worksheet.getCell(startRow, 2)
		group1Cell.value = headersConfig.group1
		applyHeaderCellStyle(group1Cell)
		worksheet.mergeCells(startRow, 2 + group1Cols, startRow, colCount)
		const group2Cell = worksheet.getCell(startRow, 2 + group1Cols)
		group2Cell.value = headersConfig.group2
		applyHeaderCellStyle(group2Cell)
	}
	headerRow1.commit()
	const headerRow2 = worksheet.getRow(startRow + 1)
	headerRow2.height = CONFIG.rowHeights.header2
	for (let i = 1; i < colCount; i++) {
		const cell = headerRow2.getCell(i + 1)
		cell.value = headersConfig.subHeaders[i]
		applyHeaderCellStyle(cell)
	}
	headerRow2.commit()
	return startRow + 2
}

const generateExcelReport = async (
	sheetName: string,
	headersConfig: { firstColLabel: string; group1: string; group2: string; subHeaders: string[] },
	dataRows: string[][],
	rowLevels: number[],
	totalsRow: string[],
	filePrefix: string,
	dateFrom: Date,
	dateTo: Date
): Promise<{ fileName: string; buffer: Buffer }> => {
	const startDateStr = moment(dateFrom).format('DD.MM.YYYY')
	const endDateStr = moment(dateTo).format('DD.MM.YYYY')
	const fileName = `${filePrefix}_(${startDateStr}_${endDateStr}).xlsx`
	const workbook = new ExcelJS.Workbook()
	const worksheet = workbook.addWorksheet(sheetName)
	let rowCursor = createGenericReportHeaders(worksheet, headersConfig, 1)
	dataRows.forEach((rowData, i) => {
		const row = worksheet.getRow(rowCursor)
		row.values = rowData
		row.outlineLevel = rowLevels[i]
		row.eachCell({ includeEmpty: true }, (cell, col) => {
			if (!cell.alignment) {
				cell.alignment = { wrapText: col === 1, horizontal: col === 1 ? 'left' : 'right', vertical: 'middle' }
			}
			setCellBorder(cell, BORDER_STYLE.data)
		})
		row.commit()
		rowCursor++
	})
	const totalsExcelRow = worksheet.getRow(rowCursor)
	totalsExcelRow.values = totalsRow
	totalsExcelRow.eachCell({ includeEmpty: true }, (cell, col) => {
		cell.font = { bold: true, color: { argb: CONFIG.COLORS.totalsFont } }
		cell.alignment = { wrapText: col === 1, horizontal: col === 1 ? 'left' : 'right', vertical: 'middle' }
		cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.COLORS.totalsFill } }
		setCellBorder(cell, BORDER_STYLE.totals)
	})
	totalsExcelRow.commit()
	worksheet.properties.outlineLevelRow = Math.max(...rowLevels, 0)
	worksheet.properties.outlineProperties = { summaryBelow: false, summaryRight: false }
	const arrayBuffer = await workbook.xlsx.writeBuffer()
	return { fileName, buffer: Buffer.from(arrayBuffer) }
}

const processNodeGeneric = (
	node: any,
	level: number,
	getValues: (node: any) => any,
	dataRows: string[][],
	rowLevels: number[],
	keys: string[],
	showNative: boolean
) => {
	const indent = node.children && node.children.length ? '\u2003'.repeat(level) : ''
	const label = indent + node.name
	const values = getValues(node)
	const row: string[] = [label]
	if (showNative) {
		keys.forEach(key => {
			row.push(displayValue(values[`${key}Native`], values.nativeCurrency))
		})
		keys.forEach(key => {
			row.push(displayValue(values[`${key}Mgm`]))
		})
	} else {
		keys.forEach(key => {
			row.push(displayValue(values[`${key}Mgm`]))
		})
	}
	dataRows.push(row)
	rowLevels.push(level)
	if (node.children && node.children.length) {
		node.children.forEach((child: any) =>
			processNodeGeneric(child, level + 1, getValues, dataRows, rowLevels, keys, showNative)
		)
	}
}

function parseReportGeneric(
	{ tree, totals, getValues, headersConfig, keys }: { tree: any[]; totals: any; getValues: any; headersConfig: { firstColLabel: string; group1: string; group2: string; subHeaders: string[] }; keys: string[] },
	showNative: boolean
) {
	const dataRows: string[][] = []
	const rowLevels: number[] = []
	tree.forEach(node => processNodeGeneric(node, 0, getValues, dataRows, rowLevels, keys, showNative))
	let totalsRow: string[]
	if (showNative) {
		totalsRow = [i18n.t(TEXT_KEYS.total, { defaultValue: 'Total' })]
		keys.forEach(key => {
			totalsRow.push(displayValue(totals[`${key}NativeConv`], NATIVE_CURRENCY_NAME))
		})
		keys.forEach(key => {
			totalsRow.push(displayValue(totals[`${key}Mgm`]))
		})
	} else {
		totalsRow = [i18n.t(TEXT_KEYS.total, { defaultValue: 'Total' })]
		keys.forEach(key => {
			totalsRow.push(displayValue(totals[`${key}Mgm`]))
		})
	}
	return { headers: headersConfig, dataRows, rowLevels, totalsRow }
}

const downloadGenericExcelReport = async (
	finalReport: any,
	showNative: boolean,
	dateFrom: Date,
	dateTo: Date,
	sheetName: string,
	filePrefix: string,
	parser: (report: any, showNative: boolean) => { headers: any; dataRows: string[][]; rowLevels: number[]; totalsRow: string[] }
): Promise<void> => {
	const { headers, dataRows, rowLevels, totalsRow } = parser(finalReport, showNative)
	const { fileName, buffer } = await generateExcelReport(sheetName, headers, dataRows, rowLevels, totalsRow, filePrefix, dateFrom, dateTo)
	const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
	downloadBlobFile(blob, fileName)
}

export const parseTurnoversReport = (finalReport: any, showNative: boolean) => {
	const headersConfig = {
		firstColLabel:
			i18n.t(TEXT_KEYS.account, { defaultValue: 'Account' }) +
			' / ' +
			i18n.t(TEXT_KEYS.cashFlowType, { defaultValue: 'Cash Flow Type' }),
		group1: showNative
			? i18n.t(TEXT_KEYS.systemNative, { defaultValue: 'System Native' })
			: i18n.t(TEXT_KEYS.managementCurrency, { defaultValue: 'Management Currency' }),
		group2: showNative
			? i18n.t(TEXT_KEYS.managementCurrency, { defaultValue: 'Management Currency' })
			: '',
		subHeaders: showNative
			? [
				'',
				i18n.t(TEXT_KEYS.openingBalance, { defaultValue: 'Opening Balance' }),
				i18n.t(TEXT_KEYS.income, { defaultValue: 'Income' }),
				i18n.t(TEXT_KEYS.expense, { defaultValue: 'Expense' }),
				i18n.t(TEXT_KEYS.closingBalance, { defaultValue: 'Closing Balance' }),
				i18n.t(TEXT_KEYS.openingBalance, { defaultValue: 'Opening Balance' }),
				i18n.t(TEXT_KEYS.income, { defaultValue: 'Income' }),
				i18n.t(TEXT_KEYS.expense, { defaultValue: 'Expense' }),
				i18n.t(TEXT_KEYS.closingBalance, { defaultValue: 'Closing Balance' })
			]
			: [
				'',
				i18n.t(TEXT_KEYS.openingBalance, { defaultValue: 'Opening Balance' }),
				i18n.t(TEXT_KEYS.income, { defaultValue: 'Income' }),
				i18n.t(TEXT_KEYS.expense, { defaultValue: 'Expense' }),
				i18n.t(TEXT_KEYS.closingBalance, { defaultValue: 'Closing Balance' })
			]
	}
	const getValues = (node: any) => ({
		openingNative: showNative ? node.amounts.opening.native : 0,
		incomingNative: showNative ? node.amounts.incoming.native : 0,
		outgoingNative: showNative ? node.amounts.outgoing.native : 0,
		closingNative: showNative ? node.amounts.closing.native : 0,
		openingMgm: node.amounts.opening.mgm,
		incomingMgm: node.amounts.incoming.mgm,
		outgoingMgm: node.amounts.outgoing.mgm,
		closingMgm: node.amounts.closing.mgm,
		nativeCurrency: node.currency ?? ''
	})
	return parseReportGeneric(
		{ tree: finalReport.tree, totals: finalReport.totals, getValues, headersConfig, keys: ['opening', 'incoming', 'outgoing', 'closing'] },
		showNative
	)
}

export const parseNetSettlementReport = (finalReport: any, showNative: boolean) => {
	const headersConfig = {
		firstColLabel: i18n.t(TEXT_KEYS.type, { defaultValue: 'Type' }),
		group1: showNative
			? i18n.t(TEXT_KEYS.systemNative, { defaultValue: 'System Native' })
			: i18n.t(TEXT_KEYS.managementCurrency, { defaultValue: 'Management Currency' }),
		group2: showNative
			? i18n.t(TEXT_KEYS.managementCurrency, { defaultValue: 'Management Currency' })
			: '',
		subHeaders: showNative
			? [
				'',
				i18n.t(TEXT_KEYS.openingBalance, { defaultValue: 'Opening Balance' }),
				i18n.t(TEXT_KEYS.incoming, { defaultValue: 'Incoming' }),
				i18n.t(TEXT_KEYS.outcoming, { defaultValue: 'Outcoming' }),
				i18n.t(TEXT_KEYS.closingBalance, { defaultValue: 'Closing Balance' }),
				i18n.t(TEXT_KEYS.openingBalance, { defaultValue: 'Opening Balance' }),
				i18n.t(TEXT_KEYS.incoming, { defaultValue: 'Incoming' }),
				i18n.t(TEXT_KEYS.outcoming, { defaultValue: 'Outcoming' }),
				i18n.t(TEXT_KEYS.closingBalance, { defaultValue: 'Closing Balance' })
			]
			: [
				'',
				i18n.t(TEXT_KEYS.openingBalance, { defaultValue: 'Opening Balance' }),
				i18n.t(TEXT_KEYS.incoming, { defaultValue: 'Incoming' }),
				i18n.t(TEXT_KEYS.outcoming, { defaultValue: 'Outcoming' }),
				i18n.t(TEXT_KEYS.closingBalance, { defaultValue: 'Closing Balance' })
			]
	}
	const getValues = (node: any) => ({
		openingNative: showNative ? node.amounts.opening.native : 0,
		incomingNative: showNative ? node.amounts.incoming.native : 0,
		outgoingNative: showNative ? node.amounts.outgoing.native : 0,
		closingNative: showNative ? node.amounts.closing.native : 0,
		openingMgm: node.amounts.opening.mgm,
		incomingMgm: node.amounts.incoming.mgm,
		outgoingMgm: node.amounts.outgoing.mgm,
		closingMgm: node.amounts.closing.mgm,
		nativeCurrency: 'UAH'
	})
	return parseReportGeneric({ tree: finalReport.tree, totals: finalReport.totals, getValues, headersConfig, keys: ['opening', 'incoming', 'outgoing', 'closing'] }, showNative)
}

function writePnlTableAt(
	worksheet: ExcelJS.Worksheet,
	report: PnlReport,
	months: { month: string; year: string; key: string }[],
	startRow: number,
	showNative: boolean
): number {
	const { rowIndex, colCount } = createCashFlowHeaders(worksheet, months, showNative, startRow)
	let rowCursor = rowIndex
	const headerColSpan = showNative ? 2 : 1
	const processRow = (row: MonthlyRow, level: number): void => {
		const excelRow = worksheet.getRow(rowCursor)
		excelRow.height = row.children && row.children.length ? CONFIG.rowHeights.dataNonLeaf : CONFIG.rowHeights.dataLeaf
		const firstCell = excelRow.getCell(1)
		firstCell.value = row.name
		firstCell.alignment = { ...CONFIG.DATA_ALIGNMENT_LEFT, indent: level }
		setCellBorder(firstCell, BORDER_STYLE.data)
		excelRow.outlineLevel = level
		months.forEach((m, idx) => {
			const startCol = 2 + idx * headerColSpan
			const cellData = row.monthly[m.key] || { raw: 0, native: 0, mgm: 0 }
			if (showNative) {
				const cellMgmt = excelRow.getCell(startCol)
				const valueMgmt = row.children && row.children.length ? cellData.raw : cellData.native
				cellMgmt.value = displayValue(valueMgmt, row.currency || NATIVE_CURRENCY_NAME)
				cellMgmt.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
				setCellBorder(cellMgmt, BORDER_STYLE.data)
				const cellMgm = excelRow.getCell(startCol + 1)
				cellMgm.value = displayValue(cellData.mgm)
				cellMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
				setCellBorder(cellMgm, BORDER_STYLE.data)
			} else {
				try { worksheet.mergeCells(rowCursor, startCol, rowCursor, startCol + headerColSpan - 1) } catch {}
				const cell = excelRow.getCell(startCol)
				cell.value = displayValue(cellData.mgm)
				cell.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
				setCellBorder(cell, BORDER_STYLE.data)
			}
		})
		const totalStartCol = colCount - headerColSpan + 1
		if (showNative) {
			const cellTotalMgmt = excelRow.getCell(totalStartCol)
			cellTotalMgmt.value = displayValue(row.total.native, NATIVE_CURRENCY_NAME)
			cellTotalMgmt.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellTotalMgmt, BORDER_STYLE.data)
			const cellTotalMgm = excelRow.getCell(totalStartCol + 1)
			cellTotalMgm.value = displayValue(row.total.mgm)
			cellTotalMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellTotalMgm, BORDER_STYLE.data)
		} else {
			try { worksheet.mergeCells(rowCursor, totalStartCol, rowCursor, totalStartCol + headerColSpan - 1) } catch {}
			const cellTotal = excelRow.getCell(totalStartCol)
			cellTotal.value = displayValue(row.total.mgm)
			cellTotal.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellTotal, BORDER_STYLE.data)
		}
		excelRow.commit()
		rowCursor++
		if (row.children && row.children.length) {
			row.children.forEach(child => processRow(child, level + 1))
		}
	}
	report.rows.forEach(row => processRow(row, 0))
	const writeTotalsRow = (
		label: string,
		totals: Record<string, AmountTotals>,
		bgFill?: { type: 'pattern'; pattern: 'solid'; fgColor: { argb: string } }
	) => {
		if (totals['total'] === undefined) {
			let overallNative = 0, overallMgm = 0
			months.forEach(m => {
				overallNative += totals[m.key]?.native || 0
				overallMgm += totals[m.key]?.mgm || 0
			})
			totals['total'] = { native: overallNative, mgm: overallMgm, raw: 0 }
		}
		const excelRow = worksheet.getRow(rowCursor)
		excelRow.height = CONFIG.rowHeights.totals + 10
		excelRow.outlineLevel = 0
		const firstCell = excelRow.getCell(1)
		firstCell.value = label
		firstCell.alignment = CONFIG.DATA_ALIGNMENT_LEFT
		setCellBorder(firstCell, BORDER_STYLE.totals)
		months.forEach((m, idx) => {
			const startCol = 2 + idx * headerColSpan
			if (showNative) {
				const cellMgmt = excelRow.getCell(startCol)
				cellMgmt.value = displayValue(totals[m.key]?.native || 0, NATIVE_CURRENCY_NAME)
				cellMgmt.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
				setCellBorder(cellMgmt, BORDER_STYLE.data)
				const cellMgm = excelRow.getCell(startCol + 1)
				cellMgm.value = displayValue(totals[m.key]?.mgm || 0)
				cellMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
				setCellBorder(cellMgm, BORDER_STYLE.data)
			} else {
				try { worksheet.mergeCells(rowCursor, startCol, rowCursor, startCol + headerColSpan - 1) } catch {}
				const cell = excelRow.getCell(startCol)
				cell.value = displayValue(totals[m.key]?.mgm || 0)
				cell.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
				setCellBorder(cell, BORDER_STYLE.data)
			}
		})
		const totalStartCol = colCount - headerColSpan + 1
		if (showNative) {
			const cellTotalMgmt = excelRow.getCell(totalStartCol)
			cellTotalMgmt.value = displayValue(totals['total']?.native || 0, NATIVE_CURRENCY_NAME)
			cellTotalMgmt.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellTotalMgmt, BORDER_STYLE.data)
			const cellTotalMgm = excelRow.getCell(totalStartCol + 1)
			cellTotalMgm.value = displayValue(totals['total']?.mgm || 0)
			cellTotalMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cellTotalMgm, BORDER_STYLE.data)
		} else {
			try { worksheet.mergeCells(rowCursor, totalStartCol, rowCursor, totalStartCol + headerColSpan - 1) } catch {}
			const cell = excelRow.getCell(totalStartCol)
			cell.value = displayValue(totals['total']?.mgm || 0)
			cell.alignment = CONFIG.DATA_ALIGNMENT_RIGHT
			setCellBorder(cell, BORDER_STYLE.data)
		}
		excelRow.eachCell(cell => {
			cell.font = CONFIG.TOTALS_FONT
			cell.fill = bgFill || { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.COLORS.totalsFill } }
			setCellBorder(cell, BORDER_STYLE.totals)
		})
		excelRow.commit()
		rowCursor++
	}
	const monthlyTotalsRecord: Record<string, AmountTotals> = { ...report.monthlyTotals }
	writeTotalsRow(i18n.t(TEXT_KEYS.total, { defaultValue: 'Total' }), monthlyTotalsRecord)
	const additionalTotals = [
		{ key: 'marginTotals', label: i18n.t('marginProfit') },
		{ key: 'operatingProfitEBIT', label: i18n.t('operatingProfitEBIT') },
		{ key: 'interestTotals', label: i18n.t('interestOnLoans') },
		{ key: 'operatingProfitBeforeTaxation', label: i18n.t('operatingProfitBeforeTaxation') },
		{ key: 'taxesTotals', label: i18n.t('taxes') },
		{ key: 'netIncome', label: i18n.t('netIncome') }
	]
	additionalTotals.forEach(totalRow => {
		const totalsRecord: Record<string, AmountTotals> = { ...report[totalRow.key] }
		writeTotalsRow(totalRow.label, totalsRecord, { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.BG.success } })
	})
	worksheet.properties.outlineProperties = { summaryBelow: false, summaryRight: false }
	return rowCursor
}

export async function downloadCashFlowExcel(
	report: CashFlowReport,
	dateFrom: Date,
	dateTo: Date,
	months: { month: string; year: string; key: string }[],
	showNative: boolean
): Promise<void> {
	const workbook = new ExcelJS.Workbook()
	const worksheet = workbook.addWorksheet(i18n.t(TEXT_KEYS.cash_flow_report, { defaultValue: 'Cash Flow Report' }), { views: [{ showGridLines: true }] })
	let currentRow = 1
	currentRow = writeHierarchicalTableAt(worksheet, report, months, currentRow, showNative)
	currentRow += 4
	writeAccountClosingTableAt(worksheet, report.accountsTable, months, currentRow, showNative, report.accountsOverall)
	const fileName = `CashFlow_Report_(${moment(dateFrom).format('DD.MM.YYYY')}_${moment(dateTo).format('DD.MM.YYYY')}).xlsx`
	const arrayBuffer = await workbook.xlsx.writeBuffer()
	const buffer = Buffer.from(arrayBuffer)
	const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
	downloadBlobFile(blob, fileName)
}

export const downloadTurnoversExcel = (
	finalReport: any,
	showNative: boolean,
	dateFrom: Date,
	dateTo: Date
): Promise<void> =>
	downloadGenericExcelReport(
		finalReport,
		showNative,
		dateFrom,
		dateTo,
		'Turnover Data',
		i18n.t(TEXT_KEYS.turnover_report, { defaultValue: 'Turnover Report' }),
		parseTurnoversReport
	)

export const downloadNetSettlementExcel = (
	finalReport: any,
	showNative: boolean,
	dateFrom: Date,
	dateTo: Date
): Promise<void> =>
	downloadGenericExcelReport(
		finalReport,
		showNative,
		dateFrom,
		dateTo,
		'Net Settlement',
		i18n.t(TEXT_KEYS.net_settlement_report, { defaultValue: 'Net Settlement Report' }),
		parseNetSettlementReport
	)

export async function downloadPnlExcel(
	report: PnlReport,
	dateFrom: Date,
	dateTo: Date,
	months: { month: string; year: string; key: string }[],
	showNative: boolean
): Promise<void> {
	const workbook = new ExcelJS.Workbook()
	const worksheet = workbook.addWorksheet(i18n.t(TEXT_KEYS.pnl_report, { defaultValue: 'PnL Report' }), { views: [{ showGridLines: true }] })
	let currentRow = 1
	writePnlTableAt(worksheet, report, months, currentRow, showNative)
	worksheet.columns.forEach((col, idx) => {
		col.width = idx === 0 ? CONFIG.cashFlowWidths.fixed : CONFIG.cashFlowWidths.data
	})
	const fileName = `PnL_Report_(${moment(dateFrom).format('DD.MM.YYYY')}_${moment(dateTo).format('DD.MM.YYYY')}).xlsx`
	const arrayBuffer = await workbook.xlsx.writeBuffer()
	const buffer = Buffer.from(arrayBuffer)
	const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
	downloadBlobFile(blob, fileName)
}
