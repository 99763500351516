export type TimeUnit =
	| "year" | "years" | "y"
	| "month" | "months" | "M"
	| "week" | "weeks" | "w"
	| "day" | "days" | "d"
	| "hour" | "hours" | "h"
	| "minute" | "minutes" | "m"
	| "second" | "seconds" | "s"
	| "millisecond" | "milliseconds" | "ms";

export interface MomentTimeEntry {
	amount: number;
	unit: TimeUnit
}

export enum VacationsDistributionEnum {
	yearly = 'yearly',
	semiAnnually = 'semiAnnually',
	quarterly = 'quarterly'
}

export interface GeneralSettings {}
export interface IntegrationsSettings {}
export interface NotificationsSettings {}
export interface MainPageSettings {}
export interface ProjectsSettings {}
export interface GantChartSettings {}
export interface SicknessesSettings {}
export interface LatenessesSettings {}
export interface EventsSettings {}
export interface CompanyStatisticsSettings {}
export interface EmployeeStatisticsSettings {}
export interface PublishersSettings {}
export interface FinanceDictionariesSettings {}
export interface OperationStatementsSettings {}
export interface InvoicesSettings {}
export interface AccountsSettings {}
export interface ReportsSettings {}
export interface CashBoxExpensesSettings {}
export interface SalariesSettings {}
export interface ProfitabilitySettings {}
export interface SubscriptionsSettings {}

export interface ConflictingPositions {
	conflictingPositions: string[];
}

export interface VacationsSettings {
	autoApprove: boolean;
	allowVacationsBetweenPeriods: boolean
	minimumEmployedTime: MomentTimeEntry;
	minimalPeriodBetweenVacations: MomentTimeEntry;
	minimalPeriodAfterReject: MomentTimeEntry;
	minimalAdvanceTime: MomentTimeEntry;
	maximalAdvanceTime: MomentTimeEntry;
	minimalVacationDaysPerYear: number;
	vacationDaysDistribution: string;
	vacationDaysDistributionPerPeriods: number[];
	minimalVacationDuration: MomentTimeEntry;
	maximalVacationDuration: MomentTimeEntry;
	approverEmployees: string[];
	positionsThatAffectProjectDeadlines: string[];
	positionsThatCanNotBeFrozen: string[];
	positionsThatCanTakeRefunds: string[];
	conflictingPositions: ConflictingPositions[];
}

export interface HRSettings {
	vacationsSettings: VacationsSettings;
	sicknessesSettings: SicknessesSettings;
	latenessesSettings: LatenessesSettings;
	eventsSettings: EventsSettings;
}

export interface StatisticsSettings {
	companyStatisticsSettings: CompanyStatisticsSettings;
	employeeStatisticsSettings: EmployeeStatisticsSettings;
}

export interface PromotionsSettings {
	generalApprovers: string[];
	overdueApprovers: string[];
	timeToCheck: MomentTimeEntry;
	promotionRequestsInterval: MomentTimeEntry;
	rejectedPromotionRequestsInterval: MomentTimeEntry;
	timeToFirstPromotion: MomentTimeEntry;
	roadmapLinks: Record<string, string>
}

export interface EmployeeSettings {
	promotionsSettings: PromotionsSettings;
}

export interface FinancesSettings {
	financeDictionariesSettings: FinanceDictionariesSettings;
	operationStatementsSettings: OperationStatementsSettings;
	invoicesSettings: InvoicesSettings;
	accountsSettings: AccountsSettings;
	reportsSettings: ReportsSettings;
	cashBoxExpensesSettings: CashBoxExpensesSettings;
	salariesSettings: SalariesSettings;
	profitabilitySettings: ProfitabilitySettings;
	subscriptionsSettings: SubscriptionsSettings;
}

export interface Settings {
	generalSettings: GeneralSettings;
	integrationsSettings: IntegrationsSettings;
	notificationsSettings: NotificationsSettings;
	mainPageSettings: MainPageSettings;
	projectsSettings: ProjectsSettings;
	gantChartSettings: GantChartSettings;
	hrSettings: HRSettings;
	statisticsSettings: StatisticsSettings;
	employeeSettings: EmployeeSettings;
	publishersSettings: PublishersSettings;
	financesSettings: FinancesSettings;
}
