import React from 'react'
import { useTranslation } from 'react-i18next';

import UniversalTable from 'components/Tables/UniversalTable';

import { FaArchive } from 'react-icons/fa'
import { FaArrowRotateLeft, FaLock } from 'react-icons/fa6'
import { ContractorClass } from '../../../../../types/finance/contractor'
import {
	useArchiveContractorClassMutation, useGetContractorClassesQuery,
	useUnarchiveContractorClassMutation
} from '../../../../../redux/slices/financeDictionaries'
import UpdateContractorClassModal from '../Modals/UpdateContractorClassModal'
import TableError from '../../../../../components/TableError'
import { RolesEnum } from '../../../../../types/roles'
import DangerModal from '../../../../../components/Modals/DangerModal'
import SuccessModal from '../../../../../components/Modals/SuccessModal'
import Loader from '../../../../../components/Loaders/Loader'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

const columns = [
	{
		dataField: 'order_number',
		text: '№',
		sort: false,
		width: '10%',
		className: 'text-center'
	},
	{
		dataField: 'name',
		text: 'name',
		sort: false,
		width: '70%',
		className: 'text-left',
	},
	{
		dataField: 'actions',
		text: 'actions',
		sort: false,
		width: '20%',
		className: 'text-center',
	}
];

interface ContractorClassRowProps {
	contractorClass: ContractorClass;
	handlers: any;
	index: number;
}

function ContractorClassRow({ contractorClass, handlers, index }: ContractorClassRowProps) {
	const { t, i18n } = useTranslation();

	const [archiveContractorClass, unarchiveContractorClass] = handlers

	const renderTooltip = (content: string) => (props: any) => (
		<Tooltip id={`tooltip-${contractorClass._id}`} className={'custom-tooltip top'} {...props}>
			{content}
		</Tooltip>
	);

	return (
		<tr>
			<td className="text-center">{index + 1}</td>
			<td className="text-left">{(i18n.language === 'uk' ? contractorClass.ukName : contractorClass.enName)}</td>
			<td className="text-center">
				<div className='d-flex align-middle justify-content-center'>
					{
						contractorClass.isSystem ? (
							<>
								<OverlayTrigger placement="top" overlay={renderTooltip(t('is_system'))}>
									<Button
										variant="outline-muted"
										size="sm"
										className="d-flex align-items-center justify-content-center p-1"
										style={{ width: "28px", height: "28px", borderRadius: "4px" }}
										disabled={true}
										onClick={() => {}}
										aria-label={t('is_system')}
									>
										<FaLock size={18} />
									</Button>
								</OverlayTrigger>
							</>
						) : (
							<>
								{
									!contractorClass.isArchived && (
										<UpdateContractorClassModal
											contractorClass={contractorClass}
										/>
									)
								}
								{
									contractorClass.isArchived ? (
										<SuccessModal
											id={contractorClass._id}
											title={t('unarchive')}
											icon={FaArrowRotateLeft}
											onAccept={() => unarchiveContractorClass(contractorClass._id)}
											tooltipText={'unarchive'}
											requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
										/>
									) : (
										<DangerModal
											id={contractorClass._id}
											title={t('archive')}
											onAccept={() => archiveContractorClass(contractorClass._id)}
											icon={FaArchive}
											tooltipText={'archive'}
											requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
										/>
									)
								}
							</>
						)
					}
				</div>
			</td>
		</tr>
	);
}

function ContractorTypesTable() {
	const { t } = useTranslation()

	const [archiveContractorType] = useArchiveContractorClassMutation()
	const [unarchiveContractorType] = useUnarchiveContractorClassMutation()

	const { data: contractorClassesData, isLoading, isError } = useGetContractorClassesQuery();

	if (isLoading) return <Loader />;
	if (isError) return <TableError>{t('request_error')}</TableError>;

	const unarchivedContractorClassesData = (contractorClassesData && contractorClassesData.filter(entry => !entry.isArchived)) || []
	const archivedContractorClassesData = (contractorClassesData && contractorClassesData.filter(entry => entry.isArchived)) || []

	const handlers = [archiveContractorType, unarchiveContractorType]

	return <>
		{
			unarchivedContractorClassesData && unarchivedContractorClassesData.length > 0 ?
				<UniversalTable
					name={'ContractorClassesTable_tableName'}
					id={'contractorsClass ContractorClassesTable_tableName'}
					columns={columns}
					data={unarchivedContractorClassesData}
					row={
						(contractorClass, i) =>
							<ContractorClassRow handlers={handlers} key={contractorClass._id + 'contractorClassRow'} contractorClass={contractorClass} index={i}/>
					}
					scrollable
					defaultOpen={false}
				/> : <TableError>{t('no_data')}</TableError>
		}
		{
			archivedContractorClassesData && archivedContractorClassesData.length > 0 ?
				<UniversalTable
					name={'ContractorClassesTable_tableNameArchived'}
					id={'contractorsClass ContractorClassesTable_tableNameArchived'}
					columns={columns}
					data={archivedContractorClassesData}
					row={
						(contractorClass, i) =>
							<ContractorClassRow handlers={handlers} key={contractorClass._id + 'contractorClassArchivedRow'} contractorClass={contractorClass} index={i}/>
					}
					scrollable
					defaultOpen={false}
				/> : null
		}
	</>
}

export default ContractorTypesTable;
