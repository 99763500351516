import { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaUsers, FaEye } from 'react-icons/fa';
import { positionName, PositionsEnum } from "../../types/positions";
import { useGetShortEmployeesQuery } from "../../redux/slices/employees";
import { MultiselectFormGroup } from "../FormGroups";
import AuthorBadge from "../BagesAndLabels/AuthorBadge";

interface PositionsSelectProps {
    positions: string[];
    setPositions: (positions: string[]) => void;
    error?: string;
    label?: string;
    withEmployeesDisplay?: boolean;
}

function PositionsSelect({
                             positions,
                             setPositions,
                             error,
                             label,
                             withEmployeesDisplay = false,
                         }: PositionsSelectProps) {
    const { t } = useTranslation();
    const [badgesVisible, setBadgesVisible] = useState(true);

    const positionsOptions = Object.keys(PositionsEnum).map((key, i) => ({
        index: i,
        value: PositionsEnum[key as keyof typeof PositionsEnum],
        label: positionName(PositionsEnum[key as keyof typeof PositionsEnum]),
    }));

    const { data: employees = [] } = useGetShortEmployeesQuery(undefined, { skip: !withEmployeesDisplay });
    const selectedEmployees = employees.filter((e) =>
        positions.includes(e.position)
    );

    const handleChange = (selectedOptions: any[]) => {
        const values = selectedOptions.map((option) => option.value);
        setPositions(values);
    };

    return (
        <div className="pb-3">
            <MultiselectFormGroup
                label={label || t('positions')}
                options={positionsOptions}
                value={positionsOptions.filter((option) =>
                    positions.includes(option.value)
                )}
                onChange={handleChange}
                error={!!error}
                prefix={<FaUsers className="w-100 h-100" />}
            />

            {(withEmployeesDisplay && positions.length > 0) && (
                <>
                    <Row className="align-items-center">
                        <Col>{t('employees')}:</Col>
                        <Col className="d-flex justify-content-end">
                            <Button
                                variant="outline-primary"
                                onClick={() => setBadgesVisible(!badgesVisible)}
                                style={{
                                    width: '28px',
                                    height: '28px',
                                    borderRadius: '4px',
                                }}
                                className="justify-content-center align-items-center d-flex shadow-sm p-0 mb-0"
                            >
                                <FaEye />
                            </Button>
                        </Col>
                    </Row>
                    {badgesVisible && selectedEmployees.length > 0 && (
                        <Row className="mt-2">
                            <Col>
                                {selectedEmployees.map((member) => (
                                    <AuthorBadge
                                        key={member._id}
                                        employee={member}
                                        size="full"
                                    />
                                ))}
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </div>
    );
}

export default PositionsSelect;
