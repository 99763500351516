import {
	getAllCheckins,
} from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import WorkCheckin from 'types/workCheckin'

export const loadWorkCheckinsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.checkins.loadWorkCheckinsRequest())
	try {
		const workChekins: WorkCheckin[] = await getAllCheckins()
		dispatch(actions.dataActions.checkins.loadWorkCheckinsSuccess(workChekins))
		return workChekins
	} catch (error: any) {
		toast.error(error.response.data.message)
		console.log(error)
		return dispatch(actions.dataActions.checkins.loadWorkCheckinsError())
	}
}