import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../api/axios.queryBuilder'
import Employee, {
	EmployeeArchiveData,
	EmployeeCreationData,
	EmployeeUpdatingData, MissingEmployee,
	PromotionUpdatingData
} from '../../types/employee'

export const employeesApi = createApi({
	reducerPath: 'employeesApi',
	baseQuery: axiosBaseQuery({ baseUrl: 'employees' }),
	tagTypes: ['Employee', 'MissingEmployee'],
	endpoints: (builder) => ({
		getEmployee: builder.query<Employee, string>({
			query: (id) => ({ url: `/${id}`, method: 'GET' }),
			providesTags: ['Employee']
		}),
		getEmployees: builder.query<Employee[], void>({
			query: () => ({ url: '/all', method: 'GET' }),
			providesTags: ['Employee']
		}),
		getUnarchivedEmployees: builder.query<Employee[], void>({
			query: () => ({ url: '/all_unarchived', method: 'GET' }),
			providesTags: ['Employee']
		}),
		getShortEmployees: builder.query<Employee[], void>({
			query: () => ({ url: '/all_short', method: 'GET' }),
			providesTags: ['Employee']
		}),
		getMissingToday: builder.query<MissingEmployee[], void>({
			query: () => ({ url: '/missingToday', method: 'GET' }),
			providesTags: ['Employee', 'MissingEmployee']
		}),
		getMissingBetween: builder.query<
			{ date: Date; employees: string[] }[],
			{ start: string; end: string }
		>({
			query: ({ start, end }) => ({
				url: `/missingBetween?start=${start}&end=${end}`,
				method: 'GET',
			}),
			providesTags: ['Employee', 'MissingEmployee'],
		}),
		registerEmployee: builder.mutation<Employee, EmployeeCreationData>({
			query: (creationData) => ({
				url: '/register',
				method: 'POST',
				data: creationData
			}),
			invalidatesTags: ['Employee']
		}),
		archiveEmployee: builder.mutation<Employee, EmployeeArchiveData>({
			query: (archiveData) => ({
				url: '/archive',
				method: 'PUT',
				data: archiveData
			}),
			invalidatesTags: ['Employee']
		}),
		sendEmployeeInfo: builder.mutation<Employee, EmployeeUpdatingData>({
			query: (updatingData) => ({
				url: '/add_info',
				method: 'PUT',
				data: updatingData
			}),
			invalidatesTags: ['Employee']
		}),
		updatePromotionInfo: builder.mutation<Employee, PromotionUpdatingData>({
			query: (updatingData) => ({
				url: '/update_employee_info',
				method: 'PUT',
				data: updatingData
			}),
			invalidatesTags: ['Employee']
		}),
		uploadProfilePhoto: builder.mutation<Employee, File>({
			query: (file) => {
				const fd = new FormData()
				fd.append('file', file, file.name)
				return {
					url: '/upload_profile_photo',
					method: 'POST',
					data: fd,
					headers: { 'Content-Type': 'multipart/form-data' }
				}
			},
			invalidatesTags: ['Employee']
		}),
		deleteProfilePhoto: builder.mutation<Employee, string>({
			query: (id) => ({
				url: `/${id}/delete_profile_photo`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Employee']
		})
	})
})

export const {
	useGetEmployeeQuery,
	useGetEmployeesQuery,
	useGetUnarchivedEmployeesQuery,
	useGetShortEmployeesQuery,
	useGetMissingTodayQuery,
	useGetMissingBetweenQuery,
	useRegisterEmployeeMutation,
	useArchiveEmployeeMutation,
	useSendEmployeeInfoMutation,
	useUpdatePromotionInfoMutation,
	useUploadProfilePhotoMutation,
	useDeleteProfilePhotoMutation
} = employeesApi
