import React from 'react'
import { useTranslation } from 'react-i18next';

import UniversalTable from 'components/Tables/UniversalTable';

import {
	useArchiveContractTypeMutation, useGetContractTypesQuery,
	useUnarchiveContractTypeMutation
} from '../../../../../redux/slices/financeDictionaries'

import { FaArchive } from 'react-icons/fa'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import { ContractType } from '../../../../../types/finance/contract'
import UpdateContractTypeModal from '../Modals/UpdateContractTypeModal'
import TableError from '../../../../../components/TableError'
import { RolesEnum } from '../../../../../types/roles'
import SuccessModal from '../../../../../components/Modals/SuccessModal'
import DangerModal from '../../../../../components/Modals/DangerModal'
import Loader from '../../../../../components/Loaders/Loader'

const columns = [
	{
		dataField: 'order_number',
		text: '№',
		sort: false,
		width: '10%',
		className: 'text-center'
	},
	{
		dataField: 'name',
		text: 'name',
		sort: false,
		width: '70%',
		className: 'text-left',
	},
	{
		dataField: 'actions',
		text: 'actions',
		sort: false,
		width: '20%',
		className: 'text-center',
	}
];

interface ContractTypeRowProps {
	contractType: ContractType;
	handlers: any;
	index: number;
}

function ContractTypeRow({ contractType, handlers, index }: ContractTypeRowProps) {
	const { i18n } = useTranslation();

	const [archiveContractType, unarchiveContractType] = handlers

	return (
		<tr>
			<td className="text-center">{index + 1}</td>
			<td className="text-left">{(i18n.language === 'uk' ? contractType.ukName : contractType.enName)}</td>
			<td className="text-center">
				<div className='d-flex align-middle justify-content-center'>
					{
						!contractType.isArchived && (
							<UpdateContractTypeModal contractType={contractType} />
						)
					}
					{
						contractType.isArchived ? (
							<SuccessModal
								id={contractType._id}
								title='unarchive'
								icon={FaArrowRotateLeft}
								onAccept={() => unarchiveContractType(contractType._id)}
								tooltipText='unarchive'
								requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
							/>
						) : (
							<DangerModal
								id={contractType._id}
								title='archive'
								onAccept={() => archiveContractType(contractType._id)}
								icon={FaArchive}
								tooltipText='archive'
								requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
							/>
						)
					}
				</div>
			</td>
		</tr>
	);
}

function ContractTypesTable() {
	const { t } = useTranslation()

	const [archiveContractType] = useArchiveContractTypeMutation()
	const [unarchiveContractType] = useUnarchiveContractTypeMutation()

	const { data: contractTypesData, isLoading, isError } = useGetContractTypesQuery();

	if (isLoading) return <Loader />;
	if (isError) return <TableError>{t('request_error')}</TableError>;

	const unarchivedContractTypesData = (contractTypesData && contractTypesData.filter(entry => !entry.isArchived)) || []
	const archivedContractTypesData = (contractTypesData && contractTypesData.filter(entry => entry.isArchived)) || []

	const handlers = [archiveContractType, unarchiveContractType]

	return <>
		{
			unarchivedContractTypesData && unarchivedContractTypesData.length > 0 ?
				<UniversalTable
					name={'ContractTypeTable_tableName'}
					id={'contractsTable ContractTypeTable_tableName'}
					columns={columns}
					data={unarchivedContractTypesData}
					row={
						(contractType, i) =>
							<ContractTypeRow handlers={handlers} key={contractType._id + 'contractTypeRow'} contractType={contractType} index={i}/>
					}
					scrollable
					defaultOpen={false}
				/> : <TableError>{t('no_data')}</TableError>
		}
		{
			archivedContractTypesData && archivedContractTypesData.length > 0 ?
				<UniversalTable
					name={'ContractTypeTable_tableNameArchived'}
					id={'contractsTable ContractTypeTable_tableNameArchived'}
					columns={columns}
					data={archivedContractTypesData}
					row={
						(contractType, i) =>
							<ContractTypeRow handlers={handlers} key={contractType._id + 'contractTypeRow'} contractType={contractType} index={i}/>
					}
					scrollable
					defaultOpen={false}
				/> : null
		}
	</>
}

export default ContractTypesTable;
