import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../api/axios.queryBuilder'
import { CreateDivisionDto, Division, UpdateDivisionDto } from '../../types/division'

export const divisionsApi = createApi({
	reducerPath: 'divisionsApi',
	baseQuery: axiosBaseQuery({ baseUrl: 'divisions' }),
	tagTypes: ['Division'],
	endpoints: (builder) => ({
		loadDivisions: builder.query<Division[], void>({
			query: () => ({ url: '', method: 'GET' }),
			providesTags: ['Division']
		}),
		createDivision: builder.mutation<Division, CreateDivisionDto>({
			query: (newDivision) => ({
				url: '',
				method: 'POST',
				data: newDivision
			}),
			invalidatesTags: ['Division']
		}),
		updateDivision: builder.mutation<Division, { id: string, data: UpdateDivisionDto }>({
			query: ({ id, data }) => ({
				url: `/${id}`,
				method: 'PATCH',
				data
			}),
			invalidatesTags: ['Division']
		}),
		deleteDivision: builder.mutation<Division, string>({
			query: (id) => ({
				url: `/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Division']
		})
	})
})

export const {
	useLoadDivisionsQuery,
	useCreateDivisionMutation,
	useUpdateDivisionMutation,
	useDeleteDivisionMutation
} = divisionsApi
