import React, { useEffect, useState } from 'react'
import { FaHome, FaThermometerThreeQuarters, FaUmbrellaBeach, FaUserTimes } from 'react-icons/fa'
import { MdHomeWork } from 'react-icons/md'
import { SicknessTypeEnum } from 'types/sickness'
import {useGetMissingTodayQuery} from "../redux/slices/employees";
import {MissingEmployee} from "../types/employee";

interface UserTodayAvailabilityStatusProps {
	employee_id?: string
}

function UserTodayAvailabilityStatus({ employee_id }: UserTodayAvailabilityStatusProps) {
	const [availabilityType, setAvailabilityType] = useState<string | null>(null)
	const { data: missingToday } = useGetMissingTodayQuery()

	useEffect(() => {
		if (!employee_id) return

		if (missingToday) {
			const missingRecord = missingToday.find((record: MissingEmployee) => record.employee === employee_id)
			if (missingRecord) {
				setAvailabilityType(missingRecord.reason)
				return
			}
		}

		setAvailabilityType(null)
	}, [employee_id, missingToday])

	switch (availabilityType) {
		case SicknessTypeEnum.worksRemotely:
			return <FaHome className="text-primary mr-1" />
		case SicknessTypeEnum.doesntWork:
			return <FaUserTimes className="text-danger mr-1" />
		case SicknessTypeEnum.sickWork:
			return <MdHomeWork className="text-warning mr-1" />
		case SicknessTypeEnum.sick:
			return <FaThermometerThreeQuarters className="text-danger mr-1" />
		case 'vacation':
			return <FaUmbrellaBeach className="text-primary mr-1" />
		default:
			return <span></span>
	}
}

export default UserTodayAvailabilityStatus
