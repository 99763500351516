import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface TextInputProps {
    label: string;
    value: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    md?: number;
}

const TextInput: React.FC<TextInputProps> = ({ label, value, onChange, md = 12 }) => {
    const { t } = useTranslation();
    return (
        <Form.Group className="pb-3">
            <Form.Label>{t(label)}</Form.Label>
            <Row>
                <Col md={md} className="d-flex align-items-center">
                    <Form.Control type="text" value={value} onChange={onChange} />
                </Col>
            </Row>
        </Form.Group>
    );
};

export default TextInput;
