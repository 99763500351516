import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../api/axios.queryBuilder'
import WorkCheckin from '../../types/workCheckin'

export const timeTrackingApi = createApi({
    reducerPath: 'timeTrackingApi',
    baseQuery: axiosBaseQuery({ baseUrl: 'time_tracking' }),
    tagTypes: ['Checkin'],
    endpoints: (builder) => ({
        getAllCheckins: builder.query<WorkCheckin[], void>({
            query: () => ({ url: '/all', method: 'GET' }),
            providesTags: ['Checkin']
        }),
        getMonthCheckins: builder.query<WorkCheckin[], string>({
            query: (month) => ({ url: `/${month}/by_month`, method: 'GET' }),
            providesTags: ['Checkin']
        }),
        getEmployeeCheckins: builder.query<WorkCheckin[], string>({
            query: (id) => ({ url: `/${id}/employee_checkins`, method: 'GET' }),
            providesTags: ['Checkin']
        }),
        addCheckin: builder.mutation<WorkCheckin, void>({
            query: () => ({ url: '/checkin', method: 'POST' }),
            invalidatesTags: ['Checkin']
        }),
        addEndDayCheckin: builder.mutation<WorkCheckin, string>({
            query: (id) => ({ url: `/${id}/end_day_checkin`, method: 'PUT' }),
            invalidatesTags: ['Checkin']
        }),
        updateCheckin: builder.mutation<WorkCheckin, { id: string; comment: string }>({
            query: ({ id, comment }) => ({ url: '/update', method: 'PUT', data: { id, comment } }),
            invalidatesTags: ['Checkin']
        })
    })
})

export const {
    useGetAllCheckinsQuery,
    useGetMonthCheckinsQuery,
    useGetEmployeeCheckinsQuery,
    useAddCheckinMutation,
    useAddEndDayCheckinMutation,
    useUpdateCheckinMutation
} = timeTrackingApi
