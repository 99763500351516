import React from 'react';
import { Form } from 'react-bootstrap';

const ToggleSwitch = ({ id, label, checked, onChange, size = 'small' }) => (
    <Form.Group controlId={id} className="toggle-switch-container pb-3">
        <Form.Label className="toggle-label">{label}</Form.Label>
        <label className={`toggle-switch toggle-switch--${size}`}>
            <input type="checkbox" checked={checked} onChange={onChange} />
            <span className="slider"></span>
        </label>
    </Form.Group>
);

export default ToggleSwitch;
