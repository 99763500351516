import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../api/axios.queryBuilder'
import Sickness, {FilterSicknessDto, SicknessCreationData, SicknessUpdateData} from '../../types/sickness'

export const sicknessesApi = createApi({
	reducerPath: 'sicknessesApi',
	baseQuery: axiosBaseQuery({ baseUrl: 'sicknesses' }),
	tagTypes: ['Sickness', 'SicknessFile'],
	endpoints: (builder) => ({
		getAllSicknesses: builder.query<Sickness[], void>({
			query: () => ({ url: '/all', method: 'GET' }),
			providesTags: ['Sickness']
		}),
		getAllSicknessesPaginated: builder.query<
			{ data: Sickness[]; total: number },
			FilterSicknessDto
		>({
			query: (params) => ({
				url: `/all-paginated`,
				method: 'GET',
				params,
			}),
			providesTags: ['Sickness'],
		}),
		getTwoMonthSicknesses: builder.query<Sickness[], void>({
			query: () => ({ url: '/two_month_sicknesses', method: 'GET' }),
			providesTags: ['Sickness']
		}),
		getEmployeeSicknesses: builder.query<Sickness[], string>({
			query: (employeeId) => ({ url: `/${employeeId}/by_employee`, method: 'GET' }),
			providesTags: ['Sickness']
		}),
		createSickness: builder.mutation<Sickness, SicknessCreationData>({
			query: (sicknessData) => ({
				url: '/create',
				method: 'POST',
				data: sicknessData
			}),
			invalidatesTags: ['Sickness']
		}),
		updateSickness: builder.mutation<Sickness, SicknessUpdateData>({
			query: (sicknessData) => ({
				url: '/update',
				method: 'PUT',
				data: sicknessData
			}),
			invalidatesTags: ['Sickness']
		}),
		deleteSickness: builder.mutation<void, string>({
			query: (id) => ({
				url: `/delete/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Sickness']
		}),

		addSicknessFile: builder.mutation<
			{ fileId: string; fileName: string },
			{ id: string; file: File }
		>({
			query: ({ id, file }) => {
				const formData = new FormData();
				formData.append('file', file);

				return {
					url: `/upload_file/${id}`,
					method: 'POST',
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				};
			},
			invalidatesTags: ['Sickness', 'SicknessFile'],
		}),

		downloadSicknessFile: builder.mutation<any, string>({
			query: (id) => ({
				url: `/download_file/${id}`,
				method: 'GET',
			}),
		}),

		deleteSicknessFile: builder.mutation<void, string>({
			query: (id) => ({
				url: `/delete_file/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Sickness', 'SicknessFile'],
		}),
	})
})

export const {
	useGetAllSicknessesQuery,
	useGetAllSicknessesPaginatedQuery,
	useGetTwoMonthSicknessesQuery,
	useGetEmployeeSicknessesQuery,
	useCreateSicknessMutation,
	useUpdateSicknessMutation,
	useDeleteSicknessMutation,

	useAddSicknessFileMutation,
	useDownloadSicknessFileMutation,
	useDeleteSicknessFileMutation,
} = sicknessesApi
